import React, { Component } from "react";

import { get } from "lodash";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";

import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";

import WeeklyPredictionsWeek from "./WeeklyPredictionsWeek";

import seasons from "../../services/seasons";

const styles = (theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  roseCeremeonyItem: {
    "&.MuiAccordionDetails-root": {
      flexDirection: "column",
    },
  },
});

const newWeekFields = [
  {
    fieldName: "id",
    type: "numeric",
  },
]

class WeeklyPredictions extends Component {
  constructor(props) {
    super(props);

    const {
      weeklyPredictionsQuestions,
    } = this.props.season;

    this.state = {
      ...weeklyPredictionsQuestions,
      dialogOpen: false,
      newWeek: {},
    };

    this.handleNewWeekClick = this.handleNewWeekClick.bind(this);
    this.handleNewWeekClose = this.handleNewWeekClose.bind(this);
    this.handleNewWeekSave = this.handleNewWeekSave.bind(this);
    this.handleNumericChangeInWeek = this.handleNumericChangeInWeek.bind(this);
  }

  handleNewWeekClick() {
    const {
      season,
    } = this.props;

    const {
      roseCeremonyIds,
    } = season;

    this.setState({
      dialogOpen: true,
    });
  }

  handleNumericChangeInWeek(event) {
    this.setState(
      { 
        newWeek: {
          ...this.state.newWeek,
          [event.target.name]: parseFloat(event.target.value),
        },
      }
    );
  };

  handleNewWeekClose() {
    this.setState({
      newWeek: {},
      dialogOpen: false,
    });
  }

  handleNewWeekSave() {
    this.setState({
      dialogOpen: false,
    });

    const {
      openSnackbar,
    } = this.props;

    const {
      id,
    } = this.state.newWeek;

    seasons.addWeeklyPredictionsWeek(this.props.season, id, openSnackbar);
  }

  render() {
    const { classes } = this.props;

    const {
      season,
      openSnackbar,
    } = this.props;

    const {
      dialogOpen,
    } = this.state;

    const {
      id,
      weeklyPredictionsQuestions,
      contestantData,
    } = season;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Weekly Predictions</Typography>
        </AccordionSummary>
        <AccordionDetails
          className={classes.roseCeremeonyItem}
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            {get(weeklyPredictionsQuestions, "allWeeks") && weeklyPredictionsQuestions.allWeeks.map((weekId) => {
              const weeksQuestions = weeklyPredictionsQuestions.byWeek[weekId];

              return (
                <WeeklyPredictionsWeek
                  seasonId={id}
                  key={weekId}
                  weekId={weekId}
                  contestantData={contestantData}
                  questions={weeksQuestions}
                  openSnackbar={openSnackbar}
                />
              )
            })}
            <Box mt={2}>
              <Button
                variant="contained"
                onClick={this.handleNewWeekClick}
              >
                New Weekly Predictions Week 
              </Button>
            </Box>
          </Box>
          <Dialog
            open={dialogOpen}
            onClose={this.handleNewWeekClose}
          >
            <DialogTitle>
              New Weekly Predictions Week
            </DialogTitle>
            <DialogContent>
              {newWeekFields.map((field) => {
                const {
                  fieldName,
                  name,
                } = field;

                const value = get(this.state, ["newWeek", fieldName]) || "";

                return (
                  <TextField
                    key={fieldName}
                    fullWidth
                    label={fieldName}
                    type={"number"}
                    value={value}
                    variant="filled"
                    margin="normal"
                    name={fieldName}
                    onChange={(event) => this.handleNumericChangeInWeek(event)}
                  />
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleNewWeekClose}>
                Cancel
              </Button>
              <Button onClick={this.handleNewWeekSave}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default withStyles(styles)(WeeklyPredictions);;

import { createSlice, } from '@reduxjs/toolkit';

import {
  currentSeason,
} from "../../data/season-constants";

const initialState = {
  sidebarOpen: false,
  currentSeason: currentSeason,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    changeCurrentSeason: (state, action) => {
      state.currentSeason = action.payload;
    },
  },
});

export const { toggleSidebar, changeCurrentSeason, } = navigationSlice.actions;

export const selectNavigationSidebarOpen = (state) => state.navigation.sidebarOpen;
export const selectCurrentSeason = (state) => state.navigation.currentSeason;

export default navigationSlice.reducer;

import firebase, { firestore, } from "../firebase";

import { get } from "lodash";

import {
  STRING,
  NUMERIC,
  RADIO,
  SELECT_CONTESTANT,
  CLOSEST,
  BINARY_STRING,
  BINARY_NUMBER,
  ONE_OF,
  ONE_OF_CONTESTANT_IDS,
  WITHIN_AMOUNT,
  WITHIN_PERCENT,
  NUMERIC_OFF_BY,
  RADIO_SINGLE,
  INDIVIDUAL_VIBES_FROM_ALLIE,
} from "../data/question-types";

const seasons = {};

seasons.rescoreEverything = async (seasonId, openSnackbar) => {
  await seasons.resetAllScoringAndStandings(seasonId, openSnackbar);
  // await seasons.scoreAllRoseCeremonies(seasonId, openSnackbar);
  // await seasons.scoreAllWeeklyPredictions(seasonId, openSnackbar);
  // await seasons.scoreAllSeasonPredictions(seasonId, openSnackbar);
}

seasons.resetAllScoringAndStandings = async (seasonId, openSnackbar) => {
  console.log("reset all scoring")

  return new Promise(async (resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(seasonId);
    const batch = firestore.batch();

    batch.set(seasonRef,
      { standings: {}, },
      {merge: true},
    );

    seasonRef.collection("user_entries").get().then((snapshot) => {
      snapshot.forEach((doc) => {
        batch.set(doc.ref, 
          { scoring: {}, },
          { merge: true, }
        );
      });
    }).catch((reason) => {
      reject(reason);
    }).then(async () => {
      await batch.commit()
        .then(() => {
          openSnackbar("Reset all scores!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });

    });
  });
}

seasons.updateField = async (season, fieldName, newValue, openSnackbar) => {
  const {
    id,
  } = season;

  return new Promise((resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(id);
    seasonRef
        .set(
            {[fieldName]: newValue},
            {merge: true},
        )
        .then(() => {
          openSnackbar(`Updated ${fieldName}!`, "success")
        })
    });
};

seasons.addWeeklyPredictionsWeek = async (season, newWeekId, openSnackbar) => {
  const {
    id,
    weeklyPredictionsQuestions,
  } = season;

  const {
    allWeeks,
  } = weeklyPredictionsQuestions;

  const updatedAllWeekIds = [...new Set([...allWeeks, newWeekId])].sort(function (a, b) {  return a - b;  });
  const updatedWeeklyPredictionsQuestions = {
    ...weeklyPredictionsQuestions,
    allWeeks: updatedAllWeekIds,
    byWeek: {
      ...weeklyPredictionsQuestions.byWeek,
      [newWeekId]: {
        allIds: [],
        byId: {},
      }
    }
  };

  return new Promise((resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(id);
    seasonRef
        .set(
            { weeklyPredictionsQuestions: updatedWeeklyPredictionsQuestions, },
            {merge: true},
        )
        .then(() => {
          openSnackbar(`Added Weekly Predictions Week ${newWeekId}!`, "success")
        })
    });
};

const getScoringForRoseCeremony = (userEntry, roseCeremony) => {
  const {
    pointsPerRose,
    contestantIdsForPoints,
    numContestantsLeft,
  } = roseCeremony;

  const {
    rankings,
  } = userEntry;

  let numCorrect = 0;

  if (rankings && rankings.length > 0) {
    const possibleUserContestants = rankings.slice(0, numContestantsLeft);
    const correctContestants =
      contestantIdsForPoints.filter((id) =>
        possibleUserContestants.includes(id));
    numCorrect = correctContestants.length;
  }

  return {
    numCorrect: numCorrect,
    points: pointsPerRose * numCorrect,
  };
};

const sortUserScores = (userScores) => {
  return [...userScores.sort(function(a, b) {
    return b.score - a.score;
  })];
};

const sortUserScoresByWeek = (userScores) => {
  return [...userScores.sort(function(a, b) {
    return b.weeklyScore - a.weeklyScore;
  })];
};

const sortUserScoresBySeasonPredictions = (userScores) => {
  return [...userScores.sort(function(a, b) {
    return b.seasonPredictionsPoints - a.seasonPredictionsPoints;
  })];
};

seasons.updateRoseCeremony = async (season, roseCeremony, contestantIdsForPoints, newEliminatedContestantIds, allEliminatedContestantIds, openSnackbar) => {
  const {
    id,
  } = season;

  const {
    id: roseCeremonyId,
    week: weekId,
    pointsPerRose, 
    numContestantsLeft,
  } = roseCeremony;

  return new Promise(async (resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(id);
    const seasonSnapshot = (await seasonRef.get()).data();

    const {
      contestantData,
      allWeekIds = [],
      roseCeremonyIds = [],
      roseCeremoniesByWeek,
    } = seasonSnapshot;

    const userScores = [];

    let updatedContestantData = { ...contestantData };
    newEliminatedContestantIds.map((contestantId) => contestantData.byId[contestantId])
    .map((existingContestant) => {
      return {
        ...existingContestant,
        weekEliminated: weekId,
      };
    })
    .forEach((updatedContestant) => {
      updatedContestantData.byId[updatedContestant.id] = updatedContestant;
    });

    const updatedAllWeekIds = [...new Set([...allWeekIds, weekId])].sort(function (a, b) {  return a - b;  });
    const updatedRoseCeremonyIds = [...new Set([...roseCeremonyIds, roseCeremonyId])].sort(function (a, b) {  return a - b;  });
    const currentRoseCeremoniesByWeek = get(roseCeremoniesByWeek, weekId) ?? [];
    const updatedRoseCeremoniesByWeek = [...new Set([...currentRoseCeremoniesByWeek, roseCeremonyId])].sort(function (a, b) {  return a - b;  });
    const weeksToScore = updatedAllWeekIds.slice(0, updatedAllWeekIds.indexOf(weekId) + 1);
    const updatedRoseCeremony = {
      pointsPerRose: pointsPerRose,
      newEliminatedContestantIds: newEliminatedContestantIds,
      allEliminatedContestantIds: allEliminatedContestantIds,
      contestantIdsForPoints: contestantIdsForPoints,
      numContestantsLeft: numContestantsLeft,
    };

    const batch = firestore.batch();

    seasonRef.collection("user_entries").get().then((snapshot) => {
      snapshot.forEach((doc) => {
        const userEntrySnapshot = doc.data();

        const {
          scoring,
        } = userEntrySnapshot;

        const newScoring =
          getScoringForRoseCeremony(userEntrySnapshot, updatedRoseCeremony);

        const previousOverallPoints =
          (get(scoring, ["overall"]) ?? 0);

        const previousWeeklyPoints =
          (get(scoring, ["weekly", weekId, "points"]) ?? 0);

        const existingRoseCeremonyPoints =
          (get(scoring, ["weekly", weekId, "roseCeremonyById",
            roseCeremonyId, "points"]) ?? 0);

        const updatedOverallPoints = previousOverallPoints -
          existingRoseCeremonyPoints + newScoring.points;

        const updatedWeeklyPoints = previousWeeklyPoints -
          existingRoseCeremonyPoints + newScoring.points;

        const updatedScoring = {
          ...scoring,
          weekly: {
            ...get(scoring, "weekly"),
            [weekId]: {
              points: updatedWeeklyPoints,
              roseCeremonyById: {
                [roseCeremonyId]: newScoring,
              },
            },
          },
          overall: updatedOverallPoints,
        };
  
        const scoreThroughThisWeek = weeksToScore.map((week) => get(updatedScoring, ["weekly", week, "points"]) ?? 0)
          .reduce((a, b) => a + b);
        userScores.push({
          id: doc.id,
          score: scoreThroughThisWeek,
          weeklyScore: updatedWeeklyPoints,
        });

        batch.set(doc.ref, 
          { scoring: updatedScoring, },
          { merge: true, }
        );
      });
    }).catch((reason) => {
      reject(reason);
    }).then(async () => {
      const sortedUserScores = sortUserScores(userScores);
      const sortedWeeklyUserScores = sortUserScoresByWeek(userScores);

      let currentRank = 1;
      const allEntriesBreakTies = [];
      sortedUserScores.forEach((entry, index) => {
        if (index !== 0) {
          if (entry.score !==
              sortedUserScores[index - 1].score) {
            currentRank = index + 1;
          }
        }
        allEntriesBreakTies.push({
          ...entry,
          rank: currentRank,
        });
      });

      currentRank = 1;
      const weeklyEntriesBreakTies = [];
      sortedWeeklyUserScores.forEach((entry, index) => {
        if (index !== 0) {
          if (entry.weeklyScore !==
              sortedWeeklyUserScores[index - 1].weeklyScore) {
            currentRank = index + 1;
          }
        }
        weeklyEntriesBreakTies.push({
          ...entry,
          rank: currentRank,
        });
      });

      const updatedOverallStandings = {
        weekly: {
          [weekId]: {
            overall: allEntriesBreakTies,
            weekly: weeklyEntriesBreakTies,
          },
        },
      };

      const seasonUpdate = {
        roseCeremonies: {
          [roseCeremonyId]: updatedRoseCeremony,
        },
        allWeekIds: updatedAllWeekIds,
        roseCeremonyIds: updatedRoseCeremonyIds,
        roseCeremoniesByWeek: {
          [weekId]: updatedRoseCeremoniesByWeek,
        },
        contestantData: {
          ...updatedContestantData,
        },
        standings: updatedOverallStandings,
      };

      batch.set(seasonRef, 
        seasonUpdate,
        { merge: true }
      );

      console.log(seasonUpdate);

      await batch.commit()
        .then(() => {
          openSnackbar("Saved rose ceremony!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  });
};

seasons.updateWeeklyPredictionsQuestionArbitraryFact = async (seasonId, weekId, questionId, factToSave, openSnackbar) => {
  return new Promise(async (resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(seasonId);
    const seasonSnapshot = (await seasonRef.get()).data();
    const {
      weeklyPredictionsQuestions,
      allWeekIds,
    } = seasonSnapshot;

    const {
      id: factId,
    } = factToSave;

    const weeklyPredictionsQuestionsForWeek = weeklyPredictionsQuestions.byWeek[weekId];
    const question = weeklyPredictionsQuestionsForWeek.byId[questionId];
    const {
      allFactIds = [],
      factsById = {},
    } = question;

    const existingFact = get(factsById, factId);
    const updatedAllFactIds = existingFact ? allFactIds : [...allFactIds, factId]

    const updatedQuestion = {
      ...question,
      allFactIds: updatedAllFactIds,
      factsById: {
        ...question.factsById,
        [factId]: factToSave,
      },
    };
    
    const weeksToScore = allWeekIds.slice(0, allWeekIds.indexOf(weekId) + 1);
    const userScores = [];

    const batch = firestore.batch();

    seasonRef.collection("user_entries").get().then((snapshot) => {
      const allEntries = [];
      snapshot.forEach((doc) => {
        const userEntrySnapshot = doc.data();
        allEntries.push(userEntrySnapshot);
      });

      snapshot.forEach((doc) => {
        const userEntrySnapshot = doc.data();

        const {
          scoring,
        } = userEntrySnapshot;

        const updatedScoringForQuestion = getWeeklyPredictionsScoringForQuestion(
          updatedQuestion, weekId, questionId, userEntrySnapshot, allEntries);

        const {
          points: questionScore,
        } = updatedScoringForQuestion;

        const previousOverallPoints =
          (get(scoring, ["overall"]) ?? 0);

        const previousWeeklyPoints =
          (get(scoring, ["weekly", weekId, "points"]) ?? 0);

        const existingWeeklyPredictionPoints =
          (get(scoring, ["weekly", weekId, "weeklyPredictionPoints"]) ?? 0);

        const existingPointsForQuestion =
          (get(scoring, ["weekly", weekId, "weeklyPredictionPointsByQuestionId", questionId, "points"]) ?? 0);


        const updatedOverallPoints = previousOverallPoints -
          existingPointsForQuestion + questionScore;

        const updatedWeeklyPoints = previousWeeklyPoints -
          existingPointsForQuestion + questionScore;

        const updatedWeeklyPredictionPoints = existingWeeklyPredictionPoints -
          existingPointsForQuestion + questionScore;


        const updatedScoring = {
          ...scoring,
          overall: updatedOverallPoints,
          weekly: {
            ...get(scoring, "weekly"),
            [weekId]: {
              points: updatedWeeklyPoints,
              weeklyPredictionPoints: updatedWeeklyPredictionPoints,
              weeklyPredictionPointsByQuestionId: {
                [questionId]: updatedScoringForQuestion,
              },
            },
          },
        };
  
        const scoreThroughThisWeek = weeksToScore.map((week) => get(updatedScoring, ["weekly", week, "points"]) ?? 0)
          .reduce((a, b) => a + b);
        userScores.push({
          id: doc.id,
          score: scoreThroughThisWeek,
          weeklyScore: updatedWeeklyPoints,
        });

        batch.set(doc.ref, 
          { scoring: updatedScoring, },
          { merge: true, }
        );
      });
    }).catch((reason) => {
      reject(reason);
    }).then(async () => {
      const sortedUserScores = sortUserScores(userScores);
      const sortedWeeklyUserScores = sortUserScoresByWeek(userScores);

      let currentRank = 1;
      const allEntriesBreakTies = [];
      sortedUserScores.forEach((entry, index) => {
        if (index !== 0) {
          if (entry.score !==
              sortedUserScores[index - 1].score) {
            currentRank = index + 1;
          }
        }
        allEntriesBreakTies.push({
          ...entry,
          rank: currentRank,
        });
      });

      currentRank = 1;
      const weeklyEntriesBreakTies = [];
      sortedWeeklyUserScores.forEach((entry, index) => {
        if (index !== 0) {
          if (entry.weeklyScore !==
              sortedWeeklyUserScores[index - 1].weeklyScore) {
            currentRank = index + 1;
          }
        }
        weeklyEntriesBreakTies.push({
          ...entry,
          rank: currentRank,
        });
      });

      const updatedOverallStandings = {
        weekly: {
          [weekId]: {
            overall: allEntriesBreakTies,
            weekly: weeklyEntriesBreakTies,
          },
        },
      };

      const seasonUpdate = {
        weeklyPredictionsQuestions: {
          byWeek: {
            [weekId]: {
              byId: {
                [questionId]: updatedQuestion,
              },
            },
          },
        },
        standings: updatedOverallStandings,
      };

      console.log(seasonUpdate);

      batch.set(seasonRef, 
        seasonUpdate,
        { merge: true }
      );

      await batch.commit()
        .then(() => {
          openSnackbar("Saved fact!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  });
};

seasons.updateWeeklyPredictionsQuestion = async (seasonId, weekId, questionId, answer, openSnackbar) => {
  return new Promise(async (resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(seasonId);
    const seasonSnapshot = (await seasonRef.get()).data();
    const {
      weeklyPredictionsQuestions,
      allWeekIds,
    } = seasonSnapshot;

    const weeklyPredictionsQuestionsForWeek =
      weeklyPredictionsQuestions.byWeek[weekId];
    const updatedQuestion = {
      ...weeklyPredictionsQuestionsForWeek.byId[questionId],
      answer: answer,
    }

    const weeksToScore = allWeekIds.slice(0, allWeekIds.indexOf(weekId) + 1);
    const userScores = [];

    const batch = firestore.batch();

    seasonRef.collection("user_entries").get().then((snapshot) => {
      const allEntries = [];
      snapshot.forEach((doc) => {
        const userEntrySnapshot = doc.data();
        allEntries.push(userEntrySnapshot);
      });

      snapshot.forEach((doc) => {
        const userEntrySnapshot = doc.data();

        const {
          scoring,
        } = userEntrySnapshot;

        const updatedScoringForQuestion = getWeeklyPredictionsScoringForQuestion(
          updatedQuestion, weekId, questionId, userEntrySnapshot, allEntries);

        const {
          points: questionScore,
        } = updatedScoringForQuestion;

        const previousOverallPoints =
          (get(scoring, ["overall"]) ?? 0);

        const previousWeeklyPoints =
          (get(scoring, ["weekly", weekId, "points"]) ?? 0);

        const existingWeeklyPredictionPoints =
          (get(scoring, ["weekly", weekId, "weeklyPredictionPoints"]) ?? 0);

        const existingPointsForQuestion =
          (get(scoring, ["weekly", weekId, "weeklyPredictionPointsByQuestionId", questionId, "points"]) ?? 0);


        const updatedOverallPoints = previousOverallPoints -
          existingPointsForQuestion + questionScore;

        const updatedWeeklyPoints = previousWeeklyPoints -
          existingPointsForQuestion + questionScore;

        const updatedWeeklyPredictionPoints = existingWeeklyPredictionPoints -
          existingPointsForQuestion + questionScore;


        const updatedScoring = {
          ...scoring,
          overall: updatedOverallPoints,
          weekly: {
            ...get(scoring, "weekly"),
            [weekId]: {
              points: updatedWeeklyPoints,
              weeklyPredictionPoints: updatedWeeklyPredictionPoints,
              weeklyPredictionPointsByQuestionId: {
                [questionId]: updatedScoringForQuestion,
              },
            },
          },
        };
  
        const scoreThroughThisWeek = weeksToScore.map((week) => get(updatedScoring, ["weekly", week, "points"]) ?? 0)
          .reduce((a, b) => a + b);
        userScores.push({
          id: doc.id,
          score: scoreThroughThisWeek,
          weeklyScore: updatedWeeklyPoints,
        });

        batch.set(doc.ref, 
          { scoring: updatedScoring, },
          { merge: true, }
        );
      });
    }).catch((reason) => {
      reject(reason);
    }).then(async () => {
      const sortedUserScores = sortUserScores(userScores);
      const sortedWeeklyUserScores = sortUserScoresByWeek(userScores);

      let currentRank = 1;
      const allEntriesBreakTies = [];
      sortedUserScores.forEach((entry, index) => {
        if (index !== 0) {
          if (entry.score !==
              sortedUserScores[index - 1].score) {
            currentRank = index + 1;
          }
        }
        allEntriesBreakTies.push({
          ...entry,
          rank: currentRank,
        });
      });

      currentRank = 1;
      const weeklyEntriesBreakTies = [];
      sortedWeeklyUserScores.forEach((entry, index) => {
        if (index !== 0) {
          if (entry.weeklyScore !==
              sortedWeeklyUserScores[index - 1].weeklyScore) {
            currentRank = index + 1;
          }
        }
        weeklyEntriesBreakTies.push({
          ...entry,
          rank: currentRank,
        });
      });

      const updatedOverallStandings = {
        weekly: {
          [weekId]: {
            overall: allEntriesBreakTies,
            weekly: weeklyEntriesBreakTies,
          },
        },
      };

      const seasonUpdate = {
        weeklyPredictionsQuestions: {
          byWeek: {
            [weekId]: {
              byId: {
                [questionId]: {
                  answer: answer,
                },
              },
            },
          },
        },
        standings: updatedOverallStandings,
      };

      console.log(seasonUpdate);

      batch.set(seasonRef, 
        seasonUpdate,
        { merge: true }
      );

      await batch.commit()
        .then(() => {
          openSnackbar("Saved weekly prediction!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  });
};

seasons.updateSeasonPredictionsQuestion = async (seasonId, questionId, answer, openSnackbar) => {
  return new Promise(async (resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(seasonId);
    const seasonSnapshot = (await seasonRef.get()).data();
    const {
      seasonPredictionsQuestions,
      standings,
    } = seasonSnapshot;

    const updatedQuestion = {
      ...seasonPredictionsQuestions.byId[questionId],
      answer: answer,
    };

    const userScores = [];

    const batch = firestore.batch();

    seasonRef.collection("user_entries").get().then((snapshot) => {
      const allEntries = [];
      snapshot.forEach((doc) => {
        const userEntrySnapshot = doc.data();
        allEntries.push(userEntrySnapshot);
      });

      snapshot.forEach((doc) => {
        const userEntrySnapshot = doc.data();

        const {
          scoring,
        } = userEntrySnapshot;

        const updatedScoringForQuestion = getSeasonPredictionsScoringForQuestion(
          updatedQuestion, questionId, userEntrySnapshot, allEntries);
        
        const {
          points: questionScore,
        } = updatedScoringForQuestion;

        const previousOverallPoints =
          (get(scoring, ["overall"]) ?? 0);

        const existingSeasonPredictionsPoints =
          (get(scoring, ["seasonPredictions", "points"]) ?? 0);

        const existingPointsForQuestion =
          (get(scoring, ["seasonPredictions", "byQuestionId", questionId, "points"]) ?? 0);

        const updatedOverallPoints = previousOverallPoints -
          existingPointsForQuestion + questionScore;

        const updatedSeasonPredictionsPoints = existingSeasonPredictionsPoints -
          existingPointsForQuestion + questionScore;

        const updatedScoring = {
          ...scoring,
          overall: updatedOverallPoints,
          seasonPredictions: {
            ...get(scoring, "seasonPredictions"),
            points: updatedSeasonPredictionsPoints,
            byQuestionId: {
              [questionId]: updatedScoringForQuestion,
            },
          },
        };
  
        userScores.push({
          id: doc.id,
          score: updatedOverallPoints,
          seasonPredictionsPoints: updatedSeasonPredictionsPoints,
        });

        batch.set(doc.ref, 
          { scoring: updatedScoring, },
          { merge: true, }
        );
      });
    }).catch((reason) => {
      reject(reason);
    }).then(async () => {
      const sortedUserScores = sortUserScores(userScores);
      const sortedUserScoresBySeasonPredictions = sortUserScoresBySeasonPredictions(userScores);

      let currentRank = 1;
      const allEntriesBreakTies = [];
      sortedUserScores.forEach((entry, index) => {
        if (index !== 0) {
          if (entry.score !==
              sortedUserScores[index - 1].score) {
            currentRank = index + 1;
          }
        }
        allEntriesBreakTies.push({
          ...entry,
          rank: currentRank,
        });
      });

      currentRank = 1;
      const seasonPredictionsEntriesBreakTies = [];
      sortedUserScoresBySeasonPredictions.forEach((entry, index) => {
        if (index !== 0) {
          if (entry.seasonPredictionsPoints !==
              sortedUserScoresBySeasonPredictions[index - 1].seasonPredictionsPoints) {
            currentRank = index + 1;
          }
        }
        seasonPredictionsEntriesBreakTies.push({
          ...entry,
          rank: currentRank,
        });
      });

      const updatedOverallStandings = {
        ...standings,
        withSeasonPredictions: {
          overall: allEntriesBreakTies,
          seasonPredictions: seasonPredictionsEntriesBreakTies,
        },
      };

      const seasonUpdate = {
        seasonPredictionsQuestions: {
          byId: {
            [questionId]: {
              answer: answer,
            },
          },
        },
        standings: updatedOverallStandings,
      };

      console.log(seasonUpdate);

      batch.set(seasonRef, 
        seasonUpdate,
        { merge: true }
      );

      await batch.commit()
        .then(() => {
          openSnackbar("Saved season prediction!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  });
};

// const getWeeklyPredictionsScoring =
//   (weeklyPredictionsQuestions, weekId, entryToScore, allEntries) => {
//     const weeklyPredictionsQuestionsForWeek =
//       weeklyPredictionsQuestions.byWeek[weekId];

//     const {
//       userId,
//       weeklyPredictions,
//     } = entryToScore;

//     const usersAnswers = get(weeklyPredictions, [weekId]);

//     const allEntriesAnswersForWeek = allEntries.map((entry) => {
//       return {
//         userId: entry.userId,
//         answers: get(entry, ["weeklyPredictions", weekId]),
//       }
//     });

//     return scoreQuestions(
//         userId, weeklyPredictionsQuestionsForWeek, usersAnswers, allEntriesAnswersForWeek);
//   };
  
const getWeeklyPredictionsScoringForQuestion = (question, weekId, questionId, entryToScore, allEntries) => {
    const {
      userId,
      weeklyPredictions,
    } = entryToScore;

    const usersAnswer = get(weeklyPredictions, [weekId, questionId]);

    const allEntriesAnswersForWeek = allEntries.map((entry) => {
      return {
        userId: entry.userId,
        answer: get(entry, ["weeklyPredictions", weekId, questionId]),
      }
    });

    return scoreQuestion(
        userId, question, usersAnswer, allEntriesAnswersForWeek);

};

const getSeasonPredictionsScoringForQuestion = (question, questionId, entryToScore, allEntries) => {
    const {
      userId,
      seasonPredictions,
    } = entryToScore;

    const usersAnswer = get(seasonPredictions, questionId);

    const allEntriesAnswersForQuestion = allEntries.map((entry) => {
      return {
        userId: entry.userId,
        answer: get(entry, ["seasonPredictions", questionId]),
      }
    });

    return scoreQuestion(
        userId, question, usersAnswer, allEntriesAnswersForQuestion);

};

// const scoreQuestions = (userId, questions, answers, allEntries) => {
//   const scoring = questions.allIds
//       .map((questionId) => questions.byId[questionId])
//       .map((question) =>
//         scoreQuestion(userId, question, answers, allEntries),
//       ).reduce((existingScoring, questionScoring, index) => {
//         return {
//           points: existingScoring.points + questionScoring[index + 1].points,
//           byQuestionId: {
//             ...existingScoring.byQuestionId,
//             ...questionScoring,
//           },
//         };
//       }, {
//         points: 0,
//       });

//   return scoring;
// };

const scoreQuestion =
  (userId, question, answer, allEntries) => {
    let questionScoring = {
      points: 0,
    };

    switch (question.answerType) {
      case BINARY_STRING: {
        if (answer !== undefined) {
          if (answer.toLowerCase() === question.answer.toLowerCase()) {
            questionScoring = {
              points: question.points,
            };
          }
        }
        break;
      }
      case BINARY_NUMBER: {
        if (answer !== undefined) {
          if (answer === question.answer) {
            questionScoring = {
              points: question.points,
            };
          }
        }
        break;
      }
      case ONE_OF: {
        if (answer !== undefined) {
          answer = question.questionType === STRING ? answer.toLowerCase() : answer;
          if (question.possibleAnswers.includes(answer)) {
            questionScoring = {
              points: question.points,
            };
          }
        }
        break;
      }
      case ONE_OF_CONTESTANT_IDS: {
        if (answer !== undefined) {
          if (question.possibleAnswers.includes(answer)) {
            questionScoring = {
              points: question.points,
            };
          }
        }
        break;
      }
      case WITHIN_AMOUNT: {
        if (answer !== undefined) {
          let pointsForAnswer = 0;
          const difference = Math.abs(answer - question.answer);

          if (difference <= question.threshold) {
            pointsForAnswer = question.points;
          }

          questionScoring = {
            points: pointsForAnswer,
            difference: difference,
          };
        }
        break;
      }
      case WITHIN_PERCENT: {
        if (answer !== undefined) {
          let pointsForAnswer = 0;
          const difference = Math.abs(answer - question.answer);
          const threshold = get(question, "threshold");
          const thresholds = get(question, "thresholds");

          if (threshold) {
            if (difference <= threshold) {
              pointsForAnswer = question.points;
            }

            questionScoring = {
              points: pointsForAnswer,
              difference: difference,
            };
          } else if (thresholds) {
            for (const thresholdToCompare of thresholds) {
              const {
                threshold: thresholdValue,
                points,
              } = thresholdToCompare;

              if (difference <= thresholdValue) {
                pointsForAnswer = points;
                break;
              }
            }

            questionScoring = {
              points: pointsForAnswer,
              difference: difference,
            };
          }
        }
        break;
      }
      case NUMERIC_OFF_BY: {
        if (answer !== undefined) {
          const difference = Math.abs(answer - question.answer);
          const pointsForAnswer = get(question.offByScoring, difference, 0) ;

          questionScoring = {
            points: pointsForAnswer,
            difference: difference,
          };
        }
        break;
      }
      case CLOSEST: {
        const allEntriesWithAnswer =
          getAllEntriesWithAnswerForQuestion(allEntries)
              .map((entry) => {
                const answer = entry.answer;
                const difference = Math.abs(answer - question.answer);
                return {
                  userId: entry.userId,
                  answer: answer,
                  difference: difference,
                };
              })
              .sort((a, b) => a.difference - b.difference);
        if (answer !== undefined) {
          let currentRank = 1;
          const allEntriesBreakTies = [];
          allEntriesWithAnswer.forEach((entry, index) => {
            if (index !== 0) {
              if (entry.difference !==
                  allEntriesWithAnswer[index - 1].difference) {
                currentRank = index + 1;
              }
            }
            allEntriesBreakTies.push({
              ...entry,
              rank: currentRank,
            });
          });

          const myEntryRank = allEntriesBreakTies.find((entry) =>
            entry.userId === userId,
          ) ?? allEntriesBreakTies.length;

          questionScoring = {
            points: get(question, ["closestScoring", myEntryRank.rank]) ?? 0,
            rank: myEntryRank.rank,
          };
        } else {
          questionScoring = {
            points: 0,
            rank: allEntriesWithAnswer.length + 1,
          }
        }
        break;
      }
      case RADIO_SINGLE: {
        if (answer !== undefined) {
          let pointsForAnswer = 0;

          if (answer === question.answer) {
            if (question.radioScoring) {
              pointsForAnswer = question.radioScoring[answer];
            } else {
              pointsForAnswer = question.points;
            }

            questionScoring = {
              points: pointsForAnswer,
            };
          }
        }
        break;
      }
      case INDIVIDUAL_VIBES_FROM_ALLIE: {
        if (answer !== undefined) {
          const {
            allFactIds = [],
            factsById = {},
          } = question;

          let pointsForAnswer = 0;
          let matchingFactId = undefined;
          for (const id of allFactIds) {
            const fact = factsById[id];
            const {
              regex,
              points,
            } = fact;

            const regexToTest = new RegExp(regex.toLowerCase());
            const found = regexToTest.exec(answer.toLowerCase());

            if (found){
              pointsForAnswer = points;
              matchingFactId = id;
              break;
            }
          }

          questionScoring = {
            points: pointsForAnswer,
            ...(matchingFactId && {matchingFactId: matchingFactId}),
          };
        }
        break;
      }
    }

    return questionScoring;
  };

const getAllEntriesWithAnswerForQuestion = (allEntries) => {
  return allEntries.filter((entry) => {
    return (get(entry, "answer")) !== undefined;
  });
};

seasons.updateWeeklyPredictionsAnswerType = async (seasonId, weekId, questionId, answerType, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(seasonId);

    seasonRef
        .set(
            {
              weeklyPredictionsQuestions: {
                byWeek: {
                  [weekId]: {
                    byId: {
                      [questionId]: {
                        answerType: answerType,
                      }
                    }
                  }
                }
              }
            },
            {merge: true},
        )
        .then(() => {
          openSnackbar(`Updated AnswerType to ${answerType}!`, "success");
        })
  });
};

seasons.updateSeasonPredictionsAnswerType = async (seasonId, questionId, answerType, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(seasonId);

    seasonRef
        .set(
            {
              seasonPredictionsQuestions: {
                byId: {
                  [questionId]: {
                    answerType: answerType,
                  }
                }
              }
            },
            {merge: true},
        )
        .then(() => {
          openSnackbar(`Updated AnswerType to ${answerType}!`, "success");
        })
  });
};

export default seasons;
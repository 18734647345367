import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  Box,
  Grid
} from "@material-ui/core";

import PicksColumnReadOnly from "../../components/PicksColumnReadOnly";

import ScoringPanel from "../../components/ScoringPanel";

const styles = (theme) => ({
  grid: {
    margin: 0,
    width: "100%",
  },
});

class UserEntryOverview extends Component {
  render() {
    const {
      classes,
      currentRoseCeremonyId,
      currentWeek,
      rankings,
      numContestants,
      contestantData,
      seasonPredictions,
      isBachelorette,
      roseCeremonies,
      allWeekIds,
      standings,
      scoring,
      displayName,
      userEntryId,
      unloadedMyUserEntry,
      roseCeremoniesByWeek,
    } = this.props;

    // Functions
    const {
      onRoseCeremonySelected,
    } = this.props;


    if (!currentRoseCeremonyId) {
      return (
        <Box>
          <Grid className={classes.grid} container spacing={5}>
            <Grid item xs={12} md={6}>
              <PicksColumnReadOnly
                rankings={rankings}
                numContestants={numContestants}
              />
            </Grid>
          </Grid>
        </Box>
      );
    }
    
    const roseCeremony = roseCeremonies[currentRoseCeremonyId];
    const allWeeksReversed = Array.from(allWeekIds).reverse();

    return (
      <Box>
        <Grid className={classes.grid} container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Box pb={2}>
              <ScoringPanel 
                displayName={displayName}
                userEntryId={userEntryId ?? unloadedMyUserEntry.id}
                allWeekIds={allWeeksReversed}
                standings={standings}
                scoring={scoring}
                roseCeremonies={roseCeremonies}
                roseCeremoniesByWeek={roseCeremoniesByWeek}
                onRoseCeremonySelected={onRoseCeremonySelected}
                currentWeek={currentWeek}
                selectedRoseCeremonyId={currentRoseCeremonyId}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PicksColumnReadOnly
              rankings={rankings}
              contestantData={contestantData}
              numContestants={numContestants}
              isBachelorette={isBachelorette}
              roseCeremony={roseCeremony}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(UserEntryOverview);

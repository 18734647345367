import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import {
  isLoaded,
} from 'react-redux-firebase'

import { get, filter, some, reject, find, } from "lodash";

import { withRouter, } from "react-router-dom";

import {
  Box,
  Button,
  ButtonGroup,
  Hidden,
  Typography,
} from "@material-ui/core";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineDot,
} from "@material-ui/lab";

import { withStyles, makeStyles, } from "@material-ui/core/styles";

import { auth, } from "../../firebase";

import Loader from "../Loader";

import authentication from "../../services/authentication";

import LeagueInfo from "../LeagueInfo";
import Matchup from "../Matchup";

const styles = (theme) => ({
  timelineRoot: {
    "&.MuiTimeline-root": {
      padding: theme.spacing(0),
    }
  },
});

const useStyles = makeStyles((theme) => ({
  timelineBar: {
    "&.MuiTimelineOppositeContent-root": {
      padding: theme.spacing(0),
      flex: 0,
    }
  },
  timelineContent: {
    "&.MuiTimelineContent-root": {
      padding: theme.spacing(1.25, 2),
    }
  },
}));

const defaultTeam1 = {
  id: 0,
  name: "GOATs Only",
  owners: [{
    display_name: "Kneeeel",
    first_name: "Neil",
    last_name: "Alberg",
  }],
  logo_url: "https://i.imgur.com/6TPOaiQ.jpg",
  standing: 1,
  wins: 2,
  losses: 0,
};

const defaultTeam2 = {
  id: 1,
  name: "moore money moore problems",
  owners: [{
    display_name: "acell",
    first_name: "Allie",
    last_name: "Cell",
  }],
  logo_url: "https://g.espncdn.com/lm-static/logo-packs/core/DIS_Avengers_EndGame/DIS_Avengers_EndGame_Rocket.svg",
  standing: 10,
  wins: 0,
  losses: 2,
};

function TimelineSection(props) {
  const classes = useStyles();
  const {
    lastItem,
  } = props;

  return (
    <TimelineItem>
      <TimelineOppositeContent className={classes.timelineBar} />
      <TimelineSeparator>
        <TimelineDot 
          variant="outlined"
          color="primary"
        />
        {!lastItem && (
          <TimelineConnector />
        )}
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <Box
          mt={-1}
          mb={5}
        >
          {props.children}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
}

class HomePage extends Component {
  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };

  isLoaded = () => {
    const {
      leagues,
      orderedLeagues,
      myUserEntries,
      orderedMyUserEntries,
    } = this.props;

    if (this.props.user) {
      return isLoaded(
        leagues,
        orderedLeagues,
        myUserEntries,
        orderedMyUserEntries,
      );
    } else {
      return isLoaded(
        leagues,
        orderedLeagues,
      );
    }
  }

  render() {
    const {
      classes,
      user,
      orderedLeagues,
      orderedMyUserEntries,
    } = this.props;

    const {
      onSignInClick,
      onSignUpClick,
    } = this.props;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const leaguesWithEntry = filter(orderedLeagues, (league) =>
      some(orderedMyUserEntries, (entry)=>
        entry.leagueId === league.id)
    );
    const leaguesWithoutEntry = reject(orderedLeagues, (league) =>
      some(orderedMyUserEntries, (entry)=>
        entry.leagueId === league.id)
    );

    return (
      <Box>
        <Hidden mdUp>
          <Box p={2}>
            <Typography variant="h6">
              Welcome to fantasy fantasy football!
            </Typography>
            {leaguesWithEntry.length === 0 && (
              <Typography variant="subtitle2" gutterBottom>
                Here's how to play along:
              </Typography>
            )}
            {!user && (
              <Timeline className={classes.timelineRoot} align="left">
                <TimelineSection>
                  <Typography variant="body1" paragraph>
                    Sign in to get started!
                  </Typography>
                  <ButtonGroup
                    color="primary"
                    variant="contained"
                  >
                    <Button onClick={onSignUpClick}>Sign up</Button>
                    <Button onClick={onSignInClick}>Sign in</Button>
                  </ButtonGroup>
                </TimelineSection>
                <TimelineSection>
                  <Typography variant="body1" paragraph>
                    Join one of these leagues!
                  </Typography>
                  {orderedLeagues.map((league) => (
                    <Box py={2} key={league.id}>
                      <LeagueInfo 
                        user={user}
                        league={league}
                      />
                    </Box>
                  ))}
                </TimelineSection>
                <TimelineSection lastItem>
                  <Typography variant="body1" paragraph>
                    Pick who you think will win each week!
                  </Typography>
                  <Matchup
                    isDemo
                    team1={defaultTeam1}
                    team2={defaultTeam2}
                    pick={defaultTeam1.id}
                  />
                </TimelineSection>
              </Timeline>
            )}
            {user && (
              <Timeline className={classes.timelineRoot} align="left">
                {leaguesWithEntry.length > 0 && (
                  <TimelineSection>
                    <Typography variant="body1" paragraph>
                      Your leagues:
                    </Typography>
                    {leaguesWithEntry.map((league) => {
                      const matchingEntry = find(orderedMyUserEntries, {"leagueId": league.id, "year": league.year})
                      return (
                        <Box py={2} key={league.id}>
                          <LeagueInfo 
                            user={user}
                            league={league}
                            entry={matchingEntry}
                          />
                        </Box>
                      );
                    })}
                  </TimelineSection>
                )}
                {leaguesWithoutEntry.length > 0 && (
                  <TimelineSection>
                    <Typography variant="body1" paragraph>
                      {leaguesWithEntry.length === 0 ? "Join one of these leagues:" : "Other leagues:"}
                    </Typography>
                    {leaguesWithoutEntry.map((league) => (
                      <Box py={2} key={league.id}>
                        <LeagueInfo 
                          user={user}
                          league={league}
                        />
                      </Box>
                    ))}
                  </TimelineSection>
                )}
                <TimelineSection lastItem>
                  <Typography variant="body1" paragraph>
                    Pick who you think will win each week!
                  </Typography>
                  <Matchup
                    isDemo
                    team1={defaultTeam1}
                    team2={defaultTeam2}
                    pick={defaultTeam1.id}
                  />
                </TimelineSection>
              </Timeline>
            )}
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box p={2}>
            <Typography variant="h6">
              Welcome to fantasy fantasy football!
            </Typography>
            {leaguesWithEntry.length === 0 && (
              <Typography variant="subtitle2" gutterBottom>
                Here's how to play along:
              </Typography>
            )}
            {!user && (
              <Timeline className={classes.timelineRoot} align="left">
                <TimelineSection>
                  <Typography variant="body1" paragraph>
                    Sign in to get started!
                  </Typography>
                  <ButtonGroup
                    color="primary"
                    variant="contained"
                  >
                    <Button onClick={onSignUpClick}>Sign up</Button>
                    <Button onClick={onSignInClick}>Sign in</Button>
                  </ButtonGroup>
                </TimelineSection>
                <TimelineSection>
                  <Typography variant="body1" paragraph>
                    Join one of these leagues!
                  </Typography>
                  {orderedLeagues.map((league) => (
                    <Box py={2} key={league.id}>
                      <LeagueInfo 
                        user={user}
                        league={league}
                      />
                    </Box>
                  ))}
                </TimelineSection>
                <TimelineSection lastItem>
                  <Typography variant="body1" paragraph>
                    Pick who you think will win each week!
                  </Typography>
                  <Matchup
                    isDemo
                    team1={defaultTeam1}
                    team2={defaultTeam2}
                    pick={defaultTeam1.id}
                  />
                </TimelineSection>
              </Timeline>
            )}
            {user && (
              <Timeline className={classes.timelineRoot} align="left">
                {leaguesWithEntry.length > 0 && (
                  <TimelineSection>
                    <Typography variant="body1" paragraph>
                      Your leagues:
                    </Typography>
                    {leaguesWithEntry.map((league) => {
                      const matchingEntry = find(orderedMyUserEntries, {"leagueId": league.id, "year": league.year})
                      return (
                        <Box py={2} key={league.id}>
                          <LeagueInfo 
                            user={user}
                            league={league}
                            entry={matchingEntry}
                          />
                        </Box>
                      );
                    })}
                  </TimelineSection>
                )}
                {leaguesWithoutEntry.length > 0 && (
                  <TimelineSection>
                    <Typography variant="body1" paragraph>
                      {leaguesWithEntry.length === 0 ? "Join one of these leagues:" : "Other leagues:"}
                    </Typography>
                    {leaguesWithoutEntry.map((league) => (
                      <Box py={2} key={league.id}>
                        <LeagueInfo 
                          user={user}
                          league={league}
                        />
                      </Box>
                    ))}
                  </TimelineSection>
                )}
                <TimelineSection lastItem>
                  <Typography variant="body1" paragraph>
                    Pick who you think will win each week!
                  </Typography>
                  <Matchup
                    isDemo
                    team1={defaultTeam1}
                    team2={defaultTeam2}
                    pick={defaultTeam1.id}
                  />
                </TimelineSection>
              </Timeline>
            )}
          </Box>
        </Hidden>
      </Box>
    );
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

function mapStateToProps(state, props) {
  return {
    leagues: state.firestore.data.leagues,
    orderedLeagues: state.firestore.ordered.leagues,
    myUserEntries: state.firestore.data.myUserEntries,
    orderedMyUserEntries: state.firestore.ordered.myUserEntries,
  };
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps),
)(HomePage);

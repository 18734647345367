import {
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";

function PicksProgress(props) {
  const {
    numPicked,
    totalPicks,
  } = props;

  return (
    <>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={100 * numPicked / totalPicks} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${numPicked}/${totalPicks}`}</Typography>
        </Box>
      </Box>
      </>
  );
}

export default PicksProgress;
import { SvgIcon, } from "@material-ui/core";

import { HomeTwoTone as HomeIcon } from "@material-ui/icons";
import { ReactComponent as MaleAvatarIcon } from "../assets/icons/male-avatar.svg";
import { ReactComponent as PersonalDataIcon } from "../assets/icons/personal-information.svg";
import { ReactComponent as PodiumIcon } from "../assets/icons/podium.svg";
import { ReactComponent as RoseIcon } from "../assets/icons/rose-flower-outlined.svg";
import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";

export const HOME = "HOME";
export const STANDINGS = "STANDINGS";
export const RANKS = "RANKS";
export const SEASON_PREDICTIONS = "SEASON PREDICTIONS";
export const WEEKLY_PREDICTIONS = "WEEKLY PREDICTIONS";
export const USER_ENTRIES = "USER ENTRIES";
export const MY_ENTRY = "MY ENTRY";
export const SEASONS = "SEASONS";
export const ADMIN = "ADMIN";
export const LEAGUES = "LEAGUES";

const routes = {
  [HOME]: {
    path: "/",
    name: "Home",
    sidebarName: "Home",
    icon: <HomeIcon />,
  },
  [STANDINGS]: {
    path: "/standings",
    name: "Standings",
    sidebarName: "Standings",
    icon: <SvgIcon component={PodiumIcon} viewBox="0 0 512 512"/>,
  },
  [USER_ENTRIES]: {
    path: "/user-entries/:userEntryId",
    pathWithoutParam: "/user-entries/",
    name: "My Entry",
    sidebarName: "My Entry",
    icon: <SvgIcon component={RoseIcon} htmlColor="white" viewBox="0 0 512 512"/>,
  },
  [MY_ENTRY]: {
    path: "/my-entry",
    name: "My Entry",
    sidebarName: "My Entry",
    icon: <SvgIcon component={RoseIcon} htmlColor="white" viewBox="0 0 512 512"/>,
  },
  [SEASONS]: {
    path: "/seasons/:seasonId",
    pathWithoutParam: "/seasons/",
    name: "Seasons",
    sidebarName: "Seasons",
  },
  [ADMIN]: {
    path: "/admin",
    name: "Admin",
    sidebarName: "Admin",
  },
  [LEAGUES]: {
    path: "/leagues/:leagueId",
    pathWithoutParam: "/leagues/",
    name: "Leagues",
    sidebarName: "Leagues",
  },
};

export default routes;
import React, { Component, useEffect } from "react";

import PropTypes from "prop-types";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { firestoreConnect, isLoaded, } from 'react-redux-firebase'

import { BrowserRouter, Switch, Redirect, Route, useRouteMatch, useLocation, } from "react-router-dom";

import { Toolbar, Box, Container, } from "@material-ui/core";

import HomePage from "../HomePage";
import UserPicksPage from "../UserPicksPage";
import SeasonPredictionsPage from "../SeasonPredictionsPage";
import StandingsPage from "../StandingsPage";
import WeeklyPredictionsPage from "../WeeklyPredictionsPage";
import NotFoundPage from "../NotFoundPage";
import LeaguePage from "../LeaguePage";

import UserEntryPage from "../../features/userEntryPage/UserEntryPage";
import AdminPage from "../../features/admin/AdminPage";

import Loader from "../Loader";
import ScrollToTop from "../ScrollToTop";

import {
  selectCurrentSeason,
} from '../../features/navigation/navigationSlice';

import {
  defaultSeason,
} from "../../data/season-constants";

import routes, {
  HOME,
  STANDINGS,
  RANKS,
  SEASON_PREDICTIONS,
  USER_ENTRIES,
  MY_ENTRY,
  WEEKLY_PREDICTIONS,
  SEASONS,
  ADMIN,
  LEAGUES,
} from "../../data/routes";

import appearance from "../../services/appearance";

function LeagueRouter(props) {
  const match = useRouteMatch();

  // Properties
  const {
    user,
    roles,
    leagues,
    orderedLeagues,
  } = props;

  // Functions
  const {
    openSnackbar,
    onSignUpClick,
    onSignInClick,
    onSettingsClick,
    setTheme,
  } = props;

  return (
    <Switch>
      {/* TODO: use query params for year */}
      <Route exact path={`${match.path}/`}>
        {user ?
          <LeaguePage
            user={user}
            openSnackbar={openSnackbar}
          /> : <Redirect to={routes[HOME].path} />
        }
      </Route>

      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}

class Router extends Component {
  isLoaded = () => {
    const {
      leagues,
      orderedLeagues,
      myUserEntries,
      orderedMyUserEntries,
    } = this.props;

    if (this.props.user) {
      return isLoaded(
        leagues,
        orderedLeagues,
        myUserEntries,
        orderedMyUserEntries,
      );
    } else {
      return isLoaded(
        leagues,
        orderedLeagues,
      );
    }
  }

  render() {
    // Properties
    const {
      user,
      bar,
    } = this.props;

    // Functions
    const {
      openSnackbar,
      onSignUpClick,
      onSignInClick,
      onSettingsClick,
     } = this.props;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {bar}
        <Container disableGutters maxWidth="lg">
          <Box flex={1}>
            <Toolbar />
            <ScrollToTop />
            <Switch>
              <Route exact path={routes[HOME].path}>
                {/* {user 
                  ? <Redirect to={routes[SEASONS].pathWithoutParam + currentSeason} />
                  : <Redirect to={routes[SEASONS].pathWithoutParam + defaultSeason} />
                } */}
                <HomePage
                  user={user}
                  openSnackbar={openSnackbar}
                  onSignUpClick={onSignUpClick}
                  onSignInClick={onSignInClick}
                />
              </Route>

              <Route
                path={routes[LEAGUES].path}
              >
                <LeagueRouter
                  user={user}
                  openSnackbar={openSnackbar}
                  onSettingsClick={onSettingsClick}
                />
              </Route>
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
            </Box>
          </Container>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentSeason: selectCurrentSeason(state),
    leagues: state.firestore.data.leagues,
    orderedLeagues: state.firestore.ordered.leagues,
    myUserEntries: state.firestore.data.myUserEntries,
    orderedMyUserEntries: state.firestore.ordered.myUserEntries,
  };
}

function registerFirestoreListeners(props) {
  return [
    {
      collection: "leagues",
      where: ["year", "==", defaultSeason]
    },
    ...(props.user ? [
    {
      collection: "user_entries",
      where: ["userId", "==", props.user.uid],
      storeAs: `myUserEntries`,
    }] : [])
  ];
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
)(Router);

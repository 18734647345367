import React, { Component } from "react";

import { compose } from 'redux'

import { withRouter, } from "react-router-dom";

import { get } from "lodash";

import { Grid, List, RootRef, Paper, Typography, Box, Hidden, } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { Droppable } from "react-beautiful-dnd";

import ContestantCard from "../ContestantCard";

import RankingsIconColumn from "../RankingsIconColumn";

import EmptyState from "../EmptyState";

import {
  contestantDataBySeason,
} from "../../data/contestant-data";

const styles = (theme) => ({
  droppableZone: {
    minHeight: '300px',
    height: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.contrastPaper,
    height: "100%",
  },
  contestantColumn: {
    justifyContent: 'center',
    margin: 0,
    width: "100%",
  },
  gridContainer: {
    margin: 0,
    width: "100%",
  },
  emptyState: {
    borderStyle: 'dashed',
  }
});

class PicksColumn extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    // Passed callback
    const { onContestantClick, } = this.props;

    const { column, contestants, contestantData } = this.props;

    const {
      allIds,
      eliminatedWeek1Ids,
    } = contestantData;

    const numContestants = allIds.length;
    const numContestantsEliminated = eliminatedWeek1Ids.length;

    if (column.showRanks) {
      return (
        <Grid item xs={6}>
          <Hidden smUp>
            <Box height="100%">
              <Paper className={classes.paper}>
                <Grid container spacing={4} className={classes.gridContainer} >
                  <Grid item xs={1} style={{paddingRight: 0}}>
                    <RankingsIconColumn
                      startRank={1}
                      endRank={numContestants}
                      numContestantsEliminated={numContestantsEliminated}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      height="100%"
                    >
                      <Typography variant="h6" gutterBottom>
                        Rankings:
                      </Typography>
                      <Box flexGrow={1}>
                        <Droppable droppableId={column.id}>
                          {(provided) => (
                            <RootRef rootRef={provided.innerRef}>
                              <Box
                                className={classes.droppableZone}
                                {...provided.droppableProps}
                              >
                                {contestants.length === 0 && (
                                  <Box border={1} borderRadius={4} className={classes.emptyState}>
                                    <EmptyState
                                      type="card"
                                      title="Drop Your Picks Here!"
                                    />
                                  </Box>
                                )}

                                {contestants.length > 0 && (
                                  <List
                                    disablePadding
                                  >
                                    {contestants.map((contestant, index) =>
                                      <ContestantCard
                                        key={contestant.id}
                                        index={index}
                                        contestant={contestant}
                                        onClick={onContestantClick}
                                        draggable
                                        button
                                      />
                                    )}
                                  </List>
                                )}

                                {provided.placeholder}
                              </Box>
                            </RootRef>
                          )}
                        </Droppable>
                      </Box>
                      <List
                        disablePadding
                      >
                        {contestantData.eliminatedWeek1Ids.map((contestantId, index) =>
                          <ContestantCard
                            key={contestantId}
                            index={index}
                            contestant={contestantData.byId[contestantId]}
                            onClick={onContestantClick}
                            eliminated
                            gutterBottom
                            button
                            readOnly
                          />
                        )}
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
            </Hidden>
            <Hidden xsDown>
            <Box ml={2.5} height="100%">
              <Paper className={classes.paper}>
                <Grid container spacing={4} className={classes.gridContainer} >
                  <Grid item xs={1} style={{paddingRight: 0}}>
                    <RankingsIconColumn
                      startRank={1}
                      endRank={numContestants}
                      numContestantsEliminated={numContestantsEliminated}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      height="100%"
                    >
                      <Typography variant="h6" gutterBottom>
                        Your Rankings:
                      </Typography>
                      <Box flexGrow={1}>
                        <Droppable droppableId={column.id}>
                          {(provided) => (
                            <RootRef rootRef={provided.innerRef}>
                              <Box
                                className={classes.droppableZone}
                                {...provided.droppableProps}
                              >
                                {contestants.length === 0 && (
                                  <Box border={1} borderRadius={4} className={classes.emptyState}>
                                    <EmptyState
                                      type="card"
                                      title="Drop Your Picks Here!"
                                    />
                                  </Box>
                                )}

                                {contestants.length > 0 && (
                                  <List
                                    disablePadding
                                  >
                                    {contestants.map((contestant, index) =>
                                      <ContestantCard
                                        key={contestant.id}
                                        index={index}
                                        contestant={contestant}
                                        onClick={onContestantClick}
                                        draggable
                                        button
                                      />
                                    )}
                                  </List>
                                )}

                                {provided.placeholder}
                              </Box>
                            </RootRef>
                          )}
                        </Droppable>
                      </Box>
                      <List
                        disablePadding
                      >
                        {contestantData.eliminatedWeek1Ids.map((contestantId, index) =>
                          <ContestantCard
                            key={contestantId}
                            index={index}
                            contestant={contestantData.byId[contestantId]}
                            onClick={onContestantClick}
                            eliminated
                            gutterBottom
                            button
                          />
                        )}
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            </Hidden>
          </Grid>
        );
      }

      return (
        <Grid item xs={6}>
          <Hidden smUp>
          <Box height="100%">
            <Paper className={classes.paper}>
              <Grid container spacing={4} className={classes.contestantColumn}>
                <Grid item xs={10}>
                  <Typography variant="h6" gutterBottom>
                    Contestants:
                  </Typography>
                  <Droppable droppableId={column.id}>
                    {(provided, snapshot) => (
                      <RootRef rootRef={provided.innerRef}>
                        <Box
                          className={classes.droppableZone}
                          {...provided.droppableProps}
                        >
                          {contestants.length > 0 && (
                            <List
                              disablePadding
                            >
                              {contestants.map((contestant, index) => 
                                <ContestantCard
                                  key={contestant.id}
                                  index={index}
                                  contestant={contestant}
                                  onClick={onContestantClick}
                                  draggable
                                  button
                                />
                              )}
                            </List>
                          )}

                          {provided.placeholder}
                        </Box>
                      </RootRef>
                    )}
                  </Droppable>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          </Hidden>
          <Hidden xsDown>
          <Box mr={2.5} height="100%">
            <Paper className={classes.paper}>
              <Grid container spacing={4} className={classes.contestantColumn}>
                <Grid item xs={11}>
                  <Typography variant="h6" gutterBottom>
                    Contestants:
                  </Typography>
                  <Droppable droppableId={column.id}>
                    {(provided, snapshot) => (
                      <RootRef rootRef={provided.innerRef}>
                        <Box
                          className={classes.droppableZone}
                          {...provided.droppableProps}
                        >
                          {contestants.length > 0 && (
                            <List
                              disablePadding
                            >
                              {contestants.map((contestant, index) => 
                                <ContestantCard
                                  key={contestant.id}
                                  index={index}
                                  contestant={contestant}
                                  onClick={onContestantClick}
                                  draggable
                                  button
                                />
                              )}
                            </List>
                          )}

                          {provided.placeholder}
                        </Box>
                      </RootRef>
                    )}
                  </Droppable>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          </Hidden>

      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(PicksColumn);

import React, { Component } from "react";

import { compose } from 'redux'

import { get } from "lodash";

import { connect } from 'react-redux'

import { withRouter, } from "react-router-dom";

import {
  firestoreConnect,
  populate,
  isLoaded,
} from 'react-redux-firebase'

import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  Hidden,
  Link,
} from "@material-ui/core";

import {
  myUserEntry,
} from "../../data/season-constants";

import { firestoreConstants, } from "../../firebase";

import TabPanel from "../TabPanel";

import WeeklyPredictionsForm from "../WeeklyPredictionsForm";

import Loader from "../Loader";

import userEntries from "../../services/user-entries";

class WeeklyPredictionsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0,
      weeklyPredictionsAnswers: { },
    };

    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleAnswerSave = this.handleAnswerSave.bind(this);
  }

  onSaveClick = () => {
    const {
      openSnackbar,
      myUserEntryAll,
    } = this.props;

    const {
      weeklyPredictionsAnswers,
    } = this.state;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    userEntries.updateWeeklyPredictions(myUserEntry, weeklyPredictionsAnswers, openSnackbar);
  }

  handleAnswerChange = (weekId, questionId, answer) => {
    this.setState({
      weeklyPredictionsAnswers: {
        [weekId]: {
          ...this.state.weeklyPredictionsAnswers[weekId],
          [questionId]: answer,
        },
      }
    });
  };

  handleAnswerSave = (weekId, questionId, answer) => {
    const {
      openSnackbar,
      myUserEntryAll,
    } = this.props;

    const updatedState = {
      weeklyPredictionsAnswers: {
        [weekId]: {
          ...this.state.weeklyPredictionsAnswers[weekId],
          [questionId]: answer,
        },
      }
    };

    this.setState(updatedState);

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    userEntries.updateWeeklyPredictions(myUserEntry, updatedState.weeklyPredictionsAnswers, openSnackbar);
  };

  handleTabChange = (event, newTab) => {
    this.setState({
      selectedTabIndex: newTab,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const {
        myUserEntryAll,
        seasons,
      } = this.props;

      const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll, seasons);

      const {
        weeklyPredictions = {},
      } = myUserEntry;

      this.setState({
        weeklyPredictionsAnswers: {
          ...weeklyPredictions,
        },
      });
    }
  }

  isLoaded = () => {
    const {
      myUserEntryAll,
      myUserEntryById,
      seasons,
    } = this.props;

    return isLoaded(myUserEntryAll, myUserEntryById, seasons);
  }

  render() {
    const {
      user,
      seasons,
    } = this.props;

    const {
      selectedTabIndex,
      weeklyPredictionsAnswers,
    } = this.state;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    const season = get(seasons, seasonId);

    const {
      isWeeklyPredictionsLocked,
      currentWeeklyPredictionsWeek,
      weeklyPredictionsQuestions,
      contestantData,
    } = season;

    const currentWeeksQuestions = weeklyPredictionsQuestions.byWeek[currentWeeklyPredictionsWeek];
    const currentWeeksAnswers = weeklyPredictionsAnswers[currentWeeklyPredictionsWeek];
    const {
      trailerLink,
    } = currentWeeksQuestions;

    return (
      <>
        <Hidden mdUp>
          <Box>
            <Paper>
              <Box pt={2} px={4}>
                <Box pb={2}>
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={selectedTabIndex}
                    onChange={this.handleTabChange}
                    centered
                  >
                    <Tab label={"Week " + currentWeeklyPredictionsWeek} />
                  </Tabs>
                </Box>
                {trailerLink && (
                  <Box pb={2}>
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                  >
                    Episode {currentWeeklyPredictionsWeek} Trailer:
                  </Typography>
                  <Link
                    target="_blank"
                    href={trailerLink}
                    rel="noreferrer"
                  >
                    {trailerLink}
                  </Link>
                  </Box>
                )}
                <TabPanel value={selectedTabIndex} index={0}>
                  <WeeklyPredictionsForm
                    handleAnswerChange={this.handleAnswerChange}
                    handleAnswerSave={this.handleAnswerSave}
                    onSaveClick={this.onSaveClick}
                    weekId={currentWeeklyPredictionsWeek}
                    questions={currentWeeksQuestions}
                    answers={currentWeeksAnswers}
                    seasonId={seasonId}
                    contestantData={contestantData}
                    locked={isWeeklyPredictionsLocked}
                  />
                </TabPanel>
              </Box>
            </Paper>
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box p={2.5}>
            <Paper>
              <Box pt={2} px={4}>
                <Box pb={2}>
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={selectedTabIndex}
                    onChange={this.handleTabChange}
                    centered
                  >
                    <Tab label={"Week " + currentWeeklyPredictionsWeek} />
                  </Tabs>
                </Box>
                {trailerLink && (
                  <Box pb={2}>
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                    >
                      Episode {currentWeeklyPredictionsWeek} Trailer:
                    </Typography>
                    <Link
                      target="_blank"
                      href={trailerLink}
                      rel="noreferrer"
                    >
                      {trailerLink}
                    </Link>
                  </Box>
                )}
                <TabPanel value={selectedTabIndex} index={0}>
                  <WeeklyPredictionsForm
                    handleAnswerChange={this.handleAnswerChange}
                    handleAnswerSave={this.handleAnswerSave}
                    onSaveClick={this.onSaveClick}
                    weekId={currentWeeklyPredictionsWeek}
                    questions={currentWeeksQuestions}
                    answers={currentWeeksAnswers}
                    seasonId={seasonId}
                    contestantData={contestantData}
                    locked={isWeeklyPredictionsLocked}
                  />
                </TabPanel>
              </Box>
            </Paper>
          </Box>
        </Hidden>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return {
    myUserEntryById: populate(state.firestore, `${seasonId}#${myUserEntry}`, firestoreConstants.populateUserIds),
    myUserEntryAll: state.firestore.ordered[`${seasonId}#${myUserEntry}`],
    seasons: state.firestore.data.seasons,
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
        where: ["userId", "==", props.user.uid],
      }],
      populates: firestoreConstants.populateUserIds,
      storeAs: `${seasonId}#${myUserEntry}`,
    },
  ];
}

export default compose (
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
)(WeeklyPredictionsPage);

import React, { Component } from "react";

import { List, SvgIcon, ListItem, Box, Typography, Hidden } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { ReactComponent as FirstPlaceTrophyIcon } from "../../assets/icons/trophy-first-place.svg";
import { ReactComponent as SecondPlaceTrophyIcon } from "../../assets/icons/trophy-second-place.svg";
import { ReactComponent as ThirdPlaceTrophyIcon } from "../../assets/icons/trophy-third-place.svg";

const styles = (theme) => ({
  icon: {
    justifyContent: 'center',
  },
});

const iconMap = {
  1: <SvgIcon component={FirstPlaceTrophyIcon} viewBox="0 0 512 512" />,
  2: <SvgIcon component={SecondPlaceTrophyIcon} viewBox="0 0 512 512" />,
  3: <SvgIcon component={ThirdPlaceTrophyIcon} viewBox="0 0 512 512" />,
}

const ranksWithIcons = [1, 2, 3,]

class RankingsIconColumn extends Component {
  numericalRankings = (start, end) => Array.from({length: (end - start + 1)}, (v, k) => k + start);

  render() {
    // Styling
    const {
      classes,
      startRank,
      endRank,
      numContestantsEliminated,
     } = this.props;

    const ranks = this.numericalRankings(startRank, endRank);
    const ranksOfEliminated = this.numericalRankings(endRank - numContestantsEliminated + 1, endRank);

    return (
      <List disablePadding>
        <Box pb={3}>
          <ListItem />
        </Box>
        {ranks.map((rank) => 
          {
            if (ranksWithIcons.includes(rank)) {
              return (
                <>
                  <Hidden xsDown>
                    <Box key={rank} pt={2} pb={4}>
                      <ListItem disableGutters className={classes.icon}>
                        {iconMap[rank]}
                      </ListItem>
                    </Box>
                  </Hidden>
                  <Hidden smUp>
                    <Box key={rank} pt={1.875} pb={3.125}>
                      <ListItem disableGutters className={classes.icon}>
                        {iconMap[rank]}
                      </ListItem>
                    </Box>
                  </Hidden>
                </>
              );
            } else if (ranksOfEliminated.includes(rank)){
              return (
                <>
                  <Hidden xsDown>
                    <Box key={rank} pt={1.875} pb={3.875}>
                      <ListItem disableGutters className={classes.icon}>
                        <Typography variant="h6">
                          {rank}.
                        </Typography>
                      </ListItem>
                    </Box>
                  </Hidden>
                  <Hidden smUp>
                    <Box key={rank} pt={1.25} pb={3.5}>
                      <ListItem disableGutters className={classes.icon}>
                        <Typography variant="h6">
                          {rank}.
                        </Typography>
                      </ListItem>
                    </Box>
                  </Hidden>
                </>
              );
            } else {
              return (
                <>
                  <Hidden xsDown>
                    <Box key={rank} pt={1.5} pb={3.5}>
                      <ListItem disableGutters className={classes.icon}>
                        <Typography variant="h6">
                          {rank}.
                        </Typography>
                      </ListItem>
                    </Box>
                  </Hidden>
                  <Hidden smUp>
                    <Box key={rank} pt={1.25} pb={2.75}>
                      <ListItem disableGutters className={classes.icon}>
                        <Typography variant="h6">
                          {rank}.
                        </Typography>
                      </ListItem>
                    </Box>
                  </Hidden>
                </>
              );
            }
          }
        )}
      </List>
    );
  }
}

export default withStyles(styles)(RankingsIconColumn);

import React, { Component } from "react";

import { get } from "lodash";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

import {
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";

import QuizFormEntry from "../../components/QuizFormEntry";

import seasons from "../../services/seasons";

import {
  answerTypes,
} from "../../data/question-types";

const styles = (theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  roseCeremeonyItem: {
    "&.MuiAccordionDetails-root": {
      flexDirection: "column",
    },
  },
});

class SeasonPredictions extends Component {
  constructor(props) {
    super(props);

    const {
      season,
    } = this.props;
    
    const {
      seasonPredictionsQuestions,
    } = season;

    this.state = Object.fromEntries(get(seasonPredictionsQuestions, ["allIds"], []).map((id) => ( [id, get(seasonPredictionsQuestions, ["byId", id, "answer"])])));

    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleAnswerSave = this.handleAnswerSave.bind(this);
    this.handleAnswerTypeSave = this.handleAnswerTypeSave.bind(this);
  }

  handleAnswerChange = (questionId, answer) => {
    this.setState({
      [questionId]: answer,
    });
  };

  handleAnswerSave = (questionId, answer) => {
    const {
      season,
      openSnackbar,
    } = this.props;

    const {
      id: seasonId,
    } = season;

    this.setState({
      [questionId]: answer,
    });

    seasons.updateSeasonPredictionsQuestion(seasonId, questionId, answer, openSnackbar);
  };

  handleAnswerTypeSave = (event, questionId) => {
    const {
      season,
      openSnackbar,
    } = this.props;

    const {
      id: seasonId,
    } = season;

    const answerType = event.target.value;

    seasons.updateSeasonPredictionsAnswerType(seasonId, questionId, answerType, openSnackbar);
  }

  render() {
    const { classes } = this.props;

    const {
      season,
      openSnackbar,
    } = this.props;

    const {
      dialogOpen,
    } = this.state;

    const {
      id: seasonId,
      seasonPredictionsQuestions,
      contestantData,
    } = season;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Season Predictions</Typography>
        </AccordionSummary>
        <AccordionDetails
          className={classes.roseCeremeonyItem}
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            {seasonPredictionsQuestions && seasonPredictionsQuestions.allIds.map((questionId) => {
              const question = seasonPredictionsQuestions.byId[questionId];
              const answerType = get(question, "answerType");
              const hasAnswerType = answerType !== undefined;

              return (
                <Box
                  pb={4}
                  key={questionId}
                >
                  <QuizFormEntry 
                    question={question}
                    answer={this.state[questionId]}
                    onChange={this.handleAnswerChange}
                    handleAnswerSave={this.handleAnswerSave}
                    seasonId={seasonId}
                    contestantData={contestantData}
                    disabled={!hasAnswerType}
                    isAdmin={true}
                  />
                  <FormControl
                    fullWidth
                    variant="filled"
                    size="small"
                  >
                    <InputLabel>Answer Type</InputLabel>
                    <Select
                      onChange={(event) => this.handleAnswerTypeSave(event, questionId)}
                      value={answerType || ""}
                    >
                      {answerTypes.map((answer) => 
                        <MenuItem
                          key={answer}
                          value={answer}
                        >
                          {answer}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              )
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default withStyles(styles)(SeasonPredictions);;

import React, { Component } from "react";

import {
  Box,
  Grid,
  Typography,
  SvgIcon,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import ContestantCard from "../ContestantCard";
import { ReactComponent as FirstPlaceTrophyIcon } from "../../assets/icons/trophy-first-place.svg";
import { ReactComponent as SecondPlaceTrophyIcon } from "../../assets/icons/trophy-second-place.svg";
import { ReactComponent as ThirdPlaceTrophyIcon } from "../../assets/icons/trophy-third-place.svg";

const styles = (theme) => ({
  gridContainer: {
    margin: 0,
    width: "100%",
  },
  contestantCard: {
    minHeight: theme.spacing(9),
  },
});

const iconMap = {
  1: <SvgIcon component={FirstPlaceTrophyIcon} viewBox="0 0 512 512" />,
  2: <SvgIcon component={SecondPlaceTrophyIcon} viewBox="0 0 512 512" />,
  3: <SvgIcon component={ThirdPlaceTrophyIcon} viewBox="0 0 512 512" />,
}

const ranksWithIcons = [1, 2, 3,]

class ContestantRankingRow extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    const {
      rank,
      contestant,
      pointsPerRose,
      receivedRose,
      scoredPoints,
      belowThreshold,
      newlyEliminated,
      eliminated,
      isBachelorette,
    } = this.props;

    return (
      <Box
        display="flex"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={2}
          minWidth="30px"
        >
          {ranksWithIcons.includes(rank) 
            ? (
              iconMap[rank]
            )
            : (
              <Typography variant="h6">
                {rank}.
              </Typography>
            )
          }
        </Box>
        <Box
          flexGrow={1}
          className={classes.contestantCard}
        >
          {contestant &&
            <ContestantCard
              contestant={contestant}
              readOnly
              rank={rank}
              pointsPerRose={pointsPerRose}
              receivedRose={receivedRose}
              scoredPoints={scoredPoints}
              belowThreshold={belowThreshold}
              eliminated={eliminated}
              newlyEliminated={newlyEliminated}
              isBachelorette={isBachelorette}
            />
          }
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(ContestantRankingRow);

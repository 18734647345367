const katieContestantData = {
  byId: {
    1: {
      id: 1,
      name: 'Aaron',
      age: 27,
      occupation: 'Insurance Agent',
      hometown: 'San Diego, CA',
      fullImageName: 'aaron_summer_2021.jpeg',
      croppedImageName: 'aaron_summer_2021_cropped.jpeg',
      biography: {
        description: "It's easy to see that Aaron is a strapping young man, but don't be fooled, there is much more to this California stud than meets the eye. Aaron currently works in sales and enjoys being outdoors, surfing and lifting weights, and he was an NCAA swimmer in college. He is thoughtful, sensitive and very ambitious, saying that in five years' time, he hopes to own his business and work for himself. Aaron is looking for a woman who is down to earth, smart, funny and is someone who doesn't take herself too seriously. Aaron plans to leave a legacy behind, so he needs a partner who is going to support him and encourage his dreams. His one flaw? Aaron says he can be a procrastinator, but now he is done wasting time and is ready to find his wife!",
        likes: [
          "Aaron has one tattoo - his family's initials on his neck.",
          "Michelle is not into hot tub dates because they are too cliché.",
          "Aaron's favorite holiday is Halloween because he loves when things get spooky.",
        ],
        allieDescription: "“I can’t lie i’m a little nervous, you’re radiant, people generally say I have a nice smile” … can’t stop talking about himself, seems less good than Thomas but she gives the hosts 2 thumbs up? Also he just like picks a fight with the sex doll guy (Cody) … and yells at a bunch of people? ROSE 20.",
      },
      weekEliminated: 6,
    },
    2: {
      id: 2,
      name: 'Andrew M',
      age: 31,
      occupation: 'Deputy District Attorney',
      hometown: 'Newport Beach, CA',
      fullImageName: 'andrew_m_summer_2021.jpeg',
      croppedImageName: 'andrew_m_summer_2021_cropped.jpeg',
      biography: {
        description: "Andrew M. is a deputy district attorney who loves his job, but his identity goes far beyond what he does from 8-5. When he's not in the court room, you can find Andrew M. coaching youth water polo or building bonfires on the beach. Now, he just needs someone to snuggle up with by that fire. Andrew M. is looking for a woman who is open-minded and adaptable, exudes positive energy, but also has a fun side and a great sense of humor because – fun fact – Andrew M. LOVES to play pranks. Andrew M.'s life is all about adventure and he is here because what greater adventure is there than the search for true love?",
        likes: [
          "Andrew M. played one year of professional water polo in Hungary's First Division – the most competitive league in the world.",
          "Andrew M. loves to sample men's fragrances from magazine inserts.",
          "Andrew M. enjoys woodworking.",
          "When Andrew M. graduated from college, he \"Tebowed\" on the stage.",
        ],
        allieDescription: "The reason he’s here is for you, he’s very excited to meet you and see where it goes. He seems reasonable. Looks back at her which is cute. He giggles once. ROSE 15.",
      },
      weekEliminated: 5,
    },
    3: {
      id: 3,
      name: 'Andrew S',
      age: 26,
      occupation: 'Pro Football Player',
      hometown: 'Vienna, Austria',
      fullImageName: 'andrew_s_summer_2021.jpeg',
      croppedImageName: 'andrew_s_summer_2021_cropped.jpeg',
      biography: {
        description: "Andrew S. is truly a man of the world. He spends half the year living in Vienna, Austria, where he plays professional football, and the other half in the Chicago area, where he works as a football coach and teacher's assistant. Andrew S. says living in Europe has given him an updated outlook on life where people work to live instead of live to work. He is not afraid to make a grand romantic gesture and says that when the moment calls for it, he has no problem driving for hours just to hand deliver lunch and fresh flowers to that special woman. Andrew S. can't wait to get married and hopes to have five kids one day. The one thing he cannot handle in a relationship is poor communication. Lucky for him, the Bachelorette has no trouble in that arena!",
        likes: [
          "Andrew S. has a great sense of humor, and one of his favorite party tricks is imitating accents from around the globe.",
          "Andrew S. loves to nerd out over Ancient Rome and says that seeing the Colosseum in person was a dream come true.",
          "Andrew S. loves margaritas and will happily challenge anyone who says they can make a better one than he can.",
          "Andrew S. is very close with his 96-year-old grandmother.",
        ],
        allieDescription: "Got an intro video, plays football in Austria, wants to try to make her laugh, oh I like him. He drew a smiley face on a paper bag and is doing an accent. He does an Austrian accent when meeting her, she does not believe him. “He’s got a dumptruck!” - Neil. He pulls her first! We get a nice lil scene of them talking together. They both do some english accents together and she makes a cheers. We like him. ROSE 7.",
      },
      weekEliminated: 7,
    },
    4: {
      id: 4,
      name: 'Austin',
      age: 25,
      occupation: 'Real Estate Investor',
      hometown: 'Mission Viejo, CA',
      fullImageName: 'austin_summer_2021.jpeg',
      croppedImageName: 'austin_summer_2021_cropped.jpeg',
      biography: {
        description: "Austin has been very busy in quarantine – not only does he work out at least six days a week, but in 2020 alone, he flipped 20 properties and says that in 2021 his goal is to flip over 100. He is smooth, funny, handsome and driven to succeed in all aspects of his life. He believes that ambition is the most important trait that his partner can have and says that doing life together means driving each other to be better. Austin says his weakness is a woman with a great smile and that he would love to find someone he can get dressed up for and enjoy a night out on the town with. While Austin has dated many women, he says that he hasn't found a relationship serious enough from him to consider marriage, but he is hoping to change that. He is truly searching for the Beyoncé to his Jay-Z and has no intention of compromising.",
        likes: [
          "Austin loves to spend the day at the beach and work on his tan.",
          "Austin doesn't currently have any tattoos, but he definitely wants to get a few.",
          "Austin loves his white shoes and fears the day that someone spills on them.",
        ],
        allieDescription: "“I’m on the pursuit of happiness.”",
      },
      weekEliminated: 1,
    },
    5: {
      id: 5,
      name: 'Brandon',
      age: 26,
      occupation: 'Auto Parts Manager',
      hometown: 'Queens, NY',
      fullImageName: 'brandon_summer_2021.jpeg',
      croppedImageName: 'brandon_summer_2021_cropped.jpeg',
      biography: {
        description: "Brandon is quirky, intelligent and unapologetically himself. He attended Northwestern University where he says he learned to outgrow behaviors that limit his progress as a person. He currently spends most of his weekends at home working on a new skill he's trying to develop. Currently, languages are a big interest of his – he is fluent in Japanese and Spanish and is working on expanding his Korean vocabulary. Brandon needs a woman who understands the importance of putting in effort. He's looking for an equal partner in life and says he could never be with anyone who would issue him an ultimatum. Brandon's ideal date would be filled with meaningful conversation, delicious cocktails and a nightcap at home getting cozy over movies and snacks. His idea of a romantic gift is a home-cooked meal or a handwritten letter. For Brandon, it's the experiences in life and not the material things that matter most.",
        likes: [
          "Brandon loves to attend the occasional rave.",
          "Brandon HATES exorbitantly overpriced restaurants.",
          "Brandon loves Japanese and Korean R&B.",
          "Brandon finds joy hitting the open road on a motorcycle.",
        ],
        allieDescription: "“I’m good how are you”",
      },
      weekEliminated: 1,
    },
    6: {
      id: 6,
      name: 'Brendan',
      age: 26,
      occupation: 'Firefighter Trainee',
      hometown: 'Toronto, Ontario, Canada',
      fullImageName: 'brendan_summer_2021.jpeg',
      croppedImageName: 'brendan_summer_2021_cropped.jpeg',
      biography: {
        description: "Brendan is the type of guy who always enjoys life to the fullest. He is currently in the process of becoming a full-time firefighter. Brendan takes a lot of pride over this goal and is excited to make a positive difference in his community. While Brendan's professional life is exactly where he wants it, he still dreams of adding a little fire to his personal life. In his partner, Brendan is looking for good back and forth banter, a sense of adventure and an open mind. He has no specific dealbreakers but says that he loves being clean and organized and hopes to find a woman who feels the same. He is a straight shooter and says that nothing makes him happier than putting a smile on the face of the woman he loves. Brendan says he is in a great spot in his life to go on this journey and try and find his perfect match!",
        likes: [
          "Brendan is not a good dancer, but that doesn't stop him from tearing up the dance floor!",
          "Brendan's favorite sport is hockey, and he plays goalie in a local league.",
          "Brendan really does not like vegetables.",
          "One day, Brendan hopes to take the love of his life swimming with sharks.",
        ],
        allieDescription: "Got an intro video, canadian, hockey player, full-time firefighter, has really aggressive hair. “We’re gonna have a lot of fun.” “Why are they all so hot?” - Katie. ROSE 18.",
      },
      weekEliminated: 7,
    },
    7: {
      id: 7,
      name: 'Christian',
      age: 26,
      occupation: 'Real Estate Agent',
      hometown: 'Boston, MA',
      fullImageName: 'christian_summer_2021.jpeg',
      croppedImageName: 'christian_summer_2021_cropped.jpeg',
      biography: {
        description: "Christian sees the romance in everything. His parents have been married since their early twenties and Christian says he dreams of finding a long-lasting love like theirs. He spends a lot of time with his family and says that he wants the woman that he ends up with to love them just as much as he does because, in all honesty, they are a good time! Christian wants a woman who is confident and driven, which makes sense considering how successful he is professionally – he's sold over $25 million worth of residential real estate properties. He's looking for his best friend and someone to go through life's successes and failures with, free of judgment. Christian's ideal woman values the meaning of making a promise and keeping their word. He hopes to find a woman who appreciates him for the thoughtful, hard worker that he is, while also pushing him to be the best version of himself.",
        likes: [
          "Christian dreams of driving the Batmobile one day.",
          "In his younger days, Christian rocked a fauxhawk.",
          "Christian loves parades.",
          "Don't let his smile fool you, he's competitive and has fought in mixed martial arts bouts.",
        ],
        allieDescription: "“I hope we can rub one out together” while holding a genie lamp. We get a quick cutaway of them laughing together. “I’m melting” .. true, they are all sweaty. ROSE 21.",
      },
      weekEliminated: 4,
    },
    8: {
      id: 8,
      name: 'Cody',
      age: 27,
      occupation: 'Zipper Sales Manager',
      hometown: 'San Diego, CA',
      fullImageName: 'cody_summer_2021.jpeg',
      croppedImageName: 'cody_summer_2021_cropped.jpeg',
      biography: {
        description: "On the surface, Cody is a handsome surfer with a six pack, but he's a romantic gentleman at heart. When he is not chasing the best waves he can find in the San Diego surf, he is hanging with his boys and making necessary moves to achieve his dream of owning a home by the age of 30. His other dream? Having someone special to share that future home with! When it comes to relationships, Cody values nothing more than authentic connections and has no trouble being transparent with his feelings. He prefers to put everything out there for better or for worse. Cody is looking for someone to look past his exterior and see what he is all about inside. He wants someone that is self-sufficient, socially confident, willing to push him and work together with him to drive the relationship forward. When Cody falls, he falls hard - he is the type of guy who buys flowers for no reason and will surprise you in the morning with breakfast in bed. Sounds like every girl's dream, right?",
        likes: [
          "Cody is a very proud Eagle Scout.",
          "Cody's favorite TV show is \"The Jersey Shore.\"",
          "Cody says he is not a good cook but swears he is a master on the grill.",
          "If Cody could live in any other time period, he would be a cowboy in the wild wild west.",
        ],
        allieDescription: "“he’s so handsome for her” - our hosts. He kind of reminds me of like Arthur. He then brings a sex doll. Its name is Sandy. She laughs. “Oh my god.” - Katie. FINAL ROSE 23.",
      },
      weekEliminated: 3,
    },
    9: {
      id: 9,
      name: 'Connor B',
      age: 29,
      occupation: 'Math Teacher',
      hometown: 'Nashville, TN',
      fullImageName: 'connor_b_summer_2021.jpeg',
      croppedImageName: 'connor_b_summer_2021_cropped.jpeg',
      biography: {
        description: "Connor B. is a lovable, quirky and charming eighth-grade math teacher. With a master's degree in medical physics and a resume that includes nuclear engineer as a job title, Connor B. definitely owns his nerdy side, but he is not afraid to poke fun at himself. Connor B. is looking for a woman who will share his enthusiasm and zest for living every day to its fullest. He describes his ideal partner as genuine, kind, socially accepting and open-minded. He wants someone who will build him up and will love his family as much as he does. She should also enjoy a good multi-location date with a theme because when Connor B. wants to impress his date with a good time, he likes to go all out! Connor B. says his one major dealbreaker is a woman who doesn't respect waiters because there is no place for any bullying in his life. Sounds like he and Katie already have something major in common!",
        likes: [
          "Connor B. loves dressing up and is the proud owner of his own tux.",
          "Connor B. claims he can eat cereal faster than anyone.",
          "Connor B. dreams of going to Italy and overindulging on pasta.",
        ],
        allieDescription: "Got an intro video, Naked playing the ukelele, math teacher who also sings. Kind of reminds me of Jed. Loves that Katie stands up for what she believes in and is confident and gorgeous. Shows up in a cat suit, says “I heard you’re a cat lover.” Gave double thumbs up….? Is maybe into it? Lol. He’s confident and funny enough to pull the whole thing off haha. She says “I can’t get the cat guy out of my head!” He also brought a little keyboard and they tap it with their feet together. He goes in for a big ol open mouth kiss. Her nose has his blake cat nose makeup all over him. “Flying high right now.” She goes back in for kiss # 3!!!! Lot of chemistry. ROSE 6.",
      },
      weekEliminated: 6,
    },
    10: {
      id: 10,
      name: 'Conor C',
      age: 29,
      occupation: 'Former Baseball Player',
      hometown: 'Costa Mesa, CA',
      fullImageName: 'conor_c_summer_2021.jpeg',
      croppedImageName: 'conor_c_summer_2021_cropped.jpeg',
      biography: {
        description: "Conor C. is an athletic stud with a Midwestern heart of gold. He currently lives in Southern California where he spends as much time as he can at the beach, but he dreams of moving home to Oklahoma to settle near his family and build a life with his wife by his side. He describes his dream woman as a giver who genuinely cares for others and wants to see those around her succeed. Conor C. is always up for a good time, whether that means going out for a night of bowling or enjoying an intimate night in. Finding someone who has the similar religious beliefs to him is very important to Conor C. because raising his future family in the church is a huge priority. Conor C. has never left the country, so hopefully this journey is just the start of new adventures for him and the Bachelorette!",
        likes: [
          "Conor C. and Matthew McConaughey share birthdays.",
          "Growing up, Conor C. loved his puka shell necklaces.",
          "Conor C. loves breakfast burritos and blueberry pancakes.",
        ],
        allieDescription: "“If I’m gonna be catching feelings, might as well bring a glove.” ROSE 17.",
      },
      weekEliminated: 4,
    },
    11: {
      id: 11,
      name: 'David',
      age: 27,
      occupation: 'Technical Product Specialist',
      hometown: 'Nashville, TN',
      fullImageName: 'david_summer_2021.jpeg',
      croppedImageName: 'david_summer_2021_cropped.jpeg',
      biography: {
        description: "David is a first generation American, born to and raised by Jamaican parents in New York. He recently left the family unit in New York to pursue career opportunities in tech sales in Nashville, TN. David is looking for a woman who is intelligent and driven to succeed. He is extremely attracted to women who exude a quiet confidence! Synchronicity in life is of the utmost importance for David, and he hopes to find a partner that is as passionate about building a successful life, both professionally and romantically, as he is. David wants a wife who will be proud to stand next to him for the rest of their lives, and he hopes to find that with the Bachelorette!",
        likes: [
          "New York City pizza is David's favorite food.",
          "David was on the debate team in high school.",
          "\'N Sync is David's favorite boy band.",
        ],
        allieDescription: "He’s a New Yorker and he knows anything can happen in a NY minute -- she says “aww”, he seems pretty nervous …. We get a quick cutaway of them holding hands and breathing together. ROSE 19.",
      },
      weekEliminated: 4,
    },
    12: {
      id: 12,
      name: 'Gabriel',
      age: 35,
      occupation: 'Entrepreneur',
      hometown: 'Charlotte, NC',
      fullImageName: 'gabriel_summer_2021.jpeg',
      croppedImageName: 'gabriel_summer_2021_cropped.jpeg',
      biography: {
        description: "Gabriel loves the expression \"Renaissance man\" and feels it describes him well. He prides himself on having a lot of passions and interests and enthusiastically says that his current passion project is finding his forever love! Gabriel says his best qualities are that he is introspective, creative and confident. He is not afraid to share his feelings and hopes to find a partner that he can evolve with through life. Gabriel loves a woman who is as stimulated by intelligent conversations as he is and who truly knows herself inside and out. In his perfect world, Gabriel says that he and his future wife will grow together and never stop unpeeling each other's layers.",
        likes: [
          "Gabriel doesn't understand the concept of \"athleisure\" and believes that \"people should respect elegant styles of the past.\"",
          "Gabriel loves to play tennis.",
          "Sean Connery is Gabriel's favorite James Bond.",
        ],
        allieDescription: "No physical contact? His hair is a lot. Oh now he is leading her through his favorite hug. A lot of breathing. Pretty weird? “I think he’s weird as fuck?” - Neil. “He’s cute” she says hesitantly .. oh but she keeps looking back at him.",
      },
      weekEliminated: 1,
    },
    13: {
      id: 13,
      name: 'Garrett',
      age: 29,
      occupation: 'Software Marketing Manager',
      hometown: 'Salinas, CA',
      fullImageName: 'garrett_summer_2021.jpeg',
      croppedImageName: 'garrett_summer_2021_cropped.jpeg',
      biography: {
        description: "Garrett was raised in a small California town where ranch life, barbecues and baseball were the backdrop of his childhood. Now, he lives a life dedicated to his career in marketing, which he absolutely loves, but the only thing missing is an amazing woman to share it with. His dream woman is caring, intelligent and someone who prides herself on being a critical thinker. Garrett loves being romantic and says the perfect date is a night out at his favorite steakhouse followed by cozy time at home and enjoying some good wine while getting warm by the fire. Garrett is a YOLO type of guy but take note - he is very loyal and is looking for a wife, NOT a one-night stand. At the end of the day, Garrett is looking for something that lasts forever and dreams of sitting down next to his wife, holding her hand and looking gratefully at the beautiful family that they've built together. How sweet is that?",
        likes: [
          "Garrett has a five-year-old German Shepherd named Archer and describes him as a \"very good boy.\"",
          "Garrett is not a fan of tangy foods.",
          "One day, Garrett wants to climb to the summit of Mount Everest.",
        ],
        allieDescription: "“I’m already super into you.” ROSE 5.",
      },
      weekEliminated: 3,
    },
    14: {
      id: 14,
      name: 'Greg',
      age: 27,
      occupation: 'Marketing Sales Representative',
      hometown: 'Edison, NJ',
      fullImageName: 'greg_summer_2021.jpeg',
      croppedImageName: 'greg_summer_2021_cropped.jpeg',
      biography: {
        description: "Greg is the full package. He's handsome, kind, vulnerable and serious about wanting to settle down. Greg considers himself a hopeless romantic, and a perfect first date would be something active like riding bikes, dancing or going to a concert. Greg wants to travel the world with his wife before having kids, of which he says he wants to have at least six! Greg is also extremely close with his family and says his parents set his definition of #romancegoals. Thanks to their love, the standards for what a successful marriage looks like are sky high. Greg wants to find someone he can grow old with and treat like a queen, so the Bachelorette may just be swept off her feet!",
        likes: [
          "Greg is not afraid to admit that he cries during movies.",
          "Greg's biggest pet peeve is slow drivers.",
          "Greg dreams of seeing Lebron James play basketball in person.",
        ],
        allieDescription: "Got an intro video, marketing sales rep, his nieces and nephews make him excited to be a dad, there is some shirtless footage, “out first round for sure” - Lynus. He seems genuine and nervous when getting out of the limo. He is choked up, but she grabs his face! Which seems like a good sign. His hair is nice. He has kind of like Jake Gyllenhall vibes. “He looks like my ex-boyfriend” - Katie!!! He talks to Katie about his niece who made her a necklace… she’s 3! Aw. He plays it up in a cute way. He is lookin pretty good honestly. He gets the first impression rose!!! Gets a kiss, Lynus says there was some tongue. He has a lil cute all frazzled-lookin confessional. ROSE 1.",
      },
      weekEliminated: 10,
    },
    15: {
      id: 15,
      name: 'Hunter',
      age: 34,
      occupation: 'Software Strategist',
      hometown: 'Houson, TX',
      fullImageName: 'hunter_summer_2021.jpeg',
      croppedImageName: 'hunter_summer_2021_cropped.jpeg',
      biography: {
        description: "Hunter is an expressive, passionate and outgoing person who does not believe in holding back. He is a father of two amazing children who he lives to make happy. Hunter has always been a romantic and says that even as a child, he was known for writing incredible love letters and always being the guy to surprise his crushes with mix tapes or flowers. He is looking for a woman to be his other half – someone who is compassionate, witty, great at communicating and, above all, someone who could love his kids as much as he does. Hunter says that he will never stop believing in romance because \"when you hit the mark, there really is no better feeling.\" He hopes to find someone to stare off into the stars with for the rest of his life, so let's see if he and Katie can go the distance!",
        likes: [
          "One of Hunter's favorite past times is people-watching.",
          "Hunter has never ridden a horse, but he REALLY wants to!",
          "Hunter has a pet parakeet named Zazu.",
        ],
        allieDescription: "“Brings a fish, says ‘I’m kind of a catch’. Not a fan favorite based off of the 4 of us watching. ROSE 22.",
      },
      weekEliminated: 6,
    },
    16: {
      id: 16,
      name: 'Jeff',
      age: 31,
      occupation: 'Surgical Skin Salesman',
      hometown: 'Jersey City, NJ',
      fullImageName: 'jeff_summer_2021.jpeg',
      croppedImageName: 'jeff_summer_2021_cropped.jpeg',
      biography: {
        description: "Jeff comes from a big, loving Italian family and is looking to find the love that his parents have. For Jeff, family is everything, and he still finds time every weekend to mow his parent's lawn, do some laundry and sit down for a big Italian dinner. When he's not chowing down on a plate of his mom's home-cooked meal, Jeff is dealing with his clients both in and out of the operating, where Jeff works as an artificial skin sales rep! His family and professional life are in a great place, and now, he is hoping to find the only thing he is missing – love. Jeff is energetic, outgoing and loving to his core. He's seeking an adventurous woman with a great sense of humor who won't have trouble giving it back to him when she sees fit. Jeff calls himself an exceptional lover, so here's to hoping he doesn't show up and disappoint!",
        likes: [
          "If Jeff could have any other job, he would be a CIA agent.",
          "Jeff has never met an animal that didn't love him.",
          "Jeff lives for good Italian food.",
        ],
        allieDescription: "Brings his RV, drives it up, says home is where the heart is. She brings him into his RV and omg he has dishes laying around. He is pretty creepy and has made 2 creepy jokes about the RV. “It’s definitely… lived-in.” He starts to eat celery while they’re talking. Omg it’s really dirty and has his dirty boxers laying around. She was just not feeling the vibes.",
      },
      weekEliminated: 1,
    },
    17: {
      id: 17,
      name: 'John',
      age: 27,
      occupation: 'Bartender',
      hometown: 'Pacific Beach, CA',
      fullImageName: 'john_summer_2021.jpeg',
      croppedImageName: 'john_summer_2021_cropped.jpeg',
      biography: {
        description: "John is a thrill-seeker and here at \"The Bachelorette,\" thrill he will find! Between studying to get his pilot's license, surfing the San Diego breaks, going to EDM shows and jumping out of planes, John is always finding ways to get his adrenaline pumping. But John is also a romantic and would love to find a woman to share a burrito with while watching the daily sunset over the San Diego coastline. He is looking for someone genuine, honest and willing to challenge him to be a better version of himself. John loves when a woman is confident in her natural beauty and says that nothing turns him on more than a woman who pursues her passions every day. He has very little tolerance for anyone being unfaithful or dishonest and says that his one major dealbreaker is when someone treats another disrespectfully. Sounds like he and the Bachelorette may have a lot in common!",
        likes: [
          "John is a vegetarian.",
          "John loves the smell of fireplaces.",
          "If you've never heard of a kiwiberry, John wants you to google them and eat one immediately!",
          "John thinks swing dancing is so much fun.",
        ],
        allieDescription: "Didn’t stand out but you know looks charming. She says “that’s my type …. Play it cool”. And then he looks back. ROSE 13.",
      },
      weekEliminated: 3,
    },
    18: {
      id: 18,
      name: 'Josh',
      age: 25,
      occupation: 'IT Consultant',
      hometown: 'Miami, FL',
      fullImageName: 'josh_summer_2021.jpeg',
      croppedImageName: 'josh_summer_2021_cropped.jpeg',
      biography: {
        description: "Josh loves hard and says there is really no other way to put it. He is humble, kind-hearted and goofy, but he's also a hopeless romantic who has always been very in touch with his emotions. Josh is looking for a woman that is kind, open-minded, ambitious and adventurous. He consistently strives to be genuine and wants to find a partner who will do the same. Josh is extremely family oriented, and finding someone he can bring home to hang with his amazing family for a night of barbecue and playing cards is his dream come true. He plans to show his future wife and kids the same unconditional love he was surrounded by growing up, and really, who could ask for anything more?",
        likes: [
          "Josh hopes to travel to Asia one day.",
          "Josh has to sleep on the right side of the bed.",
          "Josh starts celebrating Christmas in October.",
        ],
        allieDescription: "“I’m like 60% optimistic and 20% nervous and 20% excited and 100% here for you”. ROSE 16.",
      },
      weekEliminated: 5,
    },
    19: {
      id: 19,
      name: 'Justin',
      age: 26,
      occupation: 'Investment Sales Consultant',
      hometown: 'Baltimore, MD',
      fullImageName: 'justin_summer_2021.jpeg',
      croppedImageName: 'justin_summer_2021_cropped.jpeg',
      biography: {
        description: "Justin is one hell of a catch – tall, handsome, has a great career in investment sales, and for the cherry on top, he is also an extremely talented painter! He comes from a loving family and credits his parents, who have been happily married for over 30 years, with providing him an excellent example of what a successful relationship should look like. While Justin has dated around, he's never been one to settle and just hasn't found \"the one.\" For Justin, that one woman is someone who is equally beautiful on the inside and out, trustworthy and committed to the idea of teamwork in a relationship. She's passionate about fitness, but also loves to enjoy a day barbecuing and a good bottle of Sauvignon Blanc. Justin considers himself to be very romantic and holds a lot of value in having a strong intimate connection, both emotionally and physically. For Justin, this journey is all about finding his forever, so here's hoping he can find that meaningful connection with Katie.",
        likes: [
          "Justin does not like to dance. At all.",
          "Justin's favorite artist of all time is Basquiat.",
          "If Justin could be any superhero, he would be Black Panther.",
        ],
        allieDescription: "Got an intro video -- pretty artistic and also jacked and loves that she is not afraid to be herself. Wow amazing painting that he is working on. Also from close to Neil’s high school. He makes some sexual innuendo … “I like to stroke, stroke it out” … referring to his paintbrush! Ha! He presents his painting … there’s something missing in the middle, … seems like she’s maybe faking it, but he does go in for the kiss and start making out with her. Gets first kiss. I am confused. ROSE 9.",
      },
      weekEliminated: 10,
    },
    20: {
      id: 20,
      name: 'Karl',
      age: 34,
      occupation: 'Motivational Speaker',
      hometown: 'Miami, FL',
      fullImageName: 'karl_summer_2021.jpeg',
      croppedImageName: 'karl_summer_2021_cropped.jpeg',
      biography: {
        description: "Karl is a charismatic and confident guy who is here to find his forever love! He loves to have a good time, and more specifically, make it a good time for everyone around him. He has had long-term relationships in the past but says he has had a hard time fully committing because his professional goals have outweighed his personal ones. Now, all that has changed, and Karl is ready to make finding his wife the priority. He is looking for someone with whom he has off-the-charts chemistry. She should be fun and spunky and also love those same qualities in him. Karl is the type of guy who will take you jet skiing on a date and then read you a poem he wrote himself; nothing is off the table with Karl. For Karl, life is an adventure that always needs a little extra spice, and if Katie is ready to turn up the heat, then they're bound to hit it off!",
        likes: [
          "Karl's favorite holiday is New Year's Day because he loves the feeling of a fresh start.",
          "Karl loves to play chess.",
          "Karl speaks both English and French.",
        ],
        allieDescription: "Got an intro video, jacked, travels around a lot for speaking, he made a little portrait of the two of them together, is a lil hype dude. “I’m really excited to get to know you and see if I can be that missing piece for you.” He made a lil animation of them together and he says that she loved it! He’s getting a lot of confessionals …. ROSE 11.",
      },
      weekEliminated: 3,
    },
    21: {
      id: 21,
      name: 'Kyle',
      age: 26,
      occupation: 'Technical Recruiter',
      hometown: 'Fort Lauderdale, FL',
      fullImageName: 'kyle_summer_2021.jpeg',
      croppedImageName: 'kyle_summer_2021_cropped.jpeg',
      biography: {
        description: "Kyle is not afraid to admit that he is looking for that romantic comedy kind of love that lasts forever. A hopeless romantic to his core, Kyle has only ever wanted to live a one-woman kind of life. He is ready to settle down and start a family, but he just needs to find the right woman to make that his reality. Kyle is looking for a woman who is intelligent, athletic, compassionate and always down to try new things. Like him, she should love to travel and always be up for an adventure. Kyle wants someone who will make him laugh and enjoy a life filled with passion and romance. Kyle is here for the real deal – he is looking for the princess to his Prince Charming, and fingers crossed, it's all fireworks when he meets the Bachelorette.",
        likes: [
          "Steve Irwin is Kyle's lifelong idol.",
          "Kyle's favorite flower is an orchid.",
          "Kyle hates mayo and mustard.",
          "Kyle would love to be able to fly so he never has to sit in traffic again.",
        ],
        allieDescription: "“I’m gonna be brief here.” Pulls briefs out of his pants. ROSE 14.",
      },
      weekEliminated: 3,
    },
    22: {
      id: 22,
      name: 'Landon',
      age: 25,
      occupation: 'Basketball Coach',
      hometown: 'Dallas, TX',
      fullImageName: 'landon_summer_2021.jpeg',
      croppedImageName: 'landon_summer_2021_cropped.jpeg',
      biography: {
        description: "Landon is a kind, handsome and outgoing guy who is ready to settle down. After a very successful collegiate basketball career, while working hard to receive his master's degree in global business, Landon is finally ready for marriage and children. According to Landon, his priorities in life have changed and he wants to find his soulmate more than anything. Landon is looking for his best friend and someone that he can be goofy with. He is a strong lover with a very big heart who says that his only major turn-off is someone who doesn't mix well with his family. As Landon gets older, he's realized there's a difference between having love for someone and being in love, and now, he's ready to leave it all on the court for the chance at something significant.",
        likes: [
          "Landon's nickname is Sunshine.",
          "Landon is a proud and loyal Belieber.",
          "Landon loves a good game of UNO! or Settlers of Catan.",
          "Christmas lights make Landon very happy.",
        ],
        allieDescription: "“The only reason I’m here is to put a smile on that beautiful face”",
      },
      weekEliminated: 1,
    },
    23: {
      id: 23,
      name: 'Marcus',
      age: 30,
      occupation: 'Real Estate Agent',
      hometown: 'Portland, OR',
      fullImageName: 'marcus_summer_2021.jpeg',
      croppedImageName: 'marcus_summer_2021_cropped.jpeg',
      biography: {
        description: "Marcus is a sweet guy with a calming presence about him. He has built a very successful professional life investing in properties, but now he is ready to shift his focus and invest in himself. Marcus is looking for someone who is smart, ambitious and tidy! He cannot be with someone who is happy sitting on their laurels all day long, and he dreams of finding a partner who brings excitement and adventure to his world. He loves to play board games – Monopoly being his all-time favorite – and finds great joy in taking in all that the Portland culinary scene has to offer. When describing himself as a lover, Marcus says he is \"amazing.\" There's no doubt that Marcus is ready for love and marriage, and we hope his confidence is enough to win Katie's heart!",
        likes: [
          "Marcus likes to have music playing at all times.",
          "Marcus fears a world where soap does not exist.",
          "Marcus would love to be Spiderman for a day even though he is terrified of spiders.",
          "Marcus loves the Los Angeles Lakers.",
        ],
        allieDescription: "Simple intro, very nervous, burgundy suit. “He’s cute, I like that.” - Katie",
      },
      weekEliminated: 1,
    },
    24: {
      id: 24,
      name: 'Marty',
      age: 25,
      occupation: 'Dancer',
      hometown: 'Reno, NV',
      fullImageName: 'marty_summer_2021.jpeg',
      croppedImageName: 'marty_summer_2021_cropped.jpeg',
      biography: {
        description: "Marty has big energy, big confidence and a lot of love to go around. Though Marty loves to have fun and show off his rockin' bod, underneath that boisterous exterior is a man with a sensitive soul who is serious about finding \"the one.\" He is looking for a woman who can joke around with him and will enthusiastically join him for a weekend of camping under the stars. He can't be with someone who spends more time staring at their phone screen than into his eyes, and good communication skills are an absolute must. Marty loves to express himself through physical touch and says that he \"loves to love on and be loved on.\" Will Katie be all about the love with Marty?",
        likes: [
          "Marty's self-declared tagline is \"Marty brings the party.\"",
          "Marty loves horseback riding.",
          "Marty explicitly prefers to watch movies indoors.",
          "The way into Marty's heart is through a good bottle of cologne.",
        ],
        allieDescription: "I’m super serious about being here. We got a quick cutaway of him showing her some different types of rocks.",
      },
      weekEliminated: 1,
    },
    25: {
      id: 25,
      name: 'Michael',
      age: 36,
      occupation: 'Business Owner',
      hometown: 'Akron, OH',
      fullImageName: 'michael_summer_2021.jpeg',
      croppedImageName: 'michael_summer_2021_cropped.jpeg',
      biography: {
        description: "Michael is a single dad whose world revolves around his amazing four-year-old son, James. When it comes to dating, the two of them are a packaged deal, and Michael wouldn't have it any other way. Michael lives a life that is incredibly fulfilling and says he is more than ready to find someone to share it with. His dream woman is compassionate, empathetic, resilient and not easily offended. A witty woman with a funny bone and a self-deprecating sense of humor is a huge turn-on for Michael, and he loves when a woman has strong convictions and stands up against ignorance. Michael says that life is short and that he's ready to find a woman who makes him a better person every day that they are together. He is serious about finding love on this journey, so, Michael, let the journey begin!",
        likes: [
          "Michael makes a mean plate of Dino Nuggies.",
          "Michael prefers the mountains over the beach any day of the week.",
          "Michael loves 90s R&B and rap music.",
        ],
        allieDescription: "Did a handshake not a hug but comes with a little gift. Coming off kinda “suave”. Did kind of a bit, but says time is precious. He went in for the cheek kiss. He also has a dog named Tommy (Katie has a cat named Tommy). He does also have a 4 year old son. He maybe has custody then if his son is staying with his parents? ROSE 3.",
      },
      weekEliminated: 10,
    },
    26: {
      id: 26,
      name: 'Mike',
      age: 31,
      occupation: 'Gym Owner',
      hometown: 'San Diego, CA',
      fullImageName: 'mike_summer_2021.jpeg',
      croppedImageName: 'mike_summer_2021_cropped.jpeg',
      biography: {
        description: "When people find out that this great-looking guy used to play professional baseball, they assume that he must be a \"player,\" but that could not be further from the truth. Mike grew up in a very loving family where the cornerstone of his upbringing was his faith. He has always been a one-woman type of guy, but due to his successful run in the MLB, dating was never really a priority in his life. Now, after retiring from baseball and opening up a gym for high school athletes, Mike is ready to focus on the missing piece in his life – finding love. Mike describes himself as easygoing, social, goofy and disciplined. He is looking for a woman who loves staying active, will make him laugh, and most importantly, has the same set of values as he does. Mike's family is extremely important to him so, ideally, he is looking to find someone whom his parents and three sisters absolutely adore. Mike loves his life and is 100% ready to share his life with the woman of his dreams. Is Katie that woman? Only time will tell.",
        likes: [
          "Mike does not like dessert.",
          "Mike occasionally describes himself as basic.",
          "Mike's goal is to visit 100 countries.",
        ],
        allieDescription: "Got an intro video -- baseball player and is a virgin waiting for marriage and would want that for them. “How you doin” - Mike. He looks like an old Tyler C. Sorta some southern charm. “I’m gonna need a cheatsheet cuz they’re all hot” - Katie. ROSE 12.",
      },
      weekEliminated: 7,
    },
    27: {
      id: 27,
      name: 'Quartney',
      age: 26,
      occupation: 'Nutrition Entrepreneur',
      hometown: 'Dallas, TX',
      fullImageName: 'quartney_summer_2021.jpeg',
      croppedImageName: 'quartney_summer_2021_cropped.jpeg',
      biography: {
        description: "When it comes to putting it all out there for love, Quartney is not afraid to make a spectacle. He is a compassionate, caring and honest man who is going to make romantic gestures that make his partner feel special, as well as provide security and consistent love at all times. He is looking for a woman who is outgoing, ambitious and like-minded when it comes to his religious beliefs, which are very important to him. He loves to daydream about having a family and says that the number one thing he is looking for is someone to be an amazing mother to his future children. Quartney's goal in life is to leave behind a legacy, and he needs a partner who will stand side by side with him and build an empire. Quartney is here to make an impact and is ready to fight for a chance at love, whatever it takes!",
        likes: [
          "Even though he's not great at it, Quartney loves to country line dance.",
          "Scuba diving is at the top of Quartney's bucket list.",
          "Quartney's idea of a perfect nightcap is a robust bonfire with s'mores, music and dancing.",
        ],
        allieDescription: "Does a lil poem with a lot of Qs and also sexual innuendos … he kinda pulled it off. She does the 2 air thrusts for him! “I feel like I made her laugh”. ROSE 10.",
      },
      weekEliminated: 5,
    },
    28: {
      id: 28,
      name: 'Thomas',
      age: 28,
      occupation: 'Real Estate Broker',
      hometown: 'Poway, CA',
      fullImageName: 'thomas_summer_2021.jpeg',
      croppedImageName: 'thomas_summer_2021_cropped.jpeg',
      biography: {
        description: "Thomas is a true believer that everything happens for a reason and says the timing to meet Katie could not be more perfect for him. Thomas knows exactly what he is looking for. For him, it's all about substance and true companionship. His dream woman is beautiful, athletic, mature and ready to be his partner in life. Ambition and thoughtfulness are two major turn-ons for Thomas, and he would love someone to stand by his side as he becomes more involved in his local community. She also must have a healthy appetite because Thomas's love language is food – he loves to show romance by bringing his loved ones their favorite dish, and one of his favorite ways to show someone his beloved city of San Diego is through his meticulously curated tour of the best burritos, burgers and brunch the city has to offer. Above all, Thomas is done looking for just a good time and is ready to find the one to last a lifetime. #Swoon.",
        likes: [
          "Thomas often wonders if The Rock really can eat everything he posts on Instagram for his cheat meal days.",
          "Thomas's favorite flower is a sunflower.",
          "Thomas is guilty of rocking the Ed Hardy trend back in high school.",
        ],
        allieDescription: "First out of a limo, he has a deep admiration for you stepping into this journey and having the … strength … ok just talking more … can’t wait to understand more about what makes her her … sounds so sincere and is very cute! Feeling good. She giggled aftewrards! 2 giggles. “I felt like a third grader trying to talk to a cute girl for the first time”. We get a scene of them talking. He is glistening (of sweat). Compliments her toast and makes her blush. “I can tell he’s a great guy and I’m impressed.” - Katie. ROSE 4.",
      },
      weekEliminated: 4,
    },
    29: {
      id: 29,
      name: 'Tre',
      age: 26,
      occupation: 'Software Engineer',
      hometown: 'Covington, GA',
      fullImageName: 'tre_summer_2021.jpeg',
      croppedImageName: 'tre_summer_2021_cropped.jpeg',
      biography: {
        description: "Tre is a handsome catch with a huge heart. He comes from a very close family and describes himself as outgoing, kindhearted and open-minded. Tre is looking for a strong, confident woman who is open to everything life has to offer. Never one to pass up an opportunity for a new experience, Tre is looking for someone who is not afraid to let passion and spontaneity dictate life's direction. Coming from a big loving family, Tre wants as many kids as possible (and that he and his wife can comfortably afford)! A willingness to be vulnerable is an absolute must for Tre, and he promises to give that vulnerability in return. At his core, Tre is eager to love and eager to be loyal to one woman. Who could ask for anything more?",
        likes: [
          "Tre's favorite activity is to meet his friends for their brunch and book club.",
          "Tre loves the harmonica.",
          "Tre is irrationally terrified of piranhas.",
        ],
        allieDescription: "Got an intro video, into rap music + classical music, loves his mom, looks like a teddy bear, “She is bad!!!” He jumps out of a bed of a truck in a ball pit -- he’s here to have a ball with you. You’re a pretty baller bachelorette. She says “good vibes from him”. He takes her to his ball pit of love. “Laying in a truck full of balls.” - Katie. “I’m having so much fun with Tre! He has all this energy about him … he’s easy to talk to.” - Katie. ROSE 2.",
      },
      weekEliminated: 6,
    },
    30: {
      id: 30,
      name: 'James',
      age: 30,
      occupation: 'Software Salesman',
      hometown: 'La Jolla, CA',
      fullImageName: 'james_summer_2021.jpeg',
      croppedImageName: 'james_summer_2021.jpeg',
      biography: {
        description: "James loves the pursuit of becoming the best version of himself. To say that he takes care of his physique is an understatement – the man is BUILT. However, there's more to him than his looks, and James has a lot of love to give. When he finds the right woman, he is ready to give himself to her wholeheartedly. James is looking for someone who is full of life and energy. He loves women who have goals of their own, and spontaneity along with a bit a mystery are his two biggest turn-ons. James works hard to make sure that the woman in his life knows she is appreciated and valued because for James, relationships are about actions, not words. We love a man of action!",
        likes: [
          "James has zero phobias.",
          "James loves to experiment in the kitchen and hates fast food.",
          "James's favorite board game is Monopoly.",
          "James loves to rock turtlenecks.",
        ],
        allieDescription: "Starts in a box - he wanted to be present with him she has to go inside to open him up … not sure how he gets inside? He looks shockingly normal. “I think he’s super hot.” “Something intrigues me and I think we were making eye contact and talking without talking …. I definitely have a little crush on him.” His hair is aggressively gelled. ROSE 8.",
      },
      weekEliminated: 6,
    },
  },
  allIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,],
  stillInWeek2Ids: [1, 2, 3, 6, 7, 8, 9, 10, 11, 13, 14, 15, 17, 18, 19, 20, 21, 25, 26, 27, 28, 29, 30,],
  eliminatedWeek1Ids: [4, 5, 12, 16, 22, 23, 24,],
};

const michelleContestantData = {
  byId: {
    1: {
      id: 1,
      name: 'Alec',
      age: 29,
      occupation: 'Engineer',
      hometown: 'North Charleston, SC',
      fullImageName: 'alec_fall_2021.jpeg',
      biography: {
        description: "Alec lives to make the most out of every day. After his first marriage ended, Alec learned a lot about the value of commitment and what it takes to truly make love last, so he is not here to waste anyone's time. He is dependable, emotionally intelligent and values integrity above all else. Alec is hoping to find a woman who appreciates how much his career means to him but will also make him want to be sentimental and bring out the fun side of him. He is true a believer in that old-school, head-over-heels unconditional type of love and is ready to find that with the beautiful Michelle.",
        likes: [
          "Alec loves to read GQ Magazine.",
          "Alec does not think bowling makes for a romantic night out.",
          "Alec's favorite thing to eat for breakfast is cottage cheese.",
        ],
        allieDescription: "pretty basic intro",
      },
      weekEliminated: 2,
    },
    2: {
      id: 2,
      name: 'Brandon J',
      age: 26,
      occupation: 'Traveling Nurse Recruiter',
      hometown: 'Portland, OR',
      fullImageName: 'brandon_j_fall_2021.jpeg',
      biography: {
        description: "Brandon J. may be young, but he knows exactly what he wants in life and is very serious about finding the one with whom to settle down. He is kind, confident and looking for a wife whose passionate personality can keep up with his. Brandon J. wants someone who has goals and the focus to achieve them. He doesn't want to be with someone who is still figuring herself out, and he hopes to find someone who can be his best friend and teammate for the rest of their lives. Speaking of teammates, not only is Brandon J. looking for love, but he's also looking for a little basketball! He is a self-proclaimed basketball fiend, and when given the opportunity, he doesn't plan on missing out on his shot with Michelle.",
        likes: [
          "Brandon J. once hopped on stage during a Lil Wayne and Drake concert.",
          "Brandon J. loves \"The Fresh Prince of Bel-Air.\"",
          "Brandon J. has no interest in ever going bungee jumping.",
        ],
        allieDescription: "a bed gets wheeled to her? showing her what it would be like to wake up next to him every morning, they have some 1/1 time and he lists good traits. ROSE 12",
      },
      weekEliminated: 9,
    },
    3: {
      id: 3,
      name: 'Brandon K',
      age: 29,
      occupation: 'Brand Manager',
      hometown: 'Austin, Tx',
      fullImageName: 'brandon_k_fall_2021.jpeg',
      biography: {
        description: "Brandon K. is a polished Southern gentleman who just hasn't found the right one yet. He is looking for love that is playful and feisty, and says that his dream woman will be able to make even the most mundane of moments special. For Brandon K., love should be simple but memorable. His ideal connection is effortless with an obvious chemistry but also fun and affectionate. He wants to make a real social impact within his community and hopes to find a partner who wants to make the world a better place alongside him. Brandon K. says he'll know he's found the one when being together makes him want the night to never end, so here's hoping to many sunrises together for him and Michelle.",
        likes: [
          "Brandon K. loves the Cha-Cha Slide.",
          "Brandon K. dreams of delivering someone an oversized check for a life-changing amount of money.",
          "Brandon K. always buys travel insurance.",
        ],
        allieDescription: "also biracial, also can play basketball, gets intro video",
      },
      weekEliminated: 1,
    },
    4: {
      id: 4,
      name: 'Bryan',
      age: 31,
      occupation: 'NFL Player',
      hometown: 'Chicago, IL',
      fullImageName: 'bryan_fall_2021.jpeg',
      biography: {
        description: "Bryan is not your stereotypical jock - he's a foodie, religious, family oriented, completely down to earth and all about finding himself a wife. He considers himself to be quite the romantic, and thanks to all of the romcoms his older sisters made him watch growing up, Bryan is all about making big moves to show his affection. He loves to travel and hopes to find a partner who will join him as he dines his way across the globe. Bryan is a big believer in fate and feels that his being here to meet Michelle is serendipitous and that it's a match truly meant to be!",
        likes: [
          "Bryan loves philosophy and would love to ask Socrates a few questions.",
          "Bryan has been to every continent except Antarctica.",
          "Bryan thinks orchids are majestic.",
        ],
        allieDescription: "dips him with some dancing",
      },
      weekEliminated: 1,
    },
    5: {
      id: 5,
      name: 'Casey',
      age: 36,
      occupation: 'Advertising Creative Director',
      hometown: 'Miami Beach, FL',
      fullImageName: 'casey_fall_2021.jpeg',
      biography: {
        description: "Casey is at the point in his life where he wants something serious and long-lasting. Though he loves life in Miami, he says he has outgrown the local dating scene where women care more about their Instagram stories than making meaningful connections. He is looking for a woman who is ready to be a mom because Casey is ready to start a family and give his mom all of the grandbabies she so badly craves! Casey also loves his job raising money for philanthropic ventures and hopes to find a wife who gets as much satisfaction in helping others as he does. Casey is excited to meet Michelle and focus on finding love without all of the outside world's distractions. He is all about the love and we can't wait to see what this journey has in store for him and the Bachelorette.",
        likes: [
          "Casey says he is secretly a hippie.",
          "Casey likes camping, but he hates sleeping in tents.",
          "Casey takes his eggs scrambled with salsa.",
        ],
        allieDescription: "kaitlyn BREAKS DOWN laughing when she calls him distracting in their game of hot hands",
      },
      weekEliminated: 5,
    },
    6: {
      id: 6,
      name: 'Chris G',
      age: 28,
      occupation: 'Motivational Speaker',
      hometown: 'Halifax, Nova Scotia, Canada',
      fullImageName: 'chris_g_fall_2021.jpeg',
      biography: {
        description: "For Chris G., commitment is the name of the game. As kindhearted as you would expect any Canadian man to be, Chris G. says he is looking for a genuine connection and for someone who is all in on him the way he is on her. His ideal woman is active, vulnerable and a good person on the inside. Chris is looking for his best friend, someone who he can bring home to his family, and someone that he just absolutely adores. When he says he's looking for love, he is looking for the right soul and someone his energy bonds to. Will there be that instant spark when Chris meets Michelle?",
        likes: [
          "Chris G. loves escape rooms.",
          "Chris G. enjoys performing spoken word poetry.",
          "Chris G. has two cats – one named Cat and one named Small Cat.",
        ],
        allieDescription: "motivational speaker from nova scotia, gets intro video, \"he's cute\" - daniel, maggie's bf, extremely excited and also nervous, \"i love him\" says kaitlyn and tayshia when bursting into his room .... he sounds like he is giving a speech at her gduring their intro",
      },
      weekEliminated: 4,
    },
    7: {
      id: 7,
      name: 'Chris S',
      age: 28,
      occupation: 'Commodities Broker',
      hometown: 'West Hollywood, CA',
      fullImageName: 'chris_s_fall_2021.jpeg',
      biography: {
        description: "This Southern gentleman-turned-California beach boy is ready to find love! His friends describe him as goofy and easygoing, but when it comes to relationships, he isn't here to joke around. When Chris S. falls, he falls hard. He is looking for an adventurous woman who is confident, kind and selfless. He does not have a specific type and prides himself in falling in love with someone on the inside rather than the outside. In five years, Chris S. hopes to be married with kids and making a positive impact on the world. He is a firm believer that chivalry isn't dead, and when the time comes, he is ready to sweep Michelle off her feet!",
        likes: [
          "Chris S. gives a mean Blue Steel face.",
          "Chris S. loves to travel.",
          "Burnt pizza makes Chris S. mad.",
        ],
        allieDescription: "from LA, california cajun man, southern gentleman, gets his eyebrows waxed, super goofy, the goofy will come out, has some footage with floaties on, mama's boy, family-oriented, point guard, likes golf and adventures and helping other people (gets an intro video) // derssed as a schoolboy and showed up in a school bus lol ... tries to make a D joke... she seems kind of over it lol but she calls him \"smooth\". ROSE 13",
      },
      weekEliminated: 5,
    },
    8: {
      id: 8,
      name: 'Clayton',
      age: 28,
      occupation: 'Medical Sales Rep',
      hometown: 'Columbia, MO',
      fullImageName: 'clayton_fall_2021.jpeg',
      biography: {
        description: "Clayton is a Missouri thoroughbred who has it all – good looks, a great job and a wonderful family. The only thing missing is the perfect woman with whom to share his life. His dream woman is funny, independent, intelligent and athletic enough to join him at the gym for a workout. He wants to find something truly special and is willing to put in the hard work to find love that will last forever. Clayton is so excited to meet Michelle and loves that she has a passion for her career. His mom is a teacher just like Michelle, and he finds it impressive that she's made this journey work without having to take time away from her students. Clayton truly believes that Michelle could be the perfect match for him. Will they hit it off?",
        likes: [
          "Clayton had a mohawk in college.",
          "Clayton's rapping alter ego is named Clay-Doh.",
          "Clayton would love to own multiple gyms.",
        ],
        allieDescription: "next bachelor lol, has 2 younger brothers, gets intro video; meeting: he used to be a student, he gives her a yardstick to spank him with ... lol ? \"i was hoping she would be nervous but she was not\" \"wow she is still so not nervous at all\". ROSE 7",
      },
      weekEliminated: 6,
    },
    9: {
      id: 9,
      name: 'Daniel',
      age: 26,
      occupation: 'Firefighter',
      hometown: 'Austin, TX',
      fullImageName: 'daniel_fall_2021.jpeg',
      biography: {
        description: "Daniel brings the heat in all aspects of his life! He's living out his childhood dream of being a firefighter and spends his free time playing sports, swimming, hiking and biking. His favorite thing in the world is being an uncle to his four nieces, and now he's ready to settle down and have a family of his own. Daniel's perfect woman is adventurous, fun, intelligent and, above all, kind. He values good energy, great conversation and loves a woman who constantly keeps him on his toes. Daniel loves that Michelle seems to have that same competitive spirit that he does, and with everything that he knows about her so far, he's optimistic he won't be going home a single man.",
        likes: [
          "Daniel really wants to know if aliens exist.",
          "Daniel is a Connect Four champ.",
          "Daniel loves Christmas music.",
        ],
        allieDescription: "walks in on a tiny little firetruck, calls her a smokeshow. ROSE 11",
      },
      weekEliminated: 2,
    },
    10: {
      id: 10,
      name: 'Edward',
      age: 27,
      occupation: 'Wellness Coach',
      hometown: 'Los Angeles, CA',
      fullImageName: 'edward_fall_2021.jpeg',
      biography: {
        description: "Edward is outgoing, optimistic and a self-proclaimed amazing wingman, but now it's time to focus on finding happiness for himself! He spends most of his weekends exploring new places and needs a woman who isn't afraid of a little adventure. She should be emotionally articulate and have a healthy appetite for learning and personal growth. She also MUST love dogs because Edward's pup, Willow, is always by his side. Edward loves to express himself through words of affirmation and physical touch, so if Michelle is looking for someone who isn't going to hold back, she won't have to look any further!",
        likes: [
          "Edward dreams of visiting Disney World one day.",
          "Edward enjoys listening to John Mayer's music, especially on road trips.",
          "Edward is excited by a good obstacle course.",
        ],
        allieDescription: "gives her a little sound bath",
      },
      weekEliminated: 1,
    },
    11: {
      id: 11,
      name: 'Garrett',
      age: 33,
      occupation: 'Tech CEO',
      hometown: 'Salt Lake City, UT',
      fullImageName: 'garrett_fall_2021.jpeg',
      biography: {
        description: "Garrett is not afraid of a little risk. He dropped out of college to start what has become an incredibly successful tech company and spends his free time traveling the world, skydiving, paragliding and speed flying. He is always pushing himself to grow as a person and needs a woman who wants the same for herself. She should also be open-minded, authentic and not afraid of a little salsa dancing. Garrett is hoping for a family one day, and if a woman does not want kids, then he is not interested. Garrett is serious about finding the one and is ready to have a little fun finding her!",
        likes: [
          "Garrett loves plants and calls himself a \"plant daddy.\"",
          "Growing up, Garrett had a bowl cut.",
          "Garrett LOVES pickles!",
        ],
        allieDescription: "broke his foot right before showing up, quick intro",
      },
      weekEliminated: 1,
    },
    12: {
      id: 12,
      name: 'Jack',
      age: 30,
      occupation: 'Former Army Officer',
      hometown: 'Philadelphia, PA',
      fullImageName: 'jack_fall_2021.jpeg',
      biography: {
        description: "Jack is a sweet and compassionate man who is ready to start the next chapter of his life. He is incredibly loyal, especially when it comes to his family, and he describes himself as genuine and driven. Jack is looking for a woman who is emotionally mature, compassionate, silly and up for joining him on a challenging hike. While he tends to be on the shyer side, he is attracted to women who are outgoing, strong and not afraid to push him outside of his comfort zone. When Jack is excited about someone, he gets very motivated to do things that make her feel loved and appreciated. Luckily, Jack is very excited to meet Michelle, so we can't wait to see what he has planned for her!",
        likes: [
          "Jack will throw up at the sight of an onion.",
          "Jack is afraid of dancing.",
          "Jack loves to play kickball.",
        ],
        allieDescription: "\"michelle .... so glad i finally get to meet you\" \"he looks like his dad was hitler - daniel maggie's bf\" \"like a dog i'm loyal and attentive and playful and ready to play tug of war for your heart\"",
      },
      weekEliminated: 1,
    },
    13: {
      id: 13,
      name: 'Jamie',
      age: 32,
      occupation: 'Biotech CEO',
      hometown: 'San Diego, CA',
      fullImageName: 'jamie_fall_2021.jpeg',
      biography: {
        description: "Jamie is a catch. He is a world traveler with a taste for all things and is the type of guy who will show up at the airport and let the ticketing agent decide his destination. Yes, he actually did that and had an amazing week in Uruguay! He has a great job, is extremely smart and has a healthy idea of exactly what he needs in a wife. Jamie is looking for someone to be his partner in this never-ending adventure called life. She should be independent, driven and always ready to head out for dinner at the newest local hot spot. For Jamie, life is about valuing cultural experiences and making memories, so hopefully, Michelle is up for the ride!",
        likes: [
          "Jamie once traveled to Ecuador just to try their chocolate.",
          "Jamie has never watched an episode of reality TV in his life.",
          "Jamie's favorite game is chess.",
        ],
        allieDescription: "\"seems really weird\" - everyone. ROSE 2",
      },
      weekEliminated: 3,
    },
    14: {
      id: 14,
      name: 'Joe',
      age: 28,
      occupation: 'Real Estate Developer',
      hometown: 'Minneapolis, MN',
      fullImageName: 'joe_fall_2021.jpeg',
      biography: {
        description: "Joe is a Minnesota-born-and-raised city boy with a heart of gold. He played basketball at the University of Minnesota, and while he says that basketball was his first true love, he's now ready to build a life outside of sports and find a wife. Joe, who describes himself as introverted, is looking for a woman who is smart, caring, articulate and able to make him laugh. He wants someone with aligning values and hopes they are ready for a family because Joe hopes to have at least three or four kids! Overall, Joe wants to find a \"real one\" with whom he can build something solid that will stand the test of time.",
        likes: [
          "Joe is scared of spiders.",
          "Joe loves snacks.",
          "Joe wants to travel to the Galapagos Islands one day.",
        ],
        allieDescription: "minneapolis redeveloping houses, looking for a queen to build with, graduated the same year, also biracial, seems very precious, gets intro video, SHE'S SLID INTO HIS DMS BEFORE????? she knows his last name, she ghosted him after DMing about basketball for a bit .... but so she does like him ... he says he wasn't in a place to be able to open up with her because of george floyd ... she says she's a very understanding person and that he just should have communicated that, says her concern is how do we know he won't shut down again, he does want to be able to open up and he did truly come for her // she likes that he is going to therapy and opened up about tht but she does also need to protect her heart // we'll see where it goes",
      },
      weekEliminated: 8,
    },
    15: {
      id: 15,
      name: 'JoMarri',
      age: 26,
      occupation: 'Personal Trainer',
      hometown: 'Fresno, CA',
      fullImageName: 'jomarri_fall_2021.jpeg',
      biography: {
        description: "JoMarri says he is a sucker for love! He falls hard and fast, and says that a smile tells you everything you need to know about a person. He is a driven gentle giant, whose dream is to own a gym and have a woman by his side to grow old and with whom to enjoy life. His perfect woman is simple, athletic and goofy. Life is never boring when JoMarri is around and he plans to make the most out of every second he has with Michelle!",
        likes: [
          "JoMarri eats an entire watermelon every morning.",
          "The light of JoMarri's life is his puppy, Buddy.",
          "JoMarri would love to be a tour guide in Antarctica.",
        ],
      },
      weekEliminated: 1,
    },
    16: {
      id: 16,
      name: 'Leroy',
      age: 27,
      occupation: 'Biomedical Ph.D Student',
      hometown: 'Dallas, TX',
      fullImageName: 'leroy_fall_2021.jpeg',
      biography: {
        description: "Leroy is a true academic who has a flair for fashion and a penchant for fun. He was born in Italy to parents who emigrated from Ghana and then moved with his family to the U.S. in pursuit of the American dream. He has worked incredibly hard in his educational pursuits, and now as that chapter of his life comes to a close, Leroy wants to find someone with whom he can share his beautiful world. He is incredibly proud of his strong Ghanaian roots and wants someone who will always support him being his most authentic self. Leroy loves brunch, but nothing makes him happier than holding hands with the woman he loves. He says that when he dates, he dates with intention. His intention here? Hopefully leave hand in hand with the love of his life!",
        likes: [
          "Leroy loves go-karting.",
          "Leroy does not like to gamble.",
          "Leroy is a big fan of breakfast in bed.",
        ],
        allieDescription: "from dallas so zach likes him, \"he is sooooo cute\" - everyone, bio phd. ROSE 3",
      },
      weekEliminated: 5,
    },
    17: {
      id: 17,
      name: 'LT',
      age: 38,
      occupation: 'Yogu Guru',
      hometown: 'Bellvue, WA',
      fullImageName: 'lt_fall_2021.jpeg',
      biography: {
        description: "LT has never had to worry about much in his life. He is very much fulfilled professionally and in his family life – the only thing missing? Someone to share all his happiness with! LT says he doesn't have any real deal breakers and is more focused on finding a genuine connection in a partner. He wants to find someone who will appreciate his quirky sense of humor, join him on long walks and truly bring out the best in him. LT is a romantic at heart who, at his core, just wants to find someone with whom to watch old movies and exchange love letters.",
        likes: [
          "LT loves his two pet Savannah cats.",
          "LT lives for a good spin class.",
          "LT has one tattoo – his grandfather's initials over his heart.",
        ],
        allieDescription: "shows up not wearing pants, otherwise no edit",
      },
      weekEliminated: 3,
    },
    18: {
      id: 18,
      name: 'Martin',
      age: 29,
      occupation: 'Personal Trainer',
      hometown: 'Miami, FL',
      fullImageName: 'martin_fall_2021.jpeg',
      biography: {
        description: "At any given time, Martin can be found working out on a beach in Miami. A passionate and disciplined man, he spends his days perfecting his physique and spending time with his loud, fun family who emigrated from Argentina when he was 10 years old. Martin says he gets his passion from his parents and credits them for teaching him how a husband should treat his wife. Martin is looking for a mature, health-conscious woman who wants to be loved. He is ready to make a big commitment and plans to be fearless in his pursuit of love.",
        likes: [
          "Martin can do a backflip.",
          "Martin would love to get lost in a foreign country.",
          "Martin can cook basics VERY WELL.",
        ],
        allieDescription: "does a backflip, i feel like i'm already crazy about you, bleached hair. ROSE 4",
      },
      weekEliminated: 6,
    },
    19: {
      id: 19,
      name: 'Mollique',
      age: 36,
      occupation: 'Academic Administrator',
      hometown: 'San Diego, CA',
      fullImageName: 'mollique_fall_2021.jpeg',
      biography: {
        description: "Mollique is a hopeless romantic who is SO ready to find his person. Raised by women all around him, he describes himself as a domesticated renaissance man who believes wholeheartedly in the power of love and family. He is looking for a woman of quality – someone with morals who is selfless, patient and well-mannered. Mollique has an 8-year-old son who is the best gift of love that he has ever received. From his son, he has learned what true unconditional love is and is ready to share that with the love of his life.",
        likes: [
          "Mollique does not like frogs.",
          "Mollique has his motorcycle license.",
          "Mollique's favorite author is Nicholas Sparks.",
        ],
        allieDescription: "pretty basic intro. ROSE 10",
      },
      weekEliminated: 3,
    },
    20: {
      id: 20,
      name: 'Nayte',
      age: 27,
      occupation: 'Sales Executive',
      hometown: 'Austin, TX',
      fullImageName: 'nayte_fall_2021.jpeg',
      biography: {
        description: "When you walk into a room, it's hard not to notice Nayte. He has a smile that sparkles like the stars, he is always the life of the party; and to boot, he is a 6-foot, eight-inch Adonis of a man. Nayte doesn't have trouble meeting women, but as he edges closer to 30, he's more focused on finding a long-lasting relationship that will go the distance. His dream woman is outgoing, spontaneous and has enough swagger of her own to keep up with him. He's looking for a connection filled with heat and for someone who will be just as passionate about him as he is about her. Nayte is looking for a teammate for life and is confident that Michelle may just be the one he's been waiting for.",
        likes: [
          "Nayte dreams of visiting the Taj Mahal.",
          "Nayte can't dance to save his life.",
          "Nayte likes Edible Arrangements.",
        ],
        allieDescription: "from canada and lives in texas, has a cute dog, likes road trips, gets intro video, his mom is a teacher, looking for someone \"not superficial\" which he feels confident about because michelle is a teacher; first impression: his full name is a mouthful, simple intro, doesn't want to take up too much time \"it's better nayte than ever\" and michelle gets her first chance to force a laugh; \"that was a good start ****** i like nayte\" - michelle \"i have like pidgeons in my stomach\" // he's the first person she pulls after the ryan situation, getting some good screen time of their 1/1 time ... aw he's never seen his parents kiss, \"looks like black justin bieber\", gets first impression rose, first kiss of the night!!!!, \"sparks butterflies and everything you'd want to feel when you kiss someone\" - michelle. ROSE 1 - First Impression Rose!",
      },
    },
    21: {
      id: 21,
      name: 'Olumide',
      age: 27,
      occupation: 'IT Analyst',
      hometown: 'Newark, NJ',
      fullImageName: 'olumide_fall_2021.jpeg',
      biography: {
        description: "A former D1 hooper at Rutgers University, Olumide knows how to compete at a high level and is coming here with one thing on his mind – Michelle! He thinks she is just absolutely beautiful, and as a strong believer in fate, he says that this journey is destined to be. He is not only confident but is also caring, attentive and more than willing to go above and beyond for the woman he loves. Olumide is ready to find his wife and he is coming in HOT!",
        likes: [
          "Olumide enjoys the theater.",
          "Olumide's favorite flower is a cherry blossom.",
          "Olumide hates tofu.",
        ],
        allieDescription: "it analyst, hoping i can be your #1 pick, pretty simple",
      },
      weekEliminated: 6,
    },
    22: {
      id: 22,
      name: 'Pardeep',
      age: 30,
      occupation: 'Neuroscientist',
      hometown: 'Brooklyn, NY',
      fullImageName: 'pardeep_fall_2021.jpeg',
      biography: {
        description: "PPardeep is the perfect combo of looks and intelligence! For work, he studies the brain to find cures for diseases like Parkinson's and Alzheimer's; and though he spends his days buttoned up in a lab, when he has free time, he's anything but serious. This self-proclaimed nerd who loves Comic-Con, video games and reading also has an undeniable Brooklyn edge about him. Pardeep is truly a unique catch who wants to find someone family oriented, affectionate, and who cares about making the world a better place just as much as he does. And if she can occasionally nerd out with him, GREAT!",
        likes: [
          "Pardeep loves to sleep in on Saturdays.",
          "Pardeep loves New Year's because he loves new beginnings.",
          "Pardeep doesn't enjoy sitting on park benches.",
        ],
        allieDescription: "[none]",
      },
      weekEliminated: 2,
    },
    23: {
      id: 23,
      name: 'Peter',
      age: 26,
      occupation: 'Pizzapreneur',
      hometown: 'Port St. Joe, FL',
      fullImageName: 'peter_fall_2021.jpeg',
      biography: {
        description: "Peter is a hopeless romantic who commits for the long haul. He is a man of passion, and when he decided to professionally pursue his love of food, he moved all the way to Italy to study the cuisine firsthand. Now that he's fulfilled his childhood dream of opening up his own – very successful – pizzeria, he's ready to find his person and build his culinary empire. Peter's dream woman is honest, committed, spontaneous and not interested in talking politics on a date. She's ready to support her man but also has ambitions of her own. She also must love food because, as ready as Peter is to find a wife, part of his heart will always belong to his first love, food.",
        likes: [
          "Peter loves deep house music.",
          "Peter doesn't like libraries.",
          'Peter loves red wine and calls himself a "vino head."',
        ],
        allieDescription: "there's a pizza guy which is interesting ok, he feeds her a cannoli during some 1/1 time, ",
      },
      weekEliminated: 3,
    },
    24: {
      id: 24,
      name: 'PJ',
      age: 30,
      occupation: 'Firefighter',
      hometown: 'Houston, TX',
      fullImageName: 'pj_fall_2021.jpeg',
      biography: {
        description: "PJ is a natural caretaker. He loves his job as a firefighter and is someone who wants to make sure that the people he loves are always happy, protected and healthy. He's very ready to get married and have a family of his own, but he just wants to make sure that when he does settle down, it's with the right woman. Nothing lights his fire more than a loyal woman with drive and beauty. He loves when he and his partner can enjoy working out together and hopes to find someone who has a thirst for travel just as much as he does. PJ says that he is the last single man standing out of all his friends and he's ready to change that for good!",
        likes: [
          "PJ loves to pass time by working on a Rubik's Cube.",
          "PJ does not enjoy the opera.",
          "PJ wants to visit Brazil and attend Carnival.",
        ],
        allieDescription: "now there's a big firetruck, silly producers, PJ is also a firefighter, ok but they maybe don't have any chemistry",
      },
      weekEliminated: 2,
    },
    25: {
      id: 25,
      name: 'Rick',
      age: 32,
      occupation: 'Medical Sales Rep',
      hometown: 'Los Angeles, CA',
      fullImageName: 'rick_fall_2021.jpeg',
      biography: {
        description: "Rick is a self-proclaimed hopeless romantic who has been thinking about his future wife for as long as he can remember. He finds romanticism in the small things in life and is the type of guy who will send you flowers at work for no reason but to say, \"I love you.\" He wears his heart on his sleeve because when he is into someone, he wants them to know and doesn't want to play games. He does love a good game night though because he says he can tell a lot about someone's personality when competition is involved. At the end of the day, Rick wants to find his heart's true counterpart and someone who can make the world a better place alongside him. #Swoon.",
        likes: [
          "Rick loves his dog, Nana.",
          'The movie "Hitch" taught Rick everything he needs to know about life.',
          "Rick is not a fan of double dates.",
        ],
        allieDescription: 'let me tell you about the special tonight" lol .... calls him the main course, very rehearsed, omg he stays in the box the rest of the night "he has crazy eyes" - daniel maggie\'s bf .... "i want to kiss her and get out of the table" "this is probably the most odd first impression" ... uhh she is feeding him a strawberry / he is not weirdly short',
      },
      weekEliminated: 6,
    },
    26: {
      id: 26,
      name: 'Rodney',
      age: 29,
      occupation: 'Sales Rep',
      hometown: 'Rancho Cucamonga, CA',
      fullImageName: 'rodney_fall_2021.jpeg',
      biography: {
        description: "Rodney is a glass-half-full kind of guy. After his dreams of playing in the NFL were shattered by a career-ending injury, Rodney picked himself up and redirected his ambition. He has a great job he enjoys, but he says life would be better if there was someone he loved waiting for him at home every night. Rodney is looking for someone who is honest, active and ideally a former student athlete like himself. He wants to find a deep mental connection with someone but also wants a woman who he can laugh with every day. Rodney knows exactly what he wants and he is looking for someone who has that clear vision too – he's done wasting time and is ready to be wifed-up!",
        likes: [
          "Rodney is afraid of opossums.",
          "Rodney is terrified of being catfished.",
          "Rodney is very into intermittent fasting but only on weekdays.",
        ],
        allieDescription: "walks in in an apple costume, trying to be the apple of her eye, uhhh it's honestly not that flattering imo but he does seem genuine, kind of giving me aaron vibes // he names a green apple when asked what type he is which really did not impress michelle",
      },
      weekEliminated: 7,
    },
    27: {
      id: 27,
      name: 'Romeo',
      age: 32,
      occupation: 'Mathematician',
      hometown: 'New York City, NY',
      fullImageName: 'romeo_fall_2021.jpeg',
      biography: {
        description: "For Romeo, math has always come easy. The one equation he has yet to solve though is Romeo + X = True Love, and this Harvard graduate is here to find that missing variable! He is looking for an adventurous woman who is smart, active and motivated. Nothing turns him on more than someone with a curious mind. In a dream world, Romeo would love to find a woman who will spend the day exploring New York's many museums and, once the sun goes down, will tear up the dance floor with him at salsa night. Like his famous namesake, Romeo is a passionate man and says that he falls hard and fast. Will Michelle be the Juliet he has been searching for?",
        likes: [
          "Romeo is very interested in clean technology.",
          "Romeo wants to go to Burning Man one day.",
          "Romeo studied Japanese for three years in college.",
        ],
        allieDescription: '"OW OW" - yells michelle, "very intriguing, i have no idea what youre saying" "i hope youll be my juliet" "i love it, it was really nice to meet you" "romeo romeo" "hahaha"',
      },
      weekEliminated: 4,
    },
    28: {
      id: 28,
      name: 'Ryan',
      age: 30,
      occupation: 'Environmental Consultant',
      hometown: 'San Jose, CA',
      fullImageName: 'ryan_fall_2021.jpeg',
      biography: {
        description: "Ryan is compassionate, competitive and motivated. He dreams about bringing the perfect woman home to his family raisin ranch to enjoy the picturesque sunset of central California, and he is hopeful that Michelle is the one for him. Ryan is a planner who creates lists for all endeavors he pursues, and that includes his love life. He is looking for a woman who checks off all his boxes: intelligent, down to earth, big heart, healthy and a girl-next-door type. Michelle may check all of his boxes, but will he check all of hers? Only time will tell …",
        likes: [
          "Ryan is obsessed with ice cream trucks.",
          "Ryan LOVES spreadsheets.",
          "Ryan is enthusiastic about cuddling.",
        ],
        allieDescription: "has research on how to get more screen time and stuff, i ordered a double scoop of love after coming in an ice cream truck, pretty sweet entrance // has multiple ice cream flavors and she says that gets brownie points, he's a special olympics coach full-time??? // she walks him out after reviewing his problematic shit",
      },
      weekEliminated: 1,
    },
    29: {
      id: 29,
      name: 'Spencer',
      age: 25,
      occupation: 'Financial Crimes Analyst',
      hometown: 'Cleveland, OH',
      fullImageName: 'spencer_fall_2021.jpeg',
      biography: {
        description: "Spencer is an amazing catch – he is athletic, has a great job and is incredibly smart. According to the most important person in his life, he is also the world's best dad. Spencer's son is the inspiration that makes him want to be a better man every day; and while he hasn't dated much since his birth, he's here now to find a wife and hopefully expand his already wonderful family. For Spencer, physical attraction gets his attention, but personality is what keeps it. He needs a woman with an open mind who will be vulnerable with him and challenge him to be the best version of himself. What matters most to Spencer is what is in someone's heart, and when he meets Michelle, he certainly will not be disappointed.",
        likes: [
          "Spencer loves to spend time in the hot tub.",
          "Spencer hates movies that require all of his attention.",
          "Spencer loves snowball fights.",
        ],
        allieDescription: "shows up with 2 basketballs",
      },
      weekEliminated: 3,
    },
    30: {
      id: 30,
      name: 'Will',
      age: 28,
      occupation: 'Academic Interventionist',
      hometown: 'Grand Rapids, MI',
      fullImageName: 'will_fall_2021.jpeg',
      biography: {
        description: "Will describes himself as classy, swaggy and sharp. He is more than ready to make a substantial connection with someone and is also very tired of being the only single one in his group of friends. Will is looking for a thrill-seeking woman who is always up for a spur-of-the-moment adventure. His dream woman is loyal, ambitious, accountable, and ready to push him every day to be the best version of himself because he's going to do the same for her. Will says he is a true romantic that will do anything to make his significant other know how loved she is, and now, he's ready to make Michelle the happiest she's ever been.",
        likes: [
          "Will hates getting money as a gift.",
          "Will is a Leo and is very into horoscopes.",
          "Will wants to climb Mount Everest one day.",
        ],
        allieDescription: "pretty basic intro, not wearing socks, but maybe nobody is wearing socks??",
      },
      weekEliminated: 4,
    }
  },
  allIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,],
  stillInWeek2Ids: [1, 2, 5, 6, 7, 8, 9, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 29, 30,],
  eliminatedWeek1Ids: [3, 4, 10, 11, 12, 15, 28,],
};

const claytonContestantData = {
  byId: {
    0: {
      id: 0,
      name: 'Cassidy',
      age: "28",
      occupation: "Executive Assistant",
      hometown: "Los Angeles, California",
      fullImageName: "cassidy_clayton_2022.jpeg",
      croppedImageName: "cassidy_clayton_2022_cropped.jpeg",
      biography: {
        description: "Cassidy is a bold, blunt woman who isn't afraid to say how she feels or do what she wants. She spent her twenties experiencing life to the fullest and, through her past experiences, has figured out what she's truly looking for. She is driven, confident and looking for a partner who can keep up with her fast-paced lifestyle. She wants someone attracted to her independence, a teammate who wants to create a home, and someone who can match her adventurous nature. Fueled by hope, Cassidy loves to take chances and will try anything once. Will Cassidy and Clayton have the adventure of a lifetime?",
        likes: [
          "Cassidy doesn't like clubs because she hates waiting in lines.",
          "Cassidy has a matching tattoo with her mom.",
          "Cassidy is a pro at driving stick shift.",
        ],
      },
      weekEliminated: 3,
    },
    1: {
      id: 1,
      name: 'Claire',
      age: "28",
      occupation: "Spray Tanner",
      hometown: "Virginia Beach, Virginia",
      fullImageName: "claire_clayton_2022.jpeg",
      croppedImageName: "claire_clayton_2022_cropped.jpeg",
      biography: {
        description: "Claire is a charming, outgoing and unapologetic woman with a big personality. She grew up quickly as a single mother but credits her son as her greatest achievement. Claire is ready to take a chance on finding love again without the distractions of life and work. She is looking for her soul mate – someone who is supportive and doesn't hold her back. At home, you can find Claire at girls' night singing along to country music, trying out a new restaurant or enjoying a bonfire. She's also hoping for more babies in the future!",
        likes: [
          "People who don't wear deodorant make Claire angry.",
          "Claire loves ranch dressing with her buffalo wings.",
          "\"How to Lose a Guy in 10 Days\" is one of Claire's favorite movies."
        ],
        allieDescription: "video description: nan|||entrance description: spray tanner lmao, kind of unpolished, in a way that was maybe endearing, \"excited to do this journey\"|||post-entrance description: lil football fan, they do cornhole at a tailgate, \"why are you stressing me out\" hm not handling him being stolen very well, angrily eats a wing, says he's too nice for her, says she hates him to a bunch of people lol,|||rose #: X|||",
      },
      weekEliminated: 1,
    },
    2: {
      id: 2,
      name: "Daria",
      age: "24",
      occupation: "Law Student",
      hometown: "Baldwin, New York",
      fullImageName: "daria_clayton_2022.jpeg",
      croppedImageName: "daria_clayton_2022_cropped.jpeg",
      biography: {
        description: "Move over Elle Woods because Daria is coming for the throne! Daria is a woman of many accomplishments including admission to seven Ivy League schools and a Harvard degree. Additionally, she is now in her third year at Yale Law School. She's a studious woman, but don't get the wrong idea because Daria also loves to be social and have fun. She's looking for someone who won't be intimidated by her accolades and loves when a man can make her laugh so hard that her stomach hurts. Speaking of her stomach, Daria says she'll know it's love when she can feel the butterflies in her tummy. Daria is very active, loves to dance and dreams of being an advocate for woman's rights in the future. She loves falling in love and we can't wait to see her love story with Clayton unfold.",
        likes: [
          "Daria idolizes Amal Clooney because she is truly an ally for women.",
          "Daria hates sushi.",
          "Daria wants to write a children's book one day. "
        ],
        allieDescription: "video description: in last year of law school,, life has been very career-oriented, does want to start a family, he looks like a provider/protector, hoping for good chemistry|||entrance description: her last name is rose, shorter cut, has a rose necklace, okay|||post-entrance description: nan|||rose #: X|||"
      },
      weekEliminated: 1,
    },
    3: {
      id: 3,
      name: "Eliza",
      age: "25",
      occupation: "Marketing Manager",
      hometown: "Berlin, Germany",
      fullImageName: "eliza_clayton_2022.jpeg",
      croppedImageName: "eliza_clayton_2022_cropped.jpeg",
      biography: {
        description: "Gorgeous, educated and well-traveled, Eliza has it all. This intelligent beauty was raised by two loving parents and spent most of her childhood traveling to and from Berlin for her father's job. Eliza was a late bloomer when it came to dating and spent her adult years prioritizing work over love, but this soft-spoken, educated woman is finally ready to find her husband. Eliza's parents have been married for 25 years and she looks up to them as role models for love. Eliza is looking for someone cultured, worldly and ready to be her partner. Here's to hoping for many adventures for Eliza and Clayton!",
        likes: [
          "Eliza loves food festivals.",
          "Eliza loves going to movies by herself.",
          "Eliza and her family visit Switzerland often for vacation."
        ],
        allieDescription: "video description: nan|||entrance description: maybe they eat a sausage, quick tu|||post-entrance description: they kiss, she knows German|||rose #: 4|||"
      },
      weekEliminated: 7,
    },
    4: {
      id: 4,
      name: "Elizabeth",
      age: "32",
      occupation: "Real Estate Advisor",
      hometown: "Highlands Ranch, Colorado",
      fullImageName: "elizabeth_clayton_2022.jpeg",
      croppedImageName: "elizabeth_clayton_2022_cropped.jpeg",
      biography: {
        description: "For Elizabeth, life is about experiences and living life to the fullest, so she needs a man who won't dull her light in any way. Elizabeth loves wine tasting, travel and self-admittedly has a taste for the finer things in life, so she wants a man who will keep life fun and be boujee by her side. She's very invested in her career and wants a man who appreciates her professional tenacity and grit while also having those same characteristics himself. When Elizabeth is really into someone, she gets the giggles; and Clayton, look out because she is ready to laugh! ",
        likes: [
          "Elizabeth speaks a dozen languages.",
          "Elizabeth says petting cute dogs makes her happy.",
          "Tattoos are not Elizabeth's thing."
        ],
        allieDescription: "video description: real estate engagement, kind of mature maybe, but also maybe cheesy, idk|||entrance description: brings a whip or they eat a sausage, quick cut|||post-entrance description: gives him a 93 year old photo of his great grandfather to hold onto, mostly just see her talking about the photo, |||rose #: 10|||"
      },
      weekEliminated: 4,
    },
    5: {
      id: 5,
      name: "Ency",
      age: "26",
      occupation: "Sales Manager",
      hometown: "Burbank, California",
      fullImageName: "ency_clayton_2022.jpeg",
      croppedImageName: "ency_clayton_2022_cropped.jpeg",
      biography: {
        description: "Ency doesn't discriminate when it comes to love languages – she speaks them all fluently! A true romantic at heart, Ency is ready to take a break from focusing on her career so she can focus on finding love and putting herself out there. She is looking for a tall man with a nice smile and an equally nice family. He should always be striving to grow both personally and professionally, and want the same of Ency. He should also love the ocean because nothing makes Ency happier than spending a day by the sea, splashing around and enjoying the sun. Hope you brought your speedo Clayton because Ency is ready to make waves! ",
        likes: [
          "Ency loves Dance Dance Revolution.",
          "Ency taught English to North Korean defectors when she studied abroad.",
          "Ency would love more opportunities to dress in costume."
        ],
        allieDescription: "video description: nan|||entrance description: half Persian half Korean, speaks some of those languages at him, older and you can kinda feel it, \"another pretty one they're all so pretty\"|||post-entrance description: nan|||rose #: 6|||"
      },
      weekEliminated: 3,
    },
    6: {
      id: 6,
      name: "Gabby",
      age: "30",
      occupation: "ICU Nurse",
      hometown: "Denver, Colorado",
      fullImageName: "gabby_clayton_2022.jpeg",
      croppedImageName: "gabby_clayton_2022_cropped.jpeg",
      biography: {
        description: "Gabby is much more than meets the eye. Not only is she beautiful, but she also has a lot of substance, emotional depth and a wealth of lived experiences. Gabby is looking for a man with quiet confidence. She doesn't have a physical type but says that if he doesn't have a personality, then it's a hard pass. Her ideal man will challenge her, communicate his feelings and work hard to equally carry the weight of the relationship. The one nonnegotiable for Gabby? Whatever man captures Gabby's heart must also have room in their life for her four-legged friend because she and her goldendoodle Leonardo are a package deal. While Gabby would love to find a man, she isn't the type to lose herself in a relationship. She's hoping to find a man who will love her for the independent woman she is. ",
        likes: [
          "Gabby is terrified of humpback whales but would love to see one in person from a safe distance.",
          "Gabby loves to write cards.",
          "Stomping grapes in Italy is at the top of Gabby's bucket list."
        ],
        allieDescription: "video description: goofy kind of awkward, NFL cheerleader, ICU nurse, \"did assist with the covid pandemic\"|||entrance description: brings a pillow because she just wwants to sit on his face loool|||post-entrance description: nan|||rose #: 20|||"
      }
    },
    7: {
      id: 7,
      name: "Genevieve",
      age: "26",
      occupation: "Bartender",
      hometown: "Los Angeles, California",
      fullImageName: "genevieve_clayton_2022.jpeg",
      croppedImageName: "genevieve_clayton_2022_cropped.jpeg",
      biography: {
        description: "Genevieve is looking for a man with whom she can get lost in their own little world. She is ambitious, confident and persistent, and says that when she wants something, there isn't much that can stop her from going for it. Her dream man is someone that will let her maintain her independence and always keep her laughing. She wants someone who won't be afraid to admit that they are wrong or change their personality when they are \"bro-ing out\" with the guys. They also must have a good relationship with their mother and be able to have deep, meaningful conversations. What Genevieve wants is to find a passionate man who can flirt with her well past the first date and make their love go the distance.",
        likes: [
          "Genevieve doesn't like public speaking.",
          "Genevieve loves to go fishing.",
          "Genevieve says Hailey and Justin Bieber are #RelationshipGoals."
        ],
        allieDescription: "video description: nan|||entrance description: hugs, basic intro statement but very smiley, he says \"i'm glad i'm not the only nervous one here\"|||post-entrance description: ooh he calls her in by name to get time with, seem excited to get to know one another|||rose #: 17|||"
      },
      weekEliminated: 7,
    },
    8: {
      id: 8,
      name: "Hailey",
      age: "26",
      occupation: "Pediatric Nurse",
      hometown: "Orlando, Florida",
      fullImageName: "hailey_clayton_2022.jpeg",
      croppedImageName: "hailey_clayton_2022_cropped.jpeg",
      biography: {
        description: "Hailey is here for the real deal, y'all! She feels like this is all happening at the perfect time for her – her body and career are exactly where she wants them to be: She has the unwavering support of her loving grandma, aka \"Lil Grams,\" and now she's just missing a man to complement it all. Hailey has a magnetic personality, is serious about finding love and won't settle for someone who just looks perfect on paper. She is looking for a family-oriented man with career ambition and a close relationship with God. Her dream man will value her and will love her firecracker personality instead of trying to soften her. At the end of the day, Hailey is trying to find her husband by any means possible, so Clayton, we hope you're ready!",
        likes: [
          "Hailey dreams of hanging out with elephants in Thailand.",
          "Hailey is not afraid to go skinny dipping.",
          "Hailey is Taylor Swift's biggest fan."
        ],
        allieDescription: "video description: nan|||entrance description: self-sufficient except for needing help opening a jar of pickles|||post-entrance description: gettin some cute little confessionals|||rose #: X|||"
      },
      weekEliminated: 1,
    },
    9: {
      id: 9,
      name: "Hunter",
      age: "28",
      occupation: "Human Resources Specialist",
      hometown: "Charlotte, North Carolina",
      fullImageName: "hunter_clayton_2022.jpeg",
      croppedImageName: "hunter_clayton_2022_cropped.jpeg",
      biography: {
        description: "Hunter is a Disney Princess looking for her Prince Charming. But seriously, Hunter used to work at Walt Disney World as a princess which 1) is way more competitive than you think and 2) absolutely makes sense when you get to know her. She is genuine, empathetic and compassionate. She describes herself as a romantic who longs for a relationship like you see in rom-coms. She is looking for a man who is loving, athletic and outdoorsy. He should also be adventurous with food because when it comes to eating, Hunter is a huge foodie. At her core, Hunter is truly ready to put it all on the line in the hopes of finding her happily ever after.",
        likes: [
          "Hunter's favorite book is \"A Walk to Remember.\"",
          "Hunter would love to be Justin Bieber's \"One Less Lonely Girl.\"",
          "Hunter would love to have an Australian accent.",
        ],
        allieDescription: "video description: nan|||entrance description: brings a snake, I believe in love at first hiss, quick cut|||post-entrance description: snake is still here and Colton touches it |||rose #: 23|||"
      },
      weekEliminated: 6,
    },
    10: {
      id: 10,
      name: "Ivana",
      age: "31",
      occupation: "Bar Mitzvah Dancer",
      hometown: "Queens, New York",
      fullImageName: "ivana_clayton_2022.jpeg",
      croppedImageName: "ivana_clayton_2022_cropped.jpeg",
      biography: {
        description: "Ivana is ready to find the one! She calls herself a multi-dater who has dated every type of man NYC has to offer. After some time spent meditating on it, she is here because it's time to manifest true love. She loves to spend her time clubbing, gardening and consignment shopping. Ivana's idea of a romantic date involves dancing naked in the rain and ends with cuddling after her man washes her hair. Also, her future hubby must love ALL animals because Ivana hopes to one day have a dog, five snakes, three exotic frogs, one lizard and a turtle. Above all, Ivana wants a man who is undeniably compatible with her. Well, we know Clayton likes dogs, so hopefully that's a start!",
        likes: [
          "Ivana takes herself out to a new restaurant every weekend.",
          "Ivana prefers indoor trees.",
          "Ivana works as a hand model from time to time."
        ],
        allieDescription: "video description: nan|||entrance description: bat mitzvah dancer hahah, ok she's being silent to start with, she blows him a kiss, and didn't say anything to him including her name lmaooo okay|||post-entrance description: nan|||rose #: X|||"
      },
      weekEliminated: 1,
    },
    11: {
      id: 11,
      name: "Jane",
      age: "33",
      occupation: "Social Media Director",
      hometown: "Los Angeles, California",
      fullImageName: "jane_clayton_2022.jpeg",
      croppedImageName: "jane_clayton_2022_cropped.jpeg",
      biography: {
        description: "Jane is the life of the party and not afraid to be bold. She loves to get dolled up for nights out with the girls but not as much as she loves her job working in social media. She likes to play hard but says she works even harder and wants to find a man who is just as passionate about building a successful career for themselves as she is. Jane is a sucker for romance and is done dating \"F boys.\" Now that she's making her romantic life a priority, she is ready for a family and hopes that Clayton will be the man with whom she steps into the next chapter of life.",
        likes: [
          "Jane dreams of a life filled with unlimited kombucha.",
          "Jane is terrified of tall cliffs.",
          "Jane prefers glamping over camping."
        ],
        allieDescription: "video description: nan|||entrance description: has been called a cougar before lol, I think she shows up a lot in the preview|||post-entrance description: nan|||rose #: X|||"
      },
      weekEliminated: 1,
    },
    12: {
      id: 12,
      name: "Jill",
      age: "26",
      occupation: "Architectural Historian",
      hometown: "Scituate, Rhode Island",
      fullImageName: "jill_clayton_2022.jpeg",
      croppedImageName: "jill_clayton_2022_cropped.jpeg",
      biography: {
        description: "Jill is full of life and personality. She loves being outside, walking around the local cemeteries and going out for drinks with the girls, but she is tired of being the only single one in her group of friends. Jill is looking for a man with a kind heart who is emotionally mature. Her dream man is someone she can laugh with, have deep conversations with, and someone who she enjoys spending time with more than she enjoys being alone. Physically, she says she is looking for a big, tall, hunk of a man that she can \"climb like a tree.\" Jill, please let us introduce you to Clayton …",
        likes: [
          "Jill knows how to identify baby pine trees as male or female.",
          "Jill was obsessed with \"Twilight\" as a teen.",
          "Petting a wombat is at the top of Jill's bucket list."
        ],
        allieDescription: "video description: nan|||entrance description: ashes of ex-boyfriends in an urn, brought in case he makes a mistake|||post-entrance description: nan|||rose #: 21|||"
      },
      weekEliminated: 5,
    },
    13: {
      id: 13,
      name: "Kate",
      age: "32",
      occupation: "Real Estate Agent",
      hometown: "Lake Hollywood, California",
      fullImageName: "kate_clayton_2022.jpeg",
      croppedImageName: "kate_clayton_2022_cropped.jpeg",
      biography: {
        description: "Kate has an amazing life – a great job with one of Los Angeles' top real estate firms, a beautiful home in the Hollywood Hills, and a glamorous social life that is nonstop fun. Kate feels like she is close to having it all, but the one thing that's missing is a special man in her life. She is looking for a nice, motivated, mature man with a bit of a flashy side. He should love a good thrill because Kate's idea of a romantic date is skydiving. She is over dating Hollywood party boys and ready to finally open her heart to someone ready for something real and hopes to walk away from this experience with a ring on her finger.",
        likes: [
          "Kate's moon/rising sign is Leo, which she says speaks for itself.",
          "Kate is a Mario Kart aficionado.",
          "Kate once went on a date with Harry Styles, and if you ask her for details, she'll tell you all about it."
        ],
        allieDescription: "video description: nan|||entrance description: has a lot of presence, makes a nips joke which apparently refers to shots??, here to do this thing lol|||post-entrance description: nan|||rose #: 8|||"
      },
      weekEliminated: 3,
    },
    14: {
      id: 14,
      name: "Kira",
      age: "32",
      occupation: "Physician",
      hometown: "Philadelphia, Pennsylvania",
      fullImageName: "kira_clayton_2022.jpeg",
      croppedImageName: "kira_clayton_2022_cropped.jpeg",
      biography: {
        description: "Kira is attractive, ambitious and wildly accomplished. She is fearlessly focused and dedicated to achieving any goal she sets her sights on, which is proven by her bachelor's degree from Harvard, her MBA from University of Pennsylvania, and her medical degree from UNC-Chapel Hill. WOW! She loves her career practicing internal medicine but is ready to put her professional life on hold so she can truly focus on herself for a change. Kira is looking for a mature man who will excite and inspire her. He should be social, always up for trying new things and appreciate the corny things in life. At the end of the day, Kira says she is looking for a man that will look at her the way she looks at french fries and we think she deserves nothing less.",
        likes: [
          "Kira taught herself to write hieroglyphics as a child.",
          "Kira loves nachos.",
          "Kira has two cats, Olga and Oksana."
        ],
        allieDescription: "video description: nan|||entrance description: in lingerie with a white coat over it, I'm a doctor and I'm here to give you your full-body physical, very controversial lol|||post-entrance description: \"you have a heart let's see if we can make it beat a little faster\" so they kiss|||rose #: 11|||"
      },
      weekEliminated: 4,
    },
    15: {
      id: 15,
      name: "Lindsay D",
      age: "27",
      occupation: "Neonatal Nurse",
      hometown: "Jacksonville, Florida",
      fullImageName: "lindsay_d_clayton_2022.jpeg",
      croppedImageName: "lindsay_d_clayton_2022_cropped.jpeg",
      biography: {
        description: "Born and raised in a small town with a loving family, Lindsay grew up encouraged by her supportive parents to chase her beauty queen dreams and won Miss Teen America at the age of 17. After giving birth to her daughter, her plans changed slightly, but Lindsay says she never knew a love like that could exist until she had her daughter! She is still searching for her love story but knows that she has to be selective since she and her daughter are in this together. Lindsay is looking for a man whom she can trust unconditionally. She wants someone who will give her as much respect as she gives them and bonus points for a man who can keep up with her on the dance floor. Lindsay is ready to find love and start a family, so she's hoping by taking this huge step and opening her heart back up to Clayton, their love story can begin.",
        likes: [
          "Lindsay D. loves Taco Bell.",
          "Lindsay D. loves Hallmark Christmas movies.",
          "Lindsay D. enjoys napping in hammocks."
        ],
        allieDescription: "video description: nan|||entrance description: shorter cut, basic, cute|||post-entrance description: nan|||rose #: X|||"
      },
      weekEliminated: 1,
    },
    16: {
      id: 16,
      name: "Lyndsey W",
      age: "28",
      occupation: "Industrial Sales Representative",
      hometown: "Houston, Texas",
      fullImageName: "lyndsey_w_clayton_2022.jpeg",
      croppedImageName: "lyndsey_w_clayton_2022_cropped.jpeg",
      biography: {
        description: "When it comes to living life, Lyndsey likes to just go for it! A small town girl making moves in the big city, Lyndsey isn't afraid to take great risk if it means great rewards. She is looking for a man who is humble, reliable and ready to be her best friend. Her faith is incredibly important to her and nothing would make her happier than to share that with the man she loves. Lyndsey is ready to stop wasting her time on men who don't deserve her and find someone who will make her the priority. Lyndsey believes true love is within reach and we hope she's right!",
        likes: [
          "Lyndsey W. doesn't do clowns.",
          "Lyndsey W. cannot sleep without a white noise machine.",
          "Lyndsey W. says she is much more of a Baby Spice then a Sporty Spice."
        ],
        allieDescription: "video description: nan|||entrance description: very blonde, makes a silly joke about being from South but looking up, eh, her nose is off-putting to me|||post-entrance description: nan|||rose #: nan|||"
      },
      weekEliminated: 5,
    },
    17: {
      id: 17,
      name: "Mara",
      age: "32",
      occupation: "Entrepreneur",
      hometown: "Collingswood, New Jersey",
      fullImageName: "mara_clayton_2022.jpeg",
      croppedImageName: "mara_clayton_2022_cropped.jpeg",
      biography: {
        description: "Mara is coming in hot and serving us swag! Born in Puerto Rico, she and her family migrated to the U.S. when she was young and has been spicing up the lives of everyone around her ever since. She considers herself the life of the party and is looking for a man to settle down with and keep life fun. When it comes to relationships, she is extremely nurturing but can be quite tough – she loves hard, fights hard and is extremely passionate. Mara is so ready to be in love and not afraid to put in the work to get there. Hope you're ready Clayton!",
        likes: [
          "Mara loves to Salsa dance.",
          "Snoring is a deal breaker for Mara.",
          "Mara doesn't eat spicy food."
        ],
        allieDescription: "video description: nan|||entrance description: like marinara, basic joke, quick cut|||post-entrance description: nan|||rose #: 14|||"
      },
      weekEliminated: 7,
    },
    18: {
      id: 18,
      name: "Marlena",
      age: "30",
      occupation: "Former Olympian",
      hometown: "Virginia Beach, Virginia",
      fullImageName: "marlena_clayton_2022.jpeg",
      croppedImageName: "marlena_clayton_2022_cropped.jpeg",
      biography: {
        description: "Marlena is definitely the full package and to say she's accomplished would be an understatement. Not only is she vice president of human resources, but she runs her own real estate company and owns several properties. She also competed for Haiti at the 2012 Summer Olympics in track and field! Yes, she is an Olympian, people! Marlena's energy is infectious and her smile lights up a room. She knows who she is in this world and has worked hard for all that she has in life. When Marlena loves, she loves hard and is looking for the right partner with whom to settle down.",
        likes: [
          "There is nothing or no one in this world who could convince Marlena to go bungee jumping.",
          "Marlena had a blonde Mohawk in high school.",
          "Marlena prefers to travel solo and has visited more than 15 countries on her own."
        ],
        allieDescription: "video description: nan|||entrance description: former olympian, penalty yard, 15 yards for being so fine hahah ok I liked that one|||post-entrance description: nan|||rose #: nan|||"
      },
      weekEliminated: 6,
    },
    19: {
      id: 19,
      name: "Melina",
      age: "27",
      occupation: "Personal Trainer",
      hometown: "West Hollywood, California",
      fullImageName: "melina_clayton_2022.jpeg",
      croppedImageName: "melina_clayton_2022_cropped.jpeg",
      biography: {
        description: "Melina is a bright, fun and charismatic gal who is ready to jump into the arms of the Bachelor, LITERALLY. When she's not working, she can be found bouncing around the streets of LA in her KANGABOOTS™️, a shock absorbing workout shoe Melina created to make cardio more fun. She lives a fast-paced life that includes shopping for designer clothes, spending time with her horse and eating exclusively from the hot bar at Erewhon. Frankly, she needs a man who can keep up! Her dream partner is incredibly athletic, unapologetic and punctual. She is looking for her best friend and doesn't plan on slowing down until she gets him!",
        likes: [
          "Melina is a very skilled jump roper.",
          "Melina loves being tucked in.",
          "When Melina dies, she wants to be buried in cranberry sauce and stuffing."
        ],
        allieDescription: "video description: nan|||entrance description: fast cut of her jumping into his arms in moon shoes|||post-entrance description: anad then they do a workout in moon shoes? very quickly|||rose #: nan|||"
      },
      weekEliminated: 4,
    },
    20: {
      id: 20,
      name: "Rachel",
      age: "25",
      occupation: "Flight Instructor",
      hometown: "Clermont, Florida",
      fullImageName: "rachel_clayton_2022.jpeg",
      croppedImageName: "rachel_clayton_2022_cropped.jpeg",
      biography: {
        description: "Rachel is a fearless frequent flyer looking for a man who will travel the world with her. After recently earning her private pilot license, the future is nothing but sunny skies ahead for Rachel and now she is hoping to find a co-pilot that matches her sense of adventure. As a hopeless romantic, Rachel is looking for someone who is as playful, passionate and spontaneous as she is. He should be nurturing, empathetic and MUST be respectful in all facets of life – to her, to family, and especially to waiters. In the end, Rachel wants to find crazy, insane love that makes sense to no one else but her and her soul mate.",
        likes: [
          "Rachel could live off of Flaming Hot Cheetos.",
          "Rachel can't wait to read the Harry Potter series with her kids one day.",
          "Rachel once organized a flash mob in high school."
        ],
        allieDescription: "video description: flight instructor, blonde-ish, Ohio, having fun with a plane, \"I don't know a lot about Clayton but he has nice teeth\" lol, maybe more charming?|||entrance description: umm has a 63-year-old woman introduce Rachel, makes a wing-woman joke, I guess maybe you need clout for the wing woman idk|||post-entrance description: got some time, is excited about him, etc.|||rose #: 5|||"
      }
    },
    21: {
      id: 21,
      name: "Rianna",
      age: "26",
      occupation: "Registered Nurse",
      hometown: "Dallas, Texas",
      fullImageName: "rianna_clayton_2022.jpeg",
      croppedImageName: "rianna_clayton_2022_cropped.jpeg",
      biography: {
        description: "A product of Texas, Rianna is a big personality with the cowgirl boots to match. She loves to two-step, hunt and horseback ride, and says she never hesitates to do something outrageous for a memory or experience. In recent years, Rianna has put her career and education at the forefront of her life, but now she's ready to hop back in the saddle and take her shot at finding the one. Rianna is looking for a man who is family-oriented, outgoing and loyal. He must love to travel and bonus points for a man who loves hiking as much as she does. One thing Rianna isn't interested in is a man who wants to change her because she loves who she is and knows that when she meets her soul mate, he'll feel the exact same way.",
        likes: [
          "Rianna loves to watch \"Naked & Afraid.\"",
          "Rianna hates doing laundry.",
          "Rianna is very competitive when it comes to playing Monopoly."
        ],
        allieDescription: "video description: nan|||entrance description: save a horse ride a cowgirl, quick cut|||post-entrance description: nan|||rose #: X|||"
      },
      weekEliminated: 1,
    },
    22: {
      id: 22,
      name: "Salley",
      age: "26",
      occupation: "Previously Engaged",
      hometown: "Charlottesville, Virginia",
      fullImageName: "salley_clayton_2022.jpeg",
      croppedImageName: "salley_clayton_2022_cropped.jpeg",
      biography: {
        description: "Salley is a real-life Meredith Grey looking for her McDreamy. She is a spine surgery robot operator who keeps a tight circle of friends that she likes to have dinner and drinks with but is a career-focused girl who is usually in bed by 11 p.m. She describes herself as religious, family-oriented and adventurous. She says she wants a man who is loyal, fun and willing to give her lots of attention because, why lie? She loves it! She says that when it comes to relationships, she is incredibly supportive and loves to make her man feel appreciated and wanted. What more could Clayton want?!",
        likes: [
          "Salley hates cotton balls and can't be near them.",
          "Salley is obsessed with hot tubs.",
          "Salley wants to own a clothing store one day."
        ],
        allieDescription: "video description: blonde, from Greenville South or North Carolina, was engaged and there was lack of trust and they called off the wedding, sad, wedding was supposed to be this weekend !?! Amy Cell says she has great teeth|||entrance description: goes to meet Clayton 3 hours before the limos, says how hard it is for her to be here and is crying, says she doesn't know if her heart is ready, and then he says \"i like what i'm seeing\" and goes to probably get a rose for her, oh yep he gives her a rose, ok but then she takes a long time to contemplate whether to accept it, oh shit ok so she actually leaves|||post-entrance description: nan|||rose #: nan|||"
      },
      weekEliminated: 1,
    },
    23: {
      id: 23,
      name: "Samantha",
      age: "26",
      occupation: "Occupational Therapist",
      hometown: "San Diego, California",
      fullImageName: "samantha_clayton_2022.jpeg",
      croppedImageName: "samantha_clayton_2022_cropped.jpeg",
      biography: {
        description: "Samantha is an outgoing go-getter who always has a glass-half-full attitude. She loves brunching, going to EDM festivals, and snuggling with her dog while enjoying a glass of white wine. Samantha's career is extremely important to her, but it is not her No. 1 priority – having a family is her biggest desire and she is ready to find a man with whom to grow a family! Samantha's dream man is gregarious, loyal and supportive. He also must be able to clean up after himself because Samantha wants a partner and not someone that she has to take care of. Samantha is looking for a real man who will enhance her life, and she's ready to put it all on the line for the pursuit of love.",
        likes: [
          "Samantha occasionally likes to play blackjack.",
          "Samantha has gone skydiving 12 times.",
          "Samantha would love to own a dog sanctuary one day."
        ],
        allieDescription: "video description: nan|||entrance description: \"want some of this bubbly personality\" with champagne coming out of a bathtub|||post-entrance description: nan|||rose #: X|||"
      },
      weekEliminated: 1,
    },
    24: {
      id: 24,
      name: "Sarah",
      age: "23",
      occupation: "Wealth Management Advisor",
      hometown: "New York City, New York",
      fullImageName: "sarah_clayton_2022.jpeg",
      croppedImageName: "sarah_clayton_2022_cropped.jpeg",
      biography: {
        description: "Sarah may be young, but she's gone through more in life than the average girl her age and she doesn't let it slow her down. When she's not killing it on Wall Street, she loves spending time with her family, taking trips to the spa, and growing the nonprofit she started which aims to support adopted children. Sarah says that she is a hopeless romantic and is ready to find the one. Her perfect man is respectful, easy-going and spontaneous by nature. He'll also love co-hosting dinner parties with her, giving her hugs that she can get lost in, and will share in her love of Valentine's Day. Sarah says that when she falls, she falls hard and fast; so Clayton, get ready to catch!",
        likes: [
          "Nothing makes Sarah happier than cute tiny dogs.",
          "Sarah gets frustrated by slow walkers.",
          "Sarah thinks that Wonder Woman is a badass."
        ],
        allieDescription: "video description: nan|||entrance description: wealth management, she wen to Clemson, very giggly, first one walking up, Clayton says \"she is beautiful! ... okay!\"|||post-entrance description: nan|||rose #: 7|||"
      },
      weekEliminated: 7,
    },
    25: {
      id: 25,
      name: "Serene",
      age: "26",
      occupation: "Elementary School Teacher",
      hometown: "Oklahoma City, Oklahoma",
      fullImageName: "serene_clayton_2022.jpeg",
      croppedImageName: "serene_clayton_2022_cropped.jpeg",
      biography: {
        description: "Serene's spirit is robust and her passion is strong. When she's not making a difference in her student's lives, she loves to spend time relaxing with close friends or planning her next big adventure. Serene knows her worth and what she needs to be happy. When it comes to relationships, she wants to find a man who is truly worthy of her commitment and ready to give her that same level of commitment back. Her dream man is honest, clear on what he wants, and an excellent communicator. He's open to trying new things, willing to be vulnerable, and ready to have a big family. Serene has a huge heart and a lot of love to give, so we can't wait to see if she finds our Bachelor a worthy recipient.",
        likes: [
          "Serene loves the theater but only if it's a musical.",
          "Guinea pigs seriously freak Serene out.",
          "Serene has a cat named Leonardo DiCATprio."
        ],
        allieDescription: "video description: nan|||entrance description: ok either just she or everyone feels like they're staring at him a little intensly, didn't say words that mean a lot but he said \"wow she's beautiful\"|||post-entrance description: nan|||rose #: 2|||"
      }
    },
    26: {
      id: 26,
      name: "Shanae",
      age: "29",
      occupation: "Recruiter",
      hometown: "Sycamore, Ohio",
      fullImageName: "shanae_clayton_2022.jpeg",
      croppedImageName: "shanae_clayton_2022_cropped.jpeg",
      biography: {
        description: "Shanae is a small-town girl with a HUGE personality. She describes herself as faithful, patient and lovable, and is here to find a man who can keep up with her in all facets of life. She is looking for a man with confidence that won't waste time playing games. She also values her independence, so while Shanae wants to build a life with someone, she also wants someone who encourages her to achieve the goals she has set for herself. Above all, Shanae wants to be able to be herself with a man who will honor and cherish her for the gem that she is.",
        likes: [
          "Shanae loves Christmas so much that she keeps her tree up all year round.",
          "Shanae is obsessed with zoos.",
          "Shanae is not interested in dating a man who performs magic."
        ],
        allieDescription: "video description: blonde, basic, , can do a cartwheel, says things like \"coming in hot\"|||entrance description: very southern, comes in in a monster truck, crushes the little baby car which put a target on her back|||post-entrance description: nan|||rose #: idk|||"
      },
      weekEliminated: 6,
    },
    27: {
      id: 27,
      name: "Sierra",
      age: "26",
      occupation: "Recruiting Coordinator",
      hometown: "Dallas, Texas",
      fullImageName: "sierra_clayton_2022.jpeg",
      croppedImageName: "sierra_clayton_2022_cropped.jpeg",
      biography: {
        description: "Sierra is a bold and beautiful firecracker of a woman. She loves traveling, yoga, dressing to impress, and working toward achieving her goals. Sierra says that everything she does has deep thought and purpose, so then why come to \"The Bachelor\"? Sierra is ready for marriage and she wants it soon! She is looking for a man who is cultured, direct and just as ambitious as she is. Also, please be clean because Sierra has no time for anyone with bad hygiene. Sierra is done wasting time and is ready to jump into love with both feet. Will Clayton be the man to take the plunge with her? Only time will tell...",
        likes: [
          "Sierra loves scavenger hunts.",
          "Sierra's favorite flower is a black rose.",
          "Sierra cannot eat enough Korean food, especially kimchi and rice."
        ],
        allieDescription: "video description: nan|||entrance description: hahah joke about coming back from the future and that she's his wife|||post-entrance description: nan|||rose #: 13|||"
      },
      weekEliminated: 5,
    },
    28: {
      id: 28,
      name: "Susie",
      age: "28",
      occupation: "Wedding Videographer",
      hometown: "Virginia Beach, Virginia",
      fullImageName: "susie_clayton_2022.jpeg",
      croppedImageName: "susie_clayton_2022_cropped.jpeg",
      biography: {
        description: "Susie is a beauty queen with a passion for adventure. Whether it's moving to Japan to experience a new culture or winning Miss Virginia 2020, Susie loves to defy expectations and is looking for a man who will keep life interesting. What Susie finds sexy is a thoughtful man who prioritizes quality time and creating shared experiences. With Susie, loyalty is the bottom line and she needs someone she can trust to her core. Above all, she wants someone who will always be there to support and encourage her dreams no matter where those dreams take her.",
        likes: [
          "Susie would love to be a documentary filmmaker one day.",
          "Susie hates haunted houses.",
          "Susie is a Jiu-Jitsu champion."
        ],
        allieDescription: "video description: lived in Japan for a few years, does jiu jitsu, blonde0ish, pageants?, seems cute and genuine idk quite charming|||entrance description: lol does a buzzer handshake about feeling a spark, kind of awkward which is surprising cuz she is so charming in all the other footage, |||post-entrance description: getting a lot of air time generally, steals him first after the speech!, asks him how he is doing, she gets a lot of confessionals including after their time together, she seems like a good pick idk|||rose #: 3|||"
      }
    },
    29: {
      id: 29,
      name: "Teddi",
      age: "24",
      occupation: "Surgical Unit Nurse",
      hometown: "Highland, California",
      fullImageName: "teddi_clayton_2022.jpeg",
      croppedImageName: "teddi_clayton_2022_cropped.jpeg",
      biography: {
        description: "Teddi is a catch. She is beautiful, smart and a total hopeless romantic who is ready to find her soul's counterpart. Though Teddi grew up in a strict Christian household, she lives by her own rules and has no interest in settling just to settle. Teddi is all about that instant connection and is looking for someone who won't be intimidated when she asks hard-hitting questions on the first date. While she is all about forming deep connections, Teddi also wants a man who can have fun with her and who won't be afraid to join her for a late-night skinny dip! Teddi is ready for the real thing and just wants to share her heart with someone who truly deserves it.",
        likes: [
          "Teddi could eat pasta for every meal.",
          "Growing up, Teddi was terrified of ladybugs.",
          "Teddi loves massages."
        ],
        allieDescription: "video description: nurse, \"tik tok i'm ready to be a misses\" jeez, virgin but would do it at a fantasy suite?, says she'll know if she's the one|||entrance description: everyone's been saying he's a big teddy bear ... now hopefully soon she'll be his teddy bear, okay, \"Teddi you make me feel some type of way\" ok maybe an above-average Colton comment lol|||post-entrance description: hahah she's very bubbly, my mom likes her a lot, definitely kind of less mature-seeming but also energetic, she did only want it to be him so that is nice, good connection and spark off the bat, first kiss!!!!, lot of kissing which puts a target on her too -- gets the first impression rose! says he feels complete when he's with her, etc.|||rose #: 1|||"
      },
      weekEliminated: 7,
    },
    30: {
      id: 30,
      name: "Tessa",
      age: "26",
      occupation: "Human Resources Specialist",
      hometown: "Stamford, Connecticut",
      fullImageName: "tessa_clayton_2022.jpeg",
      croppedImageName: "tessa_clayton_2022_cropped.jpeg",
      biography: {
        description: "Tessa is such a romantic that she is often accused of being cinematic. She loves grand romantic gestures, and when asked how she feels about love, she says \"there's definitely a moment to be extra and romance is IT!\" She loves attending concerts, spending time at her neighborhood dive bar, and dancing through the night until the sun comes up. Tessa is looking for a man who is kind, inclusive, and always pushing themselves to be better. She finds vulnerability super attractive and wants to be with a man who doesn't have trouble sharing his emotions. Tessa also dreams of finding someone to share her passion for music with because, for her, there is no sweeter song than a song of love.",
        likes: [
          "Tessa loves to bowl.",
          "Stevie Nicks is Tessa's bohemian icon.",
          "Tessa has a fear of red fruits: apples, grapes, raisins, etc..."
        ],
        allieDescription: "video description: nan|||entrance description: HR, asset backwards, silly comment, shorter cut|||post-entrance description: nan|||rose #: 24|||",
      },
      weekEliminated: 3,
    }
  },
  allIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,],
  stillInWeek2Ids: [0, 3, 4, 5, 6, 7, 9, 12, 13, 14, 16, 17, 18, 19, 20, 24, 25, 26, 27, 28, 29, 30,],
  eliminatedWeek1Ids: [1, 2, 8, 10, 11, 15, 21, 22, 23],
}

const gabbyRachelContestantData = {
  byId: {
    0: {
      id: 0,
      name: "Alec",
      age: "27",
      occupation: "Wedding Photographer",
      hometown: "Houston, TX",
      fullImageName: "alec_gabby_rachel_2022.jpeg",
      croppedImageName: "alec_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Alec is a self-proclaimed \"lover boy.\" He's charismatic, athletic, funny and, why lie, he loves to be the center of attention. Alec describes marriage as sacred and he isn't interested in settling for anything less than his true soul mate. He is looking for a passionate woman who can match his drive for success and hustle alongside him to the very end. Alex is very much a go-getter and in order for love to go the distance, he needs someone on the same wavelength. He says that once he's committed, he treats his partner like royalty. Alec, let us introduce you to two queens!",
        likes: [
          "Nothing makes Alec happier than a fresh haircut.",
          "Alec once worked as a DJ at a roller rink.",
          "If Alec could travel back in time, he would go to the 1800s and be an outlaw cowboy."
        ]
      }
    },
    1: {
      id: 1,
      name: "Aven",
      age: "28",
      occupation: "Sales Executive",
      hometown: "San Diego, CA",
      fullImageName: "aven_gabby_rachel_2022.jpeg",
      croppedImageName: "aven_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Aven's life is fueled by passion. Whether he's slaying the sales game at work or hooping on the weekend, Aven puts his heart into everything he does. He tries to approach love with the same mentality but has yet to find his perfect match. Aven wants a woman who is loyal, honest and able to organically bring out his fun side. She should love to travel as much as he does and will value quality time together above all else. Aven wants to build a meaningful relationship that goes deeper than the surface and when he meets the right one, he is ready to put it all out there if it means finding his forever.",
        likes: [
          "Aven enjoys slow dancing.",
          "Aven allows himself to eat out only once a week.",
          "Aven is a Yahtzee champ and describes his game play as intense."
        ]
      }
    },
    2: {
      id: 2,
      name: "Brandan",
      age: "23",
      occupation: "Bartender",
      hometown: "Carlsbad, CA",
      fullImageName: "brandan_gabby_rachel_2022.jpeg",
      croppedImageName: "brandan_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Brandan may be on the younger side, but he is emotionally mature beyond his years and is ready to find the one. A former college athlete, Brandan is no stranger to putting in the hard work in order to achieve success. He says he is an open book and once he finds the right woman, he is not afraid to put himself out there in the name of love. Brandan's perfect match is thoughtful, independent and supportive. He describes himself as very adventurous, so he hopes to find someone who will say \"yes\" to whatever comes their way alongside him. One thing he is definitely ready to say \"yes\" to? A rose, of course! Ladies, the ball is in your court.",
        likes: [
          "Brandan is terrified of slugs.",
          "Brandan loves to go to the batting cages.",
          "Brandan loves Disneyland and goes every year on his birthday."
        ]
      }
    },
    3: {
      id: 3,
      name: "Chris",
      age: "30",
      occupation: "Mentality Coach",
      hometown: "Redondo Beach, CA",
      fullImageName: "chris_gabby_rachel_2022.jpeg",
      croppedImageName: "chris_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Chris is an extremely driven person and has accomplished a lot in his life. Professionally, he knows what his goals are and exactly what he needs to do to accomplish them; but when it comes to love, the journey is just beginning. Chris is looking for someone ambitious, compassionate and secure. She should support Chris as he chases his goals but also work hard to accomplish the goals she has for herself. He needs a woman who will love him for the hard worker he is and not complain as the two of them work together toward greatness.",
        likes: [
          "Chris LOVES Mangos.",
          "Chris has written two books and says he is his own favorite author.",
          "When asked what Chris fears in life, he says, \"I don't choose fear.\""
        ]
      }
    },
    4: {
      id: 4,
      name: "Colin",
      age: "36",
      occupation: "Sales Director",
      hometown: "Chicago, IL",
      fullImageName: "colin_gabby_rachel_2022.jpeg",
      croppedImageName: "colin_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Colin lives for the thrill of experience. Travel is his thing, and he hopes to find someone who wants to experience everything the world has to offer right alongside him. He says that his perfect woman is self-aware, courteous and fun. She should have a good head on her shoulders, appreciate romantic gestures and, of course, be up for adventure. Colin is not looking to be the passenger on someone else's train and wants to find a connection that is truly a partnership. For Colin, life is all about creating long-lasting memories and, really, who could ask for anything more?!",
        likes: [
          "Colin would love to visit the International Space Station.",
          "People who chew with their mouth open make Colin cringe.",
          "Colin was the founding member of his high school's breakdancing club."
        ]
      }
    },
    5: {
      id: 5,
      name: "Erich",
      age: "29",
      occupation: "Real Estate Analyst",
      hometown: "Bedminster, NJ",
      fullImageName: "erich_gabby_rachel_2022.jpeg",
      croppedImageName: "erich_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Erich is a handsome guy whose quiet confidence is hard to ignore. He describes himself as low-key, funny, outdoorsy and a bit mysterious with a fun side. When it comes to relationships, Erich acts with intention and is looking for the real deal. He doesn't want to be in a relationship just to be in a relationship and says that the next time he commits, it's for the long haul. His perfect partner is easygoing, selfless and communicative; and while he says he doesn't have a type, he often finds himself attracted to taller women. He loves doing the unexpected and finds joy in making someone feel special. Above all, Erich hopes to find his best friend and someone with whom he can spend a lifetime of quality time.",
        likes: [
          "Erich has no interest in going to an escape room.",
          "Erich likes his wine both red and white.",
          "Erich dreams of seeing the Northern Lights in person."
        ]
      }
    },
    6: {
      id: 6,
      name: "Ethan",
      age: "27",
      occupation: "Advertising Executive",
      hometown: "New York, NY",
      fullImageName: "ethan_gabby_rachel_2022.jpeg",
      croppedImageName: "ethan_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Ethan is a hopeless romantic who isn't afraid to wear his heart on his sleeve. He loves his life in New York City but is at the point in his life where going out until 4 a.m. is of no interest and would rather spend his time wining and dining a woman who is just as ready to settle down as he is. Ethan's dream woman is genuine, family-oriented, comfortable in their own skin and, above all else, fun. He credits his mother with teaching him to value a woman's love and that, thanks to her, he enjoys making a woman feel special and appreciated. Ethan is a big believer in signs and, thanks to a few well-timed coincidences, he feels like this is exactly where he's meant to be. Will the stars be aligned as he makes his way out of the limo? Twinkle, twinkle Ethan!",
        likes: [
          "Ethan says he is the king of Monopoly.",
          "Ethan's perfect breakfast is pancakes and hash browns, extra crispy.",
          "Watching \"Entourage\" cheers Ethan up when he is down."
        ]
      }
    },
    7: {
      id: 7,
      name: "Hayden",
      age: "29",
      occupation: "Leisure Executive",
      hometown: "Tampa, FL",
      fullImageName: "hayden_gabby_rachel_2022.jpeg",
      croppedImageName: "hayden_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "With a bit of the accent and a lot of the charm, Hayden is proud of his southern roots. He tries not to take life so seriously and loves to golf, hit the beach and work his magic on the grill. He considers himself to be very romantic and enjoys planning extravagant dates to make his partner feel special. Hayden is looking for a thoughtful and trustworthy woman who appreciates him for the man he is, even on his worst days. She also MUST love dogs because Hayden and his good boy, Rambo, are a package deal. No one is getting a ring on their finger without Rambo's approval, so let's get this paw-ty started!",
        likes: [
          "Hayden loves baked potatoes.",
          "Hayden likes to end his day with a dip in the hot tub.",
          "Hayden loves country music and would thrive at Stagecoach."
        ]
      }
    },
    8: {
      id: 8,
      name: "Jacob",
      age: "27",
      occupation: "Mortgage Broker",
      hometown: "Scottsdale, AZ",
      fullImageName: "jacob_gabby_rachel_2022.jpeg",
      croppedImageName: "jacob_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Jacob knows exactly what he wants in a wife. He admits he's picky, but since he's looking for a love to last a lifetime, he doesn't see that as a bad thing. Jacob's ideal wife will turn heads with her looks wherever she goes and have beauty on the inside that matches. For Jacob, keeping his fitness routine in check is high on the priority list, so his ideal partner should want to maintain a healthy lifestyle and be able to have fun working up a sweat both in and out of the gym. Jacob is ready to let his guard down when he meets someone who checks all of his boxes.",
        likes: [
          "Jacob reads 30-40 books a year.",
          "Jacob loves to camp in national parks.",
          "Jacob doesn't eat cake."
        ]
      }
    },
    9: {
      id: 9,
      name: "James",
      age: "25",
      occupation: "Meatball Enthusiast",
      hometown: "Winnetka, IL",
      fullImageName: "james_gabby_rachel_2022.jpeg",
      croppedImageName: "james_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "James is a charmer with dimples so cute that you can't help but smile. He is ready to find someone to bring home to meet his loving big Italian family and says that he hopes to find someone that will care about him just as much as he cares about them. He is looking for a relationship with undeniable chemistry and wants to find his best friend with whom he can build an amazing life. He also wants six kids, so his future bride should be ready and excited for the nonstop thrill ride that is parenthood. For James, it's all about family and bringing someone in to enhance his already wonderful life, and really, who could ask for anything more?",
        likes: [
          "James is a huge Lebron James fan.",
          "James loves to waterski.",
          "Nothing makes James happier than a plate of piping hot meatballs."
        ]
      }
    },
    10: {
      id: 10,
      name: "Jason",
      age: "30",
      occupation: "Investment Banker",
      hometown: "Santa Monica, CA",
      fullImageName: "jason_gabby_rachel_2022.jpeg",
      croppedImageName: "jason_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Jason is a relationship kind of guy. He loves love and is ready to find a woman with whom to laugh and grow through life. He is never the loudest guy in the room, yet still somehow has an undeniable presence about him. He loves to surf, dance and play tennis. Jason is looking for a woman who is adventurous, trustworthy and down to enjoy a few drinks while the sun sets. He values authenticity and says that when he finds the one, the vibes will tell him all he needs to know. Gabby and Rachel, you hear that?? GOOD VIBES ONLY!",
        likes: [
          "Jason loves to tour historical homes.",
          "Jason hates Coca-Cola.",
          "Jason's favorite pastime is stargazing."
        ]
      }
    },
    11: {
      id: 11,
      name: "Joey",
      age: "24",
      occupation: "Twin",
      hometown: "Brookfield, CT",
      fullImageName: "joey_gabby_rachel_2022.jpeg",
      croppedImageName: "joey_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Joey is a loveable goofball. When he's not out looking for love, he loves to hit the clubs in New York City for a night out with the boys or spend time at home unwinding from a hard day's work with his identical twin brother, Justin. Joey is ready to be in a serious relationship and says he is looking for someone affectionate, family-oriented and sweet. She should be a good communicator and appreciate a good hug because, according to Joey, his are pretty great. At the end of the day, Joey is looking for someone to build a life with, so hope you're ready ladies!",
        likes: [
          "Nothing makes Joey happier than winning.",
          "If Joey could, he would live off of cheeseburgers.",
          "Joey loves go-karting."
        ]
      },
      weekEliminated: 1,
    },
    12: {
      id: 12,
      name: "John",
      age: "26",
      occupation: "English Teacher",
      hometown: "Nashville, TN",
      fullImageName: "john_gabby_rachel_2022.jpeg",
      croppedImageName: "john_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "John is a sweet and genuine man who will do anything for the people for whom he truly cares. Though he may come off as shy at first, once you get to know John, it's hard to ignore the positivity that radiates from within him. John's perfect woman is loving, honest and vocal about what she wants in her partner. She should want to keep life exciting but also be a shoulder to lean on when life throws the inevitable curveball. John says that when it comes to love, he is very intentional in every move he makes. And you may be wondering, what are his intentions with our Bachelorettes? He's ready to put a ring on it!",
        likes: [
          "John enjoys dressing up so much that sometimes he puts on a suit to go grocery shopping.",
          "John would love to be the face of a big clothing brand.",
          "John loves Mexican food."
        ]
      }
    },
    13: {
      id: 13,
      name: "Johnny",
      age: "25",
      occupation: "Realtor",
      hometown: "Palm Beach Gardens, FL",
      fullImageName: "johnny_gabby_rachel_2022.jpeg",
      croppedImageName: "johnny_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Johnny is a laid back, simple man who wants to find love just like the rest of us. When he isn't hanging out with his huge, crazy, Italian family, he is surfing with his boys or taking advantage of any free time to plan his next travel adventure. Johnny's perfect woman is intelligent, outgoing and athletic. She'll also have good hygiene and love Barry White and Frank Sinatra as much as he does. Johnny is a sucker for a good smile and says he'll do whatever it takes to make his partner light up. Johnny is a forward thinker and says he can't be with anyone who is hung up on past relationships. He's ready to start the next chapter of his life and now he just needs to find the right woman to stand by his side.",
        likes: [
          "Johnny's childhood dream was to be a rapper.",
          "Johnny loves to make corny jokes.",
          "Johnny loves fishing."
        ]
      }
    },
    14: {
      id: 14,
      name: "Jordan H",
      age: "25",
      occupation: "Software Developer",
      hometown: "Tampa, FL",
      fullImageName: "jordan_h_gabby_rachel_2022.jpeg",
      croppedImageName: "jordan_h_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Jordan H. is an adrenaline junkie who falls hard and fast. He loves to spend his free time racing dirt bikes and hopes to find a woman who wants to experience life's greatest thrills by his side. Jordan H. has true intentions of finding love and, wait for it … is definitely here for the right reasons. His perfect match is mature, independent, exciting and ready to have a big family because Jordan H. wants a ton of kids. She should love spontaneity and be ready to get flowers on any random day of the week because that is Jordan H.'s favorite move. Lucky her, right?!",
        likes: [
          "Jordan H. is an amateur dirt bike racer.",
          "The Fourth of July is Jordan H.'s favorite holiday.",
          "Jordan H. loves Florida but hates Florida drivers."
        ]
      }
    },
    15: {
      id: 15,
      name: "Jordan V",
      age: "27",
      occupation: "Drag Racer",
      hometown: "Alpharetta, GA",
      fullImageName: "jordan_v_gabby_rachel_2022.jpeg",
      croppedImageName: "jordan_v_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Jordan V. has got the need, the need FOR SPEED! When this professional race car driver isn't on the track going from 0 to 337mph in less than four seconds (yes, you read that right), he loves spending time with his nephews, relaxing on the lake and watching James Bond movies. Jordan V. is looking for someone who is sincere, passionate and supportive. And while he loves the hustle and bustle of his fun life, Jordan V. is very much looking to settle and start a family. This time next year, he's hoping when he crosses the finish line, his future wife is there waiting for him. So, Jordan V., start your engine.",
        likes: [
          "Jordan V. loves sweet tea.",
          "Jordan V. says the most romantic gift you can give is a handwritten letter.",
          "Jordan V. describes his middle school haircut as the Justin Bieber."
        ]
      }
    },
    16: {
      id: 16,
      name: "Justin B",
      age: "32",
      occupation: "Physical Therapist",
      hometown: "Solana Beach, CA",
      fullImageName: "justin_b_gabby_rachel_2022.jpeg",
      croppedImageName: "justin_b_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Justin B. is a lover, not a fighter. He's all about creating memories in life's little moments and dreams of having a woman who will enjoy what the world has to offer by his side. He's not just looking for his best friend but rather wants someone who can keep him excited as life naturally settles into calm. His dream woman is open-minded, fit and always striving to be the best version of herself. Justin B. is a glass half full kind of guy who is optimistic to his core. And, let me tell you, one thing he is really excited about is meeting Gabby and Rachel. Go get 'em, tiger!",
        likes: [
          "Coffee shops are Justin B.'s happy place.",
          "Justin B. loves the smell of jasmine.",
          "Justin B. dreams of traveling the U.S. in a van."
        ]
      }
    },
    17: {
      id: 17,
      name: "Justin Y",
      age: "24",
      occupation: "Other Twin",
      hometown: "Brookfield, CT",
      fullImageName: "justin_y_gabby_rachel_2022.jpeg",
      croppedImageName: "justin_y_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Justin Y. is the life of the party! He is a hardworking go-getter who enjoys clubbing in New York City's hottest spots with his favorite wingman, his identical twin brother, Joey. Justin Y. describes himself as attentive and loyal and says that he is ready to settle down when he meets the right one. His perfect woman is honest, outgoing and family-oriented. Communication is everything to Justin Y. and long term, he could never be with someone who has issues opening up. Justin Y. is ready to find his forever and so, Gabby and Rachel, hope you're in it to TWIN it!",
        likes: [
          "Justin Y. wants to learn how to salsa dance.",
          "Justin Y. loves the Lakers.",
          "If Justin Y. could live in any other decade, he would choose the early '90s."
        ]
      },
      weekEliminated: 1,
    },
    18: {
      id: 18,
      name: "Kirk",
      age: "29",
      occupation: "College Football Coach",
      hometown: "Lubbock, TX",
      fullImageName: "kirk_gabby_rachel_2022.jpeg",
      croppedImageName: "kirk_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Kirk is a successful football coach who is ready to find love that will go the distance. During the season, he works seven days a week and wants a partner that understands how demanding his work schedule is and will happily hold down the fort while he's away. That being said, he's looking for an independent woman and someone who will be his teammate through the hardest of times. Kirk loves being the fun uncle to his brother's kids, but if it's true love that he finds with either Gabby or Rachel, he is ready to go for the W and put a ring on it to start a family of his own. TOUCHDOWN!",
        likes: [
          "Kirk loves golf but isn't very good at it.",
          "Kirk never wears white while eating spaghetti.",
          "Kirk would love to visit Santorini one day."
        ]
      }
    },
    19: {
      id: 19,
      name: "Logan",
      age: "26",
      occupation: "Videographer",
      hometown: "San Diego, CA",
      fullImageName: "logan_gabby_rachel_2022.jpeg",
      croppedImageName: "logan_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Logan is a California beach boy who loves the sun, surf and the idea of finding someone to share them both with for the rest of time. While he loves to hang with his boys, he's ready to trade in bachelorhood and find someone to grow with in a meaningful way. Logan is witty, self-aware and goofy. He is very into self-growth and wants to find someone who is just as much of a free spirit as he is. His ideal woman is artsy, low maintenance and down to cuddle by a bonfire under the stars. Logan is looking for a deep connection with strong chemistry, so we can't wait to see if sparks fly when he meets our Bachelorettes!",
        likes: [
          "Logan's lifelong crush is Elaine from \"Seinfeld.\"",
          "Logan isn't afraid to skinny dip.",
          "Logan loves corn dogs."
        ]
      }
    },
    20: {
      id: 20,
      name: "Mario",
      age: "31",
      occupation: "Personal Trainer",
      hometown: "Naperville, IL",
      fullImageName: "mario_gabby_rachel_2022.jpeg",
      croppedImageName: "mario_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Mario is a ball of energy with a smile that lights up the room! When it comes to love, Mario says he is emotionally available and proudly wears his heart on his sleeve. He has done the self-work and is ready to be vulnerable, settle down and start a family (of future basketball players). For Mario, fitness is a way of life and, even if he wasn't getting paid, he'd still be out there working hard for his clients. Above all, Mario just wants to make his mother proud. He is looking for a woman in tune with her emotions and someone to share a good meal and conversation with; sounds like you've come to the right place, Mario!",
        likes: [
          "Mario is a Tetris superstar.",
          "Mario loves tulips.",
          "Mario likes to end his day by listening to '90s R&amp;B."
        ]
      }
    },
    21: {
      id: 21,
      name: "Matt",
      age: "25",
      occupation: "Shipping Executive",
      hometown: "San Diego, CA",
      fullImageName: "matt_gabby_rachel_2022.jpeg",
      croppedImageName: "matt_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Matt is a successful young man who owns his own business and has admittedly prioritized work over his romantic relationships in the past. But now that Matt has built his empire, he's ready to find a woman to share it with! Matt is sincere about finding love and he's looking for a woman who is ambitious and loyal. He loves to make a woman laugh and wants to experience new things with his partner. For Matt, vibes are everything, and we can't wait to see if he vibes with Gabby or Rachel!",
        likes: [
          "Matt has a pet Betta fish named Tony.",
          "Matt would rather vibe than dance to good music.",
          "Matt dreams of buying his mom a home wherever she wants."
        ]
      }
    },
    22: {
      id: 22,
      name: "Michael",
      age: "32",
      occupation: "Pharmaceutical Salesman",
      hometown: "Long Beach, CA",
      fullImageName: "michael_gabby_rachel_2022.jpeg",
      croppedImageName: "michael_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Michael might be reserved at first, but once he opens up, he is thoughtful and attentive with the foundation of a great husband. He does not consider himself a conventional romantic and leads with logic over emotion, but his biggest ally when it comes to love is his own intuition, \"when you know, you know.\" His mom is the driving force in his life and the reason he works so hard is to take care of his family. Michael is looking for a woman who is not afraid to get out of her comfort zone and try new things alongside him, a woman who is a go-getter. He is excited to meet a memorable woman, and, good news for Michael, he's about to meet two!",
        likes: [
          "Michael says that parents love him.",
          "Michael is not afraid to admit that he is a bad cook.",
          "Michael loves Harry Potter and wants to visit his Wizarding World."
        ]
      }
    },
    23: {
      id: 23,
      name: "Nate",
      age: "33",
      occupation: "Electrical Engineer",
      hometown: "Chicago, IL",
      fullImageName: "nate_gabby_rachel_2022.jpeg",
      croppedImageName: "nate_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "This handsome Chicago native is the full package! Humble and hardworking, Nate tries to always live life to the fullest! He is determined to take chances and is finally taking the time to focus on finding true love. Nate is looking for a woman who is kind, adventurous, smart and ready to complete his beautiful family. He also has a weakness for a woman who can make him laugh so hard his belly hurts. Nate loves to do thoughtful things for the woman he loves and, as an example, he says he plans amazing picnics! Cheese and crackers with a handsome guy like Nate? Sounds like the perfect one-on-one to us!",
        likes: [
          "Nate's favorite movie is \"How to Lose a Guy in 10 Days.\"",
          "Nate enjoys an occasional gas station Icee.",
          "Nate doesn't do mosh pits."
        ]
      }
    },
    24: {
      id: 24,
      name: "Quincey",
      age: "25",
      occupation: "Life Coach",
      hometown: "Miami, FL",
      fullImageName: "quincey_gabby_rachel_2022.jpeg",
      croppedImageName: "quincey_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Quincey's energy is unmatched, and he has a unique gift to uplift the people around him with both laughter and wisdom. Quincey fulfilled his lifelong dream of becoming his own boss and even travels around the country giving motivational talks. To Quincey, genuine connection is everything in a relationship and he believes in treating women as strong, beautiful queens who deserve romance! Quincey, you're in luck; Rachel and Gabby are ready to be romanced!",
        likes: [
          "Quincey loves high-vibration music.",
          "Quincey admits he is a bad texter.",
          "Quincey could watch \"Space Jam\" on repeat and never get bored.",
        ]
      }
    },
    25: {
      id: 25,
      name: "Roby",
      age: "33",
      occupation: "Magician",
      hometown: "Los Angeles, CA",
      fullImageName: "roby_gabby_rachel_2022.jpeg",
      croppedImageName: "roby_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Roby is an impressive and accomplished magician who has stories of unconventional life experiences no one would believe. Having grown up traveling across the globe, he's a man of the world who can tell you anything in French. He's smart, well-read and an expert conversationalist. But, ultimately, he hasn't found that special someone with whom to spend the rest of his life. When he's in a relationship, he's fully in it and loves hard. He's hoping Gabby and Rachel like magic because he's got some major tricks up his sleeve.",
        likes: [
          "Roby is an advanced scuba diver.",
          "Roby went all through elementary and high school only learning in French.",
          "Roby wants to live in a home with secret rooms and hidden tunnels in it."
        ]
      },
      weekEliminated: 1,
    },
    26: {
      id: 26,
      name: "Ryan",
      age: "36",
      occupation: "Investment Director",
      hometown: "Boston, MA",
      fullImageName: "ryan_gabby_rachel_2022.jpeg",
      croppedImageName: "ryan_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Ryan is ready to settle down and get wife'd up! He falls hard and fast and describes himself as a hopeless romantic who knows true love will find him soon. But make no mistake, Ryan isn't going to settle just to settle! He wants the kind of love that is a \"24/7 head over heels, best friend, roll on the floor laughing, make out in the rain type of relationship.\" He wants a Nicholas Sparks type of relationship and is willing to put in the work to bring his love story to life! Will this journey be \"A Walk to Remember\"? Or will Ryan have to wait a little bit longer for his storybook ending.",
        likes: [
          "Ryan is a proud Botox enthusiast.",
          "Ryan hopes to open his own dog rescue and shelter someday.",
          "Ryan LOVES pineapple."
        ]
      }
    },
    27: {
      id: 27,
      name: "Spencer",
      age: "27",
      occupation: "Venture Capitalist",
      hometown: "Chicago, IL",
      fullImageName: "spencer_gabby_rachel_2022.jpeg",
      croppedImageName: "spencer_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Spencer is a man of service who is committed to finding something significant. He says that he doesn't consider himself a textbook romantic because he doesn't like to idealize things, but please don't hold that against him because he promises he is a great partner who finds thoughtful ways to make his significant other feel special. Spencer's ideal woman is active, outdoorsy and adventurous. She also must have a great sense of humor and a great laugh to match. Spencer says he is not your stereotypical \"let's grab a drink\" kind of guy, so hope Gabby and Rachel are ready for the fun that he is ready to serve.",
        likes: [
          "Nothing makes Spencer happier than seeing the Detroit Lions win.",
          "Spencer gets a lot of compliments on his eyebrows.",
          "Spencer thinks EDM is ridiculous."
        ]
      }
    },
    28: {
      id: 28,
      name: "Termayne",
      age: "28",
      occupation: "Crypto Guy",
      hometown: "Naperville, IL",
      fullImageName: "termayne_gabby_rachel_2022.jpeg",
      croppedImageName: "termayne_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Termayne is an energetic guy with a big personality. He is a self-made entrepreneur who is not afraid to be bold and say what's on his mind. Termayne is looking for a loyal woman who will love him for the hustler and hard worker that he is. She will be intelligent, respectful and funny because nothing turns him on more than a woman who can take a joke. And bonus points to anyone who can talk crypto with him. The second a woman says \"NFT,\" it's game over.",
        likes: [
          "Termayne loves double dates.",
          "Termayne would love to snuggle with a baby cheetah.",
          "Termayne doesn't like sweets."
        ]
      }
    },
    29: {
      id: 29,
      name: "Tino",
      age: "27",
      occupation: "General Contractor",
      hometown: "Playa Del Rey, CA",
      fullImageName: "tino_gabby_rachel_2022.jpeg",
      croppedImageName: "tino_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Tino is a go-with-the-flow kind of guy. He loves spending time with his family and friends; and if he's not surfing, he's cycling, camping or loving on his family dog as much as he possibly can. Tino is very involved in giving back to his community and wants to find someone who will work to make the world a better place alongside him. He wants a meaningful connection that will set the groundwork for him and his future wife to be great parents because, for Tino, family is everything; AND he wants four kids. He's ready to put everything he's got into building something real with Gabby or Rachel, and we think the two of them deserve nothing less.",
        likes: [
          "Tino enjoys reading the Wall Street Journal.",
          "Tino says 90% of what he eats is meat.",
          "Tino dreams of surfing in Bali."
        ]
      }
    },
    30: {
      id: 30,
      name: "Tyler",
      age: "25",
      occupation: "Small Business Owner",
      hometown: "Wildwood, NJ",
      fullImageName: "tyler_gabby_rachel_2022.jpeg",
      croppedImageName: "tyler_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Tyler is an Italian stallion looking for the love of his life! He isn't afraid to be over the top and says that he wants the kind of connection that is so profound, others will say that it's the kind of love you only see in movies. Tyler's dream woman is fun, reliable, open-minded and ready to come home and meet his wonderful and loving family. He wants to find someone who will be vulnerable with him and open about what's on her mind; no surprises please. If the connection is there, Tyler is the kind of guy who will give it his all. He's ready to go big, so we hope that doesn't send him home!",
        likes: [
          "Tyler dreams of visiting every park in the MLB.",
          "Tyler likes country music that he can dance to.",
          "Tyler is not a good multitasker."
        ]
      }
    },
    31: {
      id: 31,
      name: "Zach",
      age: "25",
      occupation: "Tech Executive",
      hometown: "Anaheim Hills, CA",
      fullImageName: "zach_gabby_rachel_2022.jpeg",
      croppedImageName: "zach_gabby_rachel_2022_cropped.jpeg",
      biography: {
        description: "Zach is an old-fashioned romantic. He loves his mama, his dogs and football but promises he has more love to go around! He's charismatic, personable and has a huge heart that he is so ready to share forever with the right woman. Zach's perfect woman is compassionate, kind and ready for romance because Zach is excited to lay it on thick. He loves to plan thoughtful surprises, and nothing makes him happier than seeing the look of excitement on his partner's face when his meaningful gifts are appreciated. Zach is serious about finding a love that will lead to marriage, so whatever grand romantic gesture it takes for him to get there, he's ready to go!",
        likes: [
          "Zach would love to be Spiderman for a day.",
          "Zach doesn't like breakfast. Eggs don't agree with him.",
          "Zach loves beach volleyball and is a master of the \"Top Gun\" high-five.",
        ]
      }
    }
  },
  allIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,],
  stillInWeek2Ids: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 26, 27, 28, 29, 30, 31,],
  eliminatedWeek1Ids: [11, 17, 25],
};

export const contestantDataBySeason = {
  "katie_2021": katieContestantData,
  "michelle_2021": michelleContestantData,
  "clayton_2022": claytonContestantData,
  "gabby_rachel_2022": gabbyRachelContestantData,
};

import React, { Component } from "react";

import { get } from "lodash";

import {
  Box,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Link,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

import appearance from "../../services/appearance";

import {
  STRING,
  NUMERIC,
  RADIO,
  SELECT_CONTESTANT,
  CLOSEST,
  BINARY_STRING,
  BINARY_NUMBER,
  ONE_OF,
  WITHIN_AMOUNT,
  WITHIN_PERCENT,
  NUMERIC_OFF_BY,
  ONE_OF_CONTESTANT_IDS,
  INDIVIDUAL_VIBES_FROM_ALLIE,
} from "../../data/question-types";

import ContestantSelector from "../ContestantSelector";
import ArbitraryFactScorer from "../../features/admin/ArbitraryFactScorer";

const styles = (theme) => ({
  questionText: {
    //color: fade("#000000", 0.54),
  },
  pointsChip: {
    fontWeight: "500",
    fontSize: "large",
  },
  pointsChipCorrect: {
    fontWeight: "500",
    fontSize: "large",
    backgroundColor: fade(appearance.colors.green.import[500], 0.4),
  },
  pointsChipIncorrect: {
    fontWeight: "500",
    fontSize: "large",
    backgroundColor: fade(appearance.colors.red.import[500], 0.5),
  },
  pointsChipCorrectSmall: {
    fontWeight: "500",
    backgroundColor: fade(appearance.colors.green.import[500], 0.4),
  },
  pointsChipIncorrectSmall: {
    fontWeight: "500",
    backgroundColor: fade(appearance.colors.red.import[500], 0.5),
  },
  paperCorrect: {
    borderTop: `6px solid ${fade(appearance.colors.green.import[500], 0.4)}`,
  },
  paperIncorrect: {
    borderTop: `6px solid ${fade(appearance.colors.red.import[500], 0.5)}`,
  },
  textCorrect: {
    color: appearance.colors.green.import[600],
    margin: "0 auto",
  },
  textIncorrect: {
    color: appearance.colors.red.import[500],
    margin: "0 auto",
  },
});

function ordinalSuffixOf(i) {
  var j = i % 10,
      k = i % 100;
  if (j == 1 && k != 11) {
      return i + "st";
  }
  if (j == 2 && k != 12) {
      return i + "nd";
  }
  if (j == 3 && k != 13) {
      return i + "rd";
  }
  return i + "th";
}

class QuizFormEntry extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleChange(event, questionType) {
    let answer = event.target.value;

    if (questionType === NUMERIC) {
      if (answer.length) {
        answer = parseFloat(answer);
      }
      this.props.onChange(this.props.question.id, answer);
    } else {
      this.props.onChange(this.props.question.id, answer);
    }
  }

  handleSave(event, questionType) {
    let answer = event.target.value;

    if (questionType === NUMERIC) {
      if (answer.length) {
        answer = parseFloat(answer);
        this.props.handleAnswerSave(this.props.question.id, answer);
      }
    } else {
      this.props.handleAnswerSave(this.props.question.id, answer);
    }
  }

  render() {
    const {
      classes,
      question,
      answer,
      scoring,
      contestantData,
      seasonId,
      weekId,
      disabled,
      handleAnswerSave,
      isAdmin,
      openSnackbar,
    } = this.props;

    const {
      id,
      answer: correctAnswer,
      possibleAnswers,
      possibleAnswersContestantIds,
      allFactIds,
      factsById,
      text,
      points,
      additionalDescription,
      questionType,
      radioOptions,
      radioScoring,
      contestantIdOptions,
      contextLabel,
      contextLink,
      answerType = undefined,
      allowNone,
      isLocked,
    } = question;

    const pointsScored = get(scoring, "points");
    const rank = get(scoring, "rank");
    const difference = get(scoring, "difference");
    const matchingFactId = get(scoring, "matchingFactId");
    const locked = disabled || isLocked || correctAnswer || possibleAnswers || allFactIds;
    const isScored = correctAnswer || correctAnswer === 0 || possibleAnswers || allFactIds;
    const isCorrect = pointsScored > 0;
    const isIncorrect = pointsScored === 0;
    const paperClassName = isCorrect ? classes.paperCorrect : isIncorrect ? classes.paperIncorrect : "";

    return (
      <Box className={paperClassName}>
        <Box pt={1.5} pb={2.5} px={4}>
          {isCorrect && (
            <Box mt={-1} mb={0.5}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="subtitle1"
                >
                  {id}. ✅ 
                </Typography>
                <Typography
                  className={classes.textCorrect}
                  variant="overline"
                >
                  Correct
                </Typography>
              </Box>
              <Divider />
            </Box>
          )}
          {isIncorrect && (
            <Box mt={-1} mb={0.5}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="subtitle1"
                >
                  {id}. ❌ 
                </Typography>
                <Typography
                  className={classes.textIncorrect}
                  variant="overline"
                >
                  Incorrect
                </Typography>
              </Box>
              <Divider />
            </Box>
          )}
          {!isScored && (
            <Box mt={-1} mb={0.5}>
              <Box
                display="flex"
                justifyContent="space-between"
                mb={0.5}
              >
                <Typography
                  variant="subtitle1"
                >
                  {id}.
                </Typography>
                <Chip
                  label={points + " pts"}
                  className={classes.pointsChipCorrect}
                />
              </Box>
              <Divider />
            </Box>
          )}
          {questionType === RADIO && (
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Typography
                  variant="h6"
                  className={classes.questionText}
                  gutterBottom={!additionalDescription}
                >
                  {text}
                </Typography>
                {isScored && isCorrect && (
                  <Chip
                    label={points === pointsScored ? pointsScored + " pts" : pointsScored + " / " + points + " pts"}
                    className={classes.pointsChipCorrect}
                  />
                )}
                {isScored && isIncorrect && (
                  <Chip
                    label={"0 / " + points + " pts"}
                    className={classes.pointsChipIncorrect}
                  />
                )}
              </Box>
              {additionalDescription && (
                <Box mb={1}>
                  <Typography
                    variant="caption"
                  >
                    {additionalDescription}
                  </Typography>
                </Box>
              )}
              {isScored && !isAdmin && (
                <>
                  <Box mt={Boolean(isIncorrect && additionalDescription) ? 1.5 : isIncorrect ? 0.5 : 0}>
                    <TextField
                      fullWidth
                      disabled={locked}
                      type={"text"}
                      value={answer || " "}
                      variant="outlined"
                      size="small"
                      label={isIncorrect ? "❌ Your answer" : isCorrect ? "Your answer" : ""}
                      onChange={(event) => this.handleChange(event, questionType)}
                    />
                  </Box>
                  {isIncorrect && (
                    <Box mt={2}>
                      <TextField
                        fullWidth
                        disabled={true}
                        type={"text"}
                        value={correctAnswer}
                        variant="outlined"
                        size="small"
                        label={"✅ Correct answer"}
                      />
                    </Box>
                  )}
                </>
              )}
              {(!isScored  || isAdmin) && (
                <Box pb={2}>
                  <FormControl component="fieldset" disabled={disabled}>
                    <RadioGroup
                      aria-label="prediction-question"
                      defaultValue={answer}
                      value={answer || ""}
                      name="radio-buttons-group"
                      onChange={handleAnswerSave ? (event) => this.handleSave(event, questionType) : (event) => this.handleChange(event, questionType)}
                    >
                      {radioOptions.map((option) => 
                        <FormControlLabel
                          key={option}
                          disabled={locked}
                          value={option}
                          label={radioScoring ? `${option} (${radioScoring[option]}pts)` : option}
                          control={<Radio size="small" />}
                        />
                      )}
                      {isAdmin && (
                        <FormControlLabel
                          key={"None of the above"}
                          value={"None of the above"}
                          label={"None of the above"}
                          control={<Radio size="small" />}
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                  {(contextLabel || contextLink) && (
                    <Box
                      pb={2}
                    >
                      <Typography
                        className={classes.questionText}
                        gutterBottom
                        variant="subtitle2"
                      >
                        Context:
                      </Typography>
                      {contestantIdOptions && (
                        <ContestantSelector
                          readonly
                          label={contextLabel}
                          contestantIdOptions={contestantIdOptions}
                          contestantData={contestantData}
                          seasonId={seasonId}
                          questionId={id}
                          disabled={disabled}
                          allowNone={allowNone}
                        />
                      )}
                      {contextLink && (
                        <Link
                          target="_blank"
                          href={contextLink}
                          rel="noreferrer"
                        >
                          {contextLink}
                        </Link>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
          {questionType === NUMERIC && (
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Typography
                  variant="h6"
                  className={classes.questionText}
                  gutterBottom={!additionalDescription}
                >
                  {text}
                </Typography>
                {isScored && isCorrect && (
                  <Chip
                    label={points === pointsScored ? pointsScored + " pts" : pointsScored + " / " + points + " pts"}
                    className={classes.pointsChipCorrect}
                  />
                )}
                {isScored && isIncorrect && (
                  <Chip
                    label={"0 / " + points + " pts"}
                    className={classes.pointsChipIncorrect}
                  />
                )}
              </Box>
              {additionalDescription && (
                <Box mb={1}>
                  <Typography
                    variant="caption"
                  >
                    {additionalDescription}
                  </Typography>
                </Box>
              )}
              {(contextLabel || contextLink) && (
                <Box
                  pb={2}
                >
                  <Typography
                    className={classes.questionText}
                    gutterBottom
                    variant="subtitle2"
                  >
                    Context:
                  </Typography>
                  {contestantIdOptions && (
                    <ContestantSelector
                      readonly
                      label={contextLabel}
                      contestantIdOptions={contestantIdOptions}
                      contestantData={contestantData}
                      seasonId={seasonId}
                      questionId={id}
                      disabled={disabled}
                      allowNone={allowNone}
                    />
                  )}
                  {contextLink && (
                    <Link
                      target="_blank"
                      href={contextLink}
                      rel="noreferrer"
                    >
                      {contextLink}
                    </Link>
                  )}
                </Box>
              )}
              {(!isScored || isAdmin) && (
                <TextField
                  fullWidth
                  disabled={disabled}
                  type={"number"}
                  value={answer >= 0 ? answer : ""}
                  variant="filled"
                  onChange={(event) => this.handleChange(event, questionType)}
                  onBlur={(event) => this.handleSave(event, questionType)}
                />
              )}
              {isScored && !isAdmin && (
                <>
                  {answerType === CLOSEST && (
                    <>
                      {answer && (
                        <Typography variant="body2">
                          You were the <Chip label={ordinalSuffixOf(rank)} size="small" className={isCorrect ? classes.pointsChipCorrectSmall : classes.pointsChipIncorrectSmall}/> closest
                        </Typography>
                      )}
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          disabled={true}
                          type={"number"}
                          value={answer || ""}
                          variant="outlined"
                          size="small"
                          label={"Your answer"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          disabled={true}
                          type={"number"}
                          value={correctAnswer}
                          variant="outlined"
                          size="small"
                          label={"✅ Correct answer"}
                        />
                      </Box>
                    </>
                  )}
                  {answerType === BINARY_NUMBER && (
                    <>
                      <Box mt={Boolean(isIncorrect && additionalDescription) ? 1.5 : isIncorrect ? 0.5 : 0}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          type={"number"}
                          value={answer || ""}
                          variant="outlined"
                          size="small"
                          label={isIncorrect ? "❌ Your answer" : isCorrect ? "Your answer" : ""}
                          onChange={(event) => this.handleChange(event, questionType)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      {isIncorrect && (
                        <Box mt={2}>
                          <TextField
                            fullWidth
                            disabled={true}
                            type={"number"}
                            value={correctAnswer}
                            variant="outlined"
                            size="small"
                            label={"✅ Correct answer"}
                          />
                        </Box>
                      )}
                    </>
                  )}
                  {answerType === WITHIN_AMOUNT && (
                    <>
                      {answer && (
                        <Typography variant="body2">
                          You were off by <Chip label={difference} size="small" className={isCorrect ? classes.pointsChipCorrectSmall : classes.pointsChipIncorrectSmall}/>
                        </Typography>
                      )}
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          type={"number"}
                          value={answer || ""}
                          variant="outlined"
                          size="small"
                          label={isIncorrect ? "❌ Your answer" : "Your answer"}
                          onChange={(event) => this.handleChange(event, questionType)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          disabled={true}
                          type={"number"}
                          value={correctAnswer}
                          variant="outlined"
                          size="small"
                          label={"✅ Correct answer"}
                        />
                      </Box>
                    </>
                  )}
                  {answerType === WITHIN_PERCENT && (
                    <>
                      {answer && (
                        <Typography variant="body2">
                          You were off by <Chip label={difference + "%"} size="small" className={isCorrect ? classes.pointsChipCorrectSmall : classes.pointsChipIncorrectSmall}/>
                        </Typography>
                      )}
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          type={"number"}
                          value={answer || ""}
                          variant="outlined"
                          size="small"
                          label={isIncorrect ? "❌ Your answer" : "Your answer"}
                          onChange={(event) => this.handleChange(event, questionType)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          disabled={true}
                          type={"number"}
                          value={correctAnswer}
                          variant="outlined"
                          size="small"
                          label={"✅ Correct answer"}
                        />
                      </Box>
                    </>
                  )}
                  {answerType === NUMERIC_OFF_BY && (
                    <>
                      {answer && (
                        <Typography variant="body2">
                          You were off by <Chip label={difference} size="small" className={isCorrect ? classes.pointsChipCorrectSmall : classes.pointsChipIncorrectSmall}/>
                        </Typography>
                      )}
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          type={"number"}
                          value={answer || ""}
                          variant="outlined"
                          size="small"
                          label={isIncorrect ? "❌ Your answer" : "Your answer"}
                          onChange={(event) => this.handleChange(event, questionType)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          disabled={true}
                          type={"number"}
                          value={correctAnswer}
                          variant="outlined"
                          size="small"
                          label={"✅ Correct answer"}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          )}
          {questionType === STRING && (
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Typography
                  variant="h6"
                  className={classes.questionText}
                  gutterBottom={!additionalDescription}
                >
                  {text}
                </Typography>
                {isScored && isCorrect && (
                  <Chip
                    label={points === pointsScored ? pointsScored + " pts" : pointsScored + " / " + points + " pts"}
                    className={classes.pointsChipCorrect}
                  />
                )}
                {isScored && isIncorrect && (
                  <Chip
                    label={"0 / " + points + " pts"}
                    className={classes.pointsChipIncorrect}
                  />
                )}
              </Box>
              {additionalDescription && (
                <Box mb={1}>
                  <Typography
                    variant="caption"
                  >
                    {additionalDescription}
                  </Typography>
                </Box>
              )}
              {(contextLabel || contextLink) && (
                <Box
                  pb={2}
                >
                  <Typography
                    className={classes.questionText}
                    gutterBottom
                    variant="subtitle2"
                  >
                    Context:
                  </Typography>
                  {contestantIdOptions && (
                    <ContestantSelector
                      readonly
                      label={contextLabel}
                      contestantIdOptions={contestantIdOptions}
                      contestantData={contestantData}
                      seasonId={seasonId}
                      questionId={id}
                      disabled={disabled}
                      allowNone={allowNone}
                    />
                  )}
                  {contextLink && (
                    <Link
                      target="_blank"
                      href={contextLink}
                      rel="noreferrer"
                    >
                      {contextLink}
                    </Link>
                  )}
                </Box>
              )}
              {isAdmin && (
                <>
                  {answerType === INDIVIDUAL_VIBES_FROM_ALLIE && (
                    <ArbitraryFactScorer
                      seasonId={seasonId}
                      weekId={weekId}
                      question={question}
                      openSnackbar={openSnackbar}
                    />
                  )}
                </>
              )}
              {!isScored && !isAdmin && (
                <TextField
                  fullWidth
                  disabled={disabled}
                  type={"text"}
                  value={answer || ""}
                  variant="filled"
                  onChange={(event) => this.handleChange(event, questionType)}
                  onBlur={(event) => this.handleSave(event, questionType)}
                />
              )}
              {isScored && !isAdmin && (
                <>
                  {answerType === BINARY_STRING && (
                    <>
                      <Box mt={Boolean(isIncorrect && additionalDescription) ? 1.5 : isIncorrect ? 0.5 : 0}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          type={"text"}
                          value={answer || " "}
                          variant="outlined"
                          size="small"
                          label={isIncorrect ? "❌ Your answer" : isCorrect ? "Your answer" : ""}
                          onChange={(event) => this.handleChange(event, questionType)}
                        />
                      </Box>
                      {isIncorrect && (
                        <Box mt={2}>
                          <TextField
                            fullWidth
                            disabled={true}
                            type={"text"}
                            value={correctAnswer}
                            variant="outlined"
                            size="small"
                            label={"✅ Correct answer"}
                          />
                        </Box>
                      )}
                    </>
                  )}
                  {answerType === BINARY_NUMBER && (
                    <>
                      <Box mt={Boolean(isIncorrect && additionalDescription) ? 1.5 : isIncorrect ? 0.5 : 0}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          type={"text"}
                          value={answer || " "}
                          variant="outlined"
                          size="small"
                          label={isIncorrect ? "❌ Your answer" : isCorrect ? "Your answer" : ""}
                          onChange={(event) => this.handleChange(event, questionType)}
                        />
                      </Box>
                      {isIncorrect && (
                        <Box mt={2}>
                          <TextField
                            fullWidth
                            disabled={true}
                            type={"text"}
                            value={correctAnswer}
                            variant="outlined"
                            size="small"
                            label={"✅ Correct answer"}
                          />
                        </Box>
                      )}
                    </>
                  )}
                  {answerType === ONE_OF && (
                    <>
                      <Box mt={Boolean(isIncorrect && additionalDescription) ? 1.5 : isIncorrect ? 0.5 : 0}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          type={"text"}
                          value={answer || " "}
                          variant="outlined"
                          size="small"
                          label={isIncorrect ? "❌ Your answer" : isCorrect ? "Your answer" : ""}
                          onChange={(event) => this.handleChange(event, questionType)}
                        />
                      </Box>
                      {possibleAnswersContestantIds && (
                        <Box
                          pt={1}
                          pb={2}
                        >
                          <Typography
                            className={classes.questionText}
                            gutterBottom
                            variant="subtitle2"
                          >
                            Correct Answers:
                          </Typography>
                          <ContestantSelector
                            readonly
                            label={"View all possible correct answers"}
                            contestantIdOptions={possibleAnswersContestantIds}
                            contestantData={contestantData}
                            seasonId={seasonId}
                            questionId={id}
                            allowNone={allowNone}
                          />
                        </Box>
                      )}
                    </>
                  )}
                  {answerType === INDIVIDUAL_VIBES_FROM_ALLIE && (
                    <>
                      <Box mt={Boolean(isIncorrect && additionalDescription) ? 1.5 : isIncorrect ? 0.5 : 0}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          type={"text"}
                          value={answer || " "}
                          variant="outlined"
                          size="small"
                          label={isIncorrect ? "❌ Your answer" : isCorrect ? "Your answer" : ""}
                          onChange={(event) => this.handleChange(event, questionType)}
                        />
                      </Box>
                      <Box>
                        <List
                          disablePadding
                          subheader={
                            <ListSubheader>
                              ✅ Correct answers:
                            </ListSubheader>
                          }
                        >
                          {allFactIds.map((factId) => {
                            const fact = factsById[factId];
                            const {
                              id,
                              displayText,
                              points,
                            } = fact;

                            return (
                              <ListItem key={id} dense>
                                <ListItemIcon>
                                  {id === matchingFactId && (
                                    <Chip
                                      label={points + " pts"}
                                      className={classes.pointsChipCorrect}
                                    />
                                  )}
                                  {id !== matchingFactId && (
                                    <Chip
                                      label={points + " pts"}
                                      className={classes.pointsChip}
                                    />
                                  )}
                                </ListItemIcon>
                                <Box ml={1}>
                                  <ListItemText primary={displayText}>
                                  </ListItemText>
                                </Box>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          )}
          {questionType === SELECT_CONTESTANT && (
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Typography
                  variant="h6"
                  className={classes.questionText}
                  gutterBottom={!additionalDescription}
                >
                  {text}
                </Typography>
                {isScored && isCorrect && (
                  <Chip
                    label={points + " pts"}
                    className={classes.pointsChipCorrect}
                  />
                )}
                {isScored && isIncorrect && (
                  <Chip
                    label={"0 / " + points + " pts"}
                    className={classes.pointsChipIncorrect}
                  />
                )}
              </Box>
              {additionalDescription && (
                <Box mb={1}>
                  <Typography
                    variant="caption"
                  >
                    {additionalDescription}
                  </Typography>
                </Box>
              )}
              {isScored && !isAdmin && (
                <>
                  <Typography
                    className={classes.questionText}
                    gutterBottom
                    variant="subtitle2"
                  >
                    {isIncorrect ? "❌ Your answer:" : isCorrect ? "Your answer:" : ""}
                  </Typography>
                  <ContestantSelector
                    value={answer ?? ""}
                    contestantIdOptions={contestantIdOptions}
                    contestantData={contestantData}
                    seasonId={seasonId}
                    questionId={id}
                    disabled={true}
                    allowNone={allowNone}
                  />
                  <Box
                    pt={1}
                    pb={2}
                  >
                    {answerType === ONE_OF && (
                      <>
                        <Typography
                          className={classes.questionText}
                          gutterBottom
                          variant="subtitle2"
                        >
                          Correct Answers:
                        </Typography>
                        <ContestantSelector
                          readonly
                          label={"View all possible correct answers"}
                          contestantIdOptions={possibleAnswers}
                          contestantData={contestantData}
                          seasonId={seasonId}
                          questionId={id}
                          allowNone={allowNone}
                        />
                      </>
                    )}
                    {answerType === ONE_OF_CONTESTANT_IDS && (
                      <>
                        <Typography
                          className={classes.questionText}
                          gutterBottom
                          variant="subtitle2"
                        >
                          Correct Answers:
                        </Typography>
                        <ContestantSelector
                          readonly
                          label={"View all possible correct answers"}
                          contestantIdOptions={possibleAnswers}
                          contestantData={contestantData}
                          seasonId={seasonId}
                          questionId={id}
                          allowNone={allowNone}
                        />
                      </>
                    )}
                    {answerType === BINARY_NUMBER && (
                      <>
                        {isIncorrect && (
                          <>
                            <Typography
                              className={classes.questionText}
                              gutterBottom
                              variant="subtitle2"
                            >
                              ✅ Correct Answer:
                            </Typography>
                            <ContestantSelector
                              value={correctAnswer}
                              contestantIdOptions={contestantIdOptions}
                              contestantData={contestantData}
                              seasonId={seasonId}
                              questionId={id}
                              disabled={true}
                              allowNone={allowNone}
                            />
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </>
              )}
              {(!isScored || isAdmin) && (
                <ContestantSelector
                  value={answer ?? ""}
                  contestantIdOptions={contestantIdOptions}
                  contestantData={contestantData}
                  seasonId={seasonId}
                  onChange={(event) => this.handleSave(event, questionType)}
                  questionId={id}
                  disabled={disabled}
                  allowNone={allowNone}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(QuizFormEntry);

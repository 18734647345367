import React, { Component } from "react";

import {
  Box,
  Paper,
  Typography,
  Grid,
  Divider,
  List,
  FormControl,
  Select,
  InputLabel,
  ListSubheader,
  MenuItem,
  ListItemText,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { get } from "lodash";

import WeekScoreCard from "../WeekScoreCard";

const styles = (theme) => ({
  listSubheader: {
    lineHeight: "32px",
  }
});

class ScoringPanel extends Component {
  getEntryRank = (userEntryId, standings) => {
    const oldRank = standings.indexOf(userEntryId) + 1;
    if (oldRank === 0) {
      return standings.find(userEntry => userEntry.id === userEntryId).rank;
    } else return oldRank;
  };

  handleChange(event) {
    let selectedRoseCeremonyId = event.target.value;
    this.props.onChange(selectedRoseCeremonyId);
  }

  render() {
    // Styles
    const {
      classes,
    } = this.props;

    // Passed callbacks
    const {
      onRoseCeremonySelected,
    } = this.props;

    const {
      displayName,
      userEntryId,
      standings,
      scoring,
      allWeekIds,
      roseCeremonies,
      roseCeremoniesByWeek,
      currentWeek,
      selectedRoseCeremonyId,
    } = this.props;

    const {
      weekly,
    } = standings;

    const currentWeekStandings = weekly[currentWeek];

    const {
      overall: currentOverallStandings,
    } = currentWeekStandings;

    const rank = this.getEntryRank(userEntryId, currentOverallStandings);

    const overallPoints = get(scoring, ["overall"]) ?? 0;

    const weeksWithRoseCeremonies = allWeekIds.filter((weekId) => get(roseCeremoniesByWeek, weekId));

    const menuItems = weeksWithRoseCeremonies.map((weekId) => {
      const weeksRoseCeremonies = roseCeremoniesByWeek[weekId];
      const hasMultipleRoseCeremonies = weeksRoseCeremonies.length > 1;

      if (!hasMultipleRoseCeremonies) {
        const roseCeremonyId = weeksRoseCeremonies[0];

        return (
          <MenuItem
            key={roseCeremonyId}
            value={roseCeremonyId}
          >
            <ListItemText primary={`Week ${weekId}`} />
          </MenuItem>
        )
      } else {
        const roseCeremonies = Array.from(weeksRoseCeremonies).reverse().map((roseCeremonyId, index) => 
          <MenuItem
            key={roseCeremonyId}
            value={roseCeremonyId}
          >
            <ListItemText inset primary={`Week ${weekId}: Rose Ceremony ${index + 1}`} />
          </MenuItem>
        );
        const weekOutput = [
          <ListSubheader
            key={weekId * 1000}
            className={classes.listSubheader}
          >
            Week {weekId}
          </ListSubheader>
        ].concat(roseCeremonies);
        
        return weekOutput;
      }
    }).flat();
   
    return (
      <Paper>
        <Box py={3} px={4}>
          <Typography variant="h6" gutterBottom>
            {displayName}:
          </Typography>
          <Grid container>
            <Grid item>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Box 
                  pr={2.5}
                >
                  <Typography variant="h6">
                    {overallPoints}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Total points
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box pl={2.5}>
                  <Typography variant="h6">
                    {rank}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Rank
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box py={1}>
            <Divider />
          </Box>
          <Box
            pb={1}
          >
            <FormControl
              fullWidth
              variant="filled"
              size="small"
            >
              <InputLabel id="rose-ceremony-select-label">Rose Ceremony</InputLabel>
              <Select
                onChange={onRoseCeremonySelected}
                value={selectedRoseCeremonyId}
              >
                {menuItems.map((menuItem) => {
                  return menuItem;
                })}
              </Select>
            </FormControl>
          </Box>
          <List
            disablePadding
          >
            {allWeekIds.map((weekId, index) => {
              const roseCeremonyIds = get(roseCeremoniesByWeek, weekId);
              const isSelected = roseCeremonyIds && roseCeremonyIds.includes(selectedRoseCeremonyId);
              const scoringForWeek = scoring.weekly[weekId];

              return (
                <Box
                  key={weekId}
                >
                  <WeekScoreCard
                    weekId={weekId}
                    scoring={scoringForWeek}
                    roseCeremonies={roseCeremonies}
                    roseCeremonyIds={roseCeremonyIds}
                    selectedRoseCeremonyId={selectedRoseCeremonyId}
                    isSelected={isSelected}
                  />
                  {index < allWeekIds.length - 1 && (
                    <Box py={2}>
                      <Divider />
                    </Box>
                  )}
                </Box>
              );
            })}
          </List>
        </Box>
      </Paper>
    );
  }
}

export default withStyles(styles)(ScoringPanel);

import React, { Component } from "react";

import { compose } from 'redux'

import { get } from "lodash";

import { connect } from 'react-redux'

import { Link as RouterLink, withRouter, } from "react-router-dom";

import {
  firestoreConnect,
  populate,
  isLoaded,
} from 'react-redux-firebase'

import {
  Grid,
  Box,
  Paper,
  Button,
  Collapse,
  Hidden,
} from "@material-ui/core";

import {
  Alert,
  AlertTitle,
} from "@material-ui/lab";

import { withStyles } from "@material-ui/core/styles";

import PicksColumnReadOnly from "../PicksColumnReadOnly";

import SeasonPredictionsPanel from "../SeasonPredictionsPanel";

import Loader from "../Loader";

import { firestoreConstants, } from "../../firebase";

import {
  myUserEntry,
  rankings,
  seasonPredictions,
} from "../../data/season-constants";

import {
  contestantDataBySeason,
} from "../../data/contestant-data";

import routes, {
  RANKS,
  SEASONS,
} from "../../data/routes";

import userEntries from "../../services/user-entries";

const styles = (theme) => ({
  grid: {
    margin: 0,
    width: "100%",
  },
});

class SeasonPredictionsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleAnswerSave = this.handleAnswerSave.bind(this);
  }

  isLoaded = () => {
    const {
      myUserEntryAll,
      myUserEntryById,
      seasons,
    } = this.props;

    return isLoaded(myUserEntryAll, myUserEntryById, seasons);
  }

  handleSave = () => {
    const {
      openSnackbar,
      myUserEntryAll,
    } = this.props;

    const seasonPredictions = this.state;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    userEntries.updateSeasonPredictions(myUserEntry, seasonPredictions, openSnackbar);
  }

  shouldShowNextStep = () => {
    const {
      myUserEntryAll,
      seasons,
    } = this.props;

    const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll);
    const season = userEntries.getCurrentSeasonOrDefault(seasons);

    return !userEntries.isStepComplete(rankings, myUserEntry, season)
      && userEntries.isStepComplete(seasonPredictions, myUserEntry, season);
  };

  handleAnswerChange = (questionId, answer) => {
    this.setState({
      [questionId]: answer,
    });
  };

  handleAnswerSave = (questionId, answer) => {
    const {
      openSnackbar,
      myUserEntryAll,
    } = this.props;

    const updatedState = {
      ...this.state, 
      [questionId]: answer,
    };

    this.setState({
      [questionId]: answer,
    });

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    userEntries.updateSeasonPredictions(myUserEntry, updatedState, openSnackbar);
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const {
        myUserEntryAll,
        seasons,
      } = this.props;

      const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll, seasons);

      const {
        seasonPredictions = {},
      } = myUserEntry;

      this.setState({
        ...seasonPredictions,
      });
    }
  }

  render() {
    // Styling
    const { classes } = this.props;

    const {
      myUserEntryAll,
      seasons,
    } = this.props;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll, seasons);

    const {
      rankings = [],
    } = myUserEntry;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    const season = seasons[seasonId];
    const {
      isSeasonPredictionsLocked,
      contestantData,
      seasonPredictionsQuestions,
      trailerLink,
    } = season;

    const {
      stillInWeek2Ids
    } = contestantData;

    const numContestants = stillInWeek2Ids.length;

    const baseUrl = routes[SEASONS].pathWithoutParam + seasonId;
    const roseCeremony = {
      contestantIdsForPoints: [],
      newEliminatedContestantIds: [],
      allEliminatedContestantIds: [],
      numContestantsLeft: numContestants,
      pointsPerRose: 0,
    };

    if (isSeasonPredictionsLocked) {
      return (
        <Box p={2.5}>
          <Box mb={2.5}>
            <Alert
              severity="info"
            >
              <strong>You're locked in! You'll get points for your answers at the end of the season!</strong>
            </Alert>
          </Box>
          <Paper className={classes.paper}>
            <SeasonPredictionsPanel
              questions={seasonPredictionsQuestions}
              handleAnswerChange={this.handleAnswerChange}
              contestantData={contestantData}
              answers={this.state}
              trailerLink={trailerLink}
              locked={isSeasonPredictionsLocked}
            />
          </Paper>
        </Box>
      )
    }

    return (
      <Box>
        <Box px={2.5} pt={2.5}>
          <Collapse
            in={userEntries.allStepsComplete(myUserEntryAll, seasons)}
            timeout="auto"
            unmountOnExit
          >
            <Box mb={2}>
              <Alert
                severity="success"
              >
                <AlertTitle>Finished!</AlertTitle>
                <strong>You're all set for the season! Stay tuned for weekly predictions and scoring updates!</strong>
              </Alert>
            </Box>
          </Collapse>
          <Collapse
            in={this.shouldShowNextStep()}
            timeout="auto"
            unmountOnExit
          >
            <Box mb={2}>
              <Alert
                severity="success"
                action={
                  <Button
                    size="small"
                    component={RouterLink}
                    to={`${baseUrl}${routes[RANKS].path}`}
                    color="inherit"
                    variant="outlined"
                  >
                    Continue
                  </Button>
                }
              >
                <AlertTitle>Great!</AlertTitle>
                <strong>You also need to finish your contestant rankings!</strong>
              </Alert>
            </Box>
          </Collapse>
          <Alert
            severity="info"
          >
            <strong>Give your best guess on season-long trivia questions!</strong>
          </Alert>
        </Box>
        <Hidden smUp>
          <Paper className={classes.paper}>
            <SeasonPredictionsPanel
              questions={seasonPredictionsQuestions}
              contestantData={contestantData}
              handleAnswerChange={this.handleAnswerChange}
              handleAnswerSave={this.handleAnswerSave}
              trailerLink={trailerLink}
              answers={this.state}
            />
          </Paper>
        </Hidden>
        <Hidden xsDown>
          <Grid className={classes.grid} container spacing={5}>
            <Grid item xs={4}>
              <PicksColumnReadOnly
                contestantData={contestantData}
                rankings={rankings}
                numContestants={numContestants}
                roseCeremony={roseCeremony}
              />
            </Grid>
            <Grid item xs={8}>
              <SeasonPredictionsPanel
                questions={seasonPredictionsQuestions}
                contestantData={contestantData}
                handleAnswerChange={this.handleAnswerChange}
                handleAnswerSave={this.handleAnswerSave}
                trailerLink={trailerLink}
                answers={this.state}
              />
            </Grid>
          </Grid>
        </Hidden>
      </Box>
    );
  }
}

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return {
    myUserEntryById: populate(state.firestore, `${seasonId}#${myUserEntry}`, firestoreConstants.populateUserIds),
    myUserEntryAll: state.firestore.ordered[`${seasonId}#${myUserEntry}`],
    seasons: state.firestore.data.seasons,
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
        where: ["userId", "==", props.user.uid],
      }],
      populates: firestoreConstants.populateUserIds,
      storeAs: `${seasonId}#${myUserEntry}`,
    },
  ];
}

export default compose(
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
  withStyles(styles),
)(SeasonPredictionsPage);

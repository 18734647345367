import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { withRouter, } from "react-router-dom";

import { get } from "lodash";

import {
  firestoreConnect,
  populate,
  isLoaded,
} from 'react-redux-firebase'

import { auth, firestoreConstants, } from "../../firebase";

import Loader from "../../components/Loader";
import SeasonSettings from "./SeasonSettings";
import RoseCeremonies from "./RoseCeremonies";
import WeeklyPredictions from "./WeeklyPredictions";
import SeasonPredictions from "./SeasonPredictions";

class AdminPage extends Component {
  isLoaded = () => {
    const {
      seasons,
      userEntriesById,
    } = this.props;

    return isLoaded(
      seasons,
      userEntriesById,
    );
  }

  render() {
    const {
      seasons,
      openSnackbar,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const season = get(seasons, seasonId);

    return (
      <>
        <SeasonSettings
          season={season}
          openSnackbar={openSnackbar}
        />
        <RoseCeremonies
          season={season}
          openSnackbar={openSnackbar}
        />
        <WeeklyPredictions
          season={season}
          openSnackbar={openSnackbar}
        />
        <SeasonPredictions
          season={season}
          openSnackbar={openSnackbar}
        />
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return {
    seasons: state.firestore.data.seasons,
    userEntriesById: populate(state.firestore, `${seasonId}#userEntries`, firestoreConstants.populateUserIds),
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
      }],
      populates: firestoreConstants.populateUserIds,
      storeAs: `${seasonId}#userEntries`,
    },
  ];
}

export default compose(
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
)(AdminPage);

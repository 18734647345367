import React, { Component } from "react";

import { compose } from 'redux'

import { withRouter, } from "react-router-dom";

import { get } from "lodash";

import {
  Box,
  Paper,
  Typography,
  Link,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import QuizFormEntry from "../QuizFormEntry";

const styles = (theme) => ({
  paper: {
    position: 'sticky',
    top: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(3.5)}px)`,
  }
});

class SeasonPredictionsPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // Styles
    const {
      classes,
    } = this.props;

    // Functions
    const {
      handleAnswerChange,
      handleAnswerSave,
    } = this.props;

    const {
      questions,
      answers,
      contestantData,
      locked,
      sticky,
      trailerLink,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    return (
      <Paper className={sticky ? classes.paper : ""}>
        <Box py={2} px={4}>
          {trailerLink && (
            <Box pb={2}>
            <Typography
              gutterBottom
              variant="subtitle2"
            >
              Season Trailer:
            </Typography>
            <Link
              target="_blank"
              href={trailerLink}
              rel="noreferrer"
            >
              {trailerLink}
            </Link>
            </Box>
          )}
          <Typography variant="h6" gutterBottom>
            Season Predictions:
          </Typography>
          {questions.allIds.map((questionId) => {
              const question = questions.byId[questionId];

              return (
                <Box mb={5}>
                  <QuizFormEntry 
                    key={questionId}
                    question={question}
                    answer={answers[questionId]}
                    onChange={handleAnswerChange}
                    handleAnswerSave={handleAnswerSave}
                    contestantData={contestantData}
                    seasonId={seasonId}
                    disabled={locked}
                  />
                </Box>
              )
            }
          )}
        </Box>
      </Paper>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(SeasonPredictionsPanel);

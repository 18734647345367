import React, { Component } from "react";

import {
  Box,
} from "@material-ui/core";

class TabPanel extends Component {
  render() {
    const {
      children,
      value,
      index,
    } = this.props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box pb={3}>
            {children}
          </Box>
        )}
      </Box>
    );
  };
}

export default TabPanel;

import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { get } from "lodash";

import { Link as RouterLink, withRouter, } from "react-router-dom";

import {
  firestoreConnect,
  populate,
  isLoaded,
} from 'react-redux-firebase'

import {
  Grid,
  Box,
  Button,
  Collapse,
  Hidden,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import {
  Close as CloseIcon,
} from "@material-ui/icons";

import {
  Alert,
  AlertTitle,
} from "@material-ui/lab";

import { withStyles } from "@material-ui/core/styles";

import { DragDropContext } from "react-beautiful-dnd";

import initialRankingData from "../../data/initial-ranking-data";

import {
  contestantDataBySeason,
} from "../../data/contestant-data";

import { firestoreConstants, } from "../../firebase";

import Loader from "../Loader";

import PicksColumn from "../PicksColumn";

import PicksColumnReadOnly from "../PicksColumnReadOnly";

import ContestantDetailsPanel from "../ContestantDetailsPanel";

import {
  currentSeason,
  myUserEntry,
  rankings,
  seasonPredictions,
} from "../../data/season-constants";

import routes, {
  SEASONS,
  SEASON_PREDICTIONS,
} from "../../data/routes";

import userEntries from "../../services/user-entries";

const styles = (theme) => ({
  grid: {
    margin: 0,
    width: "100%",
  },
  dialogTitle: {
    padding: "0px 6px",
  },
  dialogContent: {
    padding: "0px",
  },
});

class UserPicksPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialRankingData,
      selectedContestantId: null,
      instructionsOpen: true,
    };
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result;
    const {
      openSnackbar,
      myUserEntryAll,
    } = this.props;
    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];

    // Dropped outside the list
    if (!destination) {
      return ;
    }

    // Dropped back in same location
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const startColumn = this.state.columns[source.droppableId];
    const endColumn = this.state.columns[destination.droppableId];

    if (startColumn === endColumn) {
      // Dropped in same column
      const newContestantIds = Array.from(startColumn.contestantIds);
      newContestantIds.splice(source.index, 1);
      newContestantIds.splice(destination.index, 0, parseInt(draggableId));

      const newColumn = {
        ...startColumn,
        contestantIds: newContestantIds,
      }

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn,
        },
      };
      this.setState(newState);
      userEntries.updateRankings(myUserEntry, newState.columns["rankings"].contestantIds, openSnackbar);
    } else {
      // Dropped in different column
      const startContestantIds = Array.from(startColumn.contestantIds);
      startContestantIds.splice(source.index, 1);
      const newStart = {
        ...startColumn,
        contestantIds: startContestantIds,
      };

      const endContestantIds = Array.from(endColumn.contestantIds);
      endContestantIds.splice(destination.index, 0, parseInt(draggableId));
      const newEnd = {
        ...endColumn,
        contestantIds: endContestantIds,
      };

      const newState = {
        columns: {
          ...this.state.columns,
          [newStart.id]: newStart,
          [newEnd.id]: newEnd,
        },
      };

      this.setState(newState);
      userEntries.updateRankings(myUserEntry, newState.columns["rankings"].contestantIds, openSnackbar);
    }
  };

  onContestantClick = (contestantId) => {
    this.setState({
      selectedContestantId: contestantId,
    });
  };

  onHandleClose = () => {
    this.setState({
      selectedContestantId: null,
    });
  }

  allStepsComplete = () => {
    const {
      myUserEntryAll,
      seasons,
    } = this.props;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    const season = seasons === undefined ? null : seasons[currentSeason];

    return userEntries.allStepsComplete(myUserEntry, season);
  };

  shouldShowNextStep = () => {
    const {
      myUserEntryAll,
      seasons,
    } = this.props;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    const season = seasons === undefined ? null : seasons[currentSeason];

    return userEntries.isStepComplete(rankings, myUserEntry, season)
      && !userEntries.isStepComplete(seasonPredictions, myUserEntry, season);
  };

  onSaveClick = () => {
    const {
      openSnackbar,
      myUserEntryAll,
     } = this.props;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];

    userEntries.updateRankings(myUserEntry, this.state.columns["rankings"].contestantIds, openSnackbar);
  }

  isLoaded = () => {
    const {
      myUserEntryAll,
      myUserEntryById,
      seasons,
    } = this.props;

    return isLoaded(myUserEntryAll, myUserEntryById, seasons);
  }

  componentDidMount() {
    const {
      myUserEntryAll,
      seasons,
     } = this.props;
    let updatedContestantsColumn = {
      ...this.state.columns['contestants'],
    };

    let updatedRankingsColumn = {
      ...this.state.columns['rankings'],
    }

    if (seasons !== undefined) {
      const params = get(this.props, "match.params");
      const {
        seasonId,
      } = params;

      const season = seasons[seasonId];
      const {
        contestantData,
      } = season;

      const {
        stillInWeek2Ids,
      } = contestantData;

      updatedContestantsColumn = {
        ...updatedContestantsColumn,
        contestantIds: stillInWeek2Ids,
      }
    }

    if (myUserEntryAll !== undefined 
        && myUserEntryAll.length > 0
        && myUserEntryAll[0].rankings) {

      updatedContestantsColumn = {
        ...updatedContestantsColumn,
        contestantIds: updatedContestantsColumn.contestantIds
          .filter(contestant => !myUserEntryAll[0].rankings.includes(contestant)),
      };

      updatedRankingsColumn = {
        ...updatedRankingsColumn,
        contestantIds: myUserEntryAll[0].rankings,
      }
    }

    this.setState({
      columns: {
        ...this.state.columns,
        'contestants': updatedContestantsColumn,
        'rankings': updatedRankingsColumn,
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {
      myUserEntryAll,
      seasons,
     } = this.props;

    if ((prevProps.seasons === undefined
        || prevProps.seasons.length == 0)
        && seasons !== undefined) {
      const params = get(this.props, "match.params");
      const {
        seasonId,
      } = params;

      const season = seasons[seasonId];
      const {
        contestantData,
      } = season;

      const {
        stillInWeek2Ids,
      } = contestantData;

      const rankings = (myUserEntryAll !== undefined && myUserEntryAll.length > 0 && myUserEntryAll[0].rankings) ? myUserEntryAll[0].rankings : [];

      const contestantsColumn = {
        ...this.state.columns['contestants'],
        contestantIds: stillInWeek2Ids,
      }

      this.setState({
        columns: {
          ...this.state.columns,
          'contestants': contestantsColumn,
        }
      });
    }

    if ((prevProps.myUserEntryAll === undefined
        || prevProps.myUserEntryAll.length == 0)
        && myUserEntryAll !== undefined 
        && myUserEntryAll.length > 0
        && myUserEntryAll[0].rankings) {
      const params = get(this.props, "match.params");
      const {
        seasonId,
      } = params;

      const season = seasons[seasonId];
      const {
        contestantData,
      } = season;

      const {
        allIds,
      } = contestantData;

      const contestantsColumn = {
        ...this.state.columns['contestants'],
        contestantIds: this.state.columns['contestants'].contestantIds
          .filter(contestant => !myUserEntryAll[0].rankings.includes(contestant)),
      }

      const rankingsColumn = {
        ...this.state.columns['rankings'],
        contestantIds: myUserEntryAll[0].rankings,
      }

      this.setState({
        columns: {
          ...this.state.columns,
          'contestants': contestantsColumn,
          'rankings': rankingsColumn,
        }
      });
    }
  }
  
  render() {
    // Styling
    const {
      classes,
      myUserEntryAll,
      seasons,
     } = this.props;

    const {
      selectedContestantId,
    } = this.state;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll, seasons);

    const {
      rankings = [],
    } = myUserEntry;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    const season = seasons[seasonId];
    const {
      contestantData,
      numContestants,
      isRankingsLocked,
    } = season;

    const baseUrl = routes[SEASONS].pathWithoutParam + currentSeason;

    if (isRankingsLocked) {
      return (
        <Box>
          <Box px={2.5} pt={2.5}>
            <Alert
              severity="info"
            >
              <strong>You're locked in! Each episode, you'll get points for the guys you picked who are still in the show!</strong>
            </Alert>
          </Box>
          <Grid className={classes.grid} container spacing={5}>
            <Grid item xs={12}>
              <PicksColumnReadOnly
                contestantData={contestantData}
                rankings={rankings}
                numContestants={numContestants}
              />
            </Grid>
          </Grid>
        </Box>
      )
    }

    return (
      <Box>
        <Hidden mdUp>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Grid className={classes.grid} container spacing={0}>
              <Grid className={classes.grid} container item xs={12}>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <Collapse
                      in={userEntries.allStepsComplete(myUserEntryAll, seasons)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box mb={2}>
                        <Alert
                          severity="success"
                        >
                          <AlertTitle>Finished!</AlertTitle>
                          <strong>You're all set for the season! Stay tuned for weekly predictions and scoring updates!</strong>
                        </Alert>
                      </Box>
                    </Collapse>
                    <Collapse
                      in={this.shouldShowNextStep()}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box mb={2}>
                        <Alert
                          severity="success"
                          action={
                            <Button
                              size="small"
                              component={RouterLink}
                              to={`${baseUrl}${routes[SEASON_PREDICTIONS].path}`}
                              color="inherit"
                              variant="outlined"
                            >
                              Continue
                            </Button>
                          }
                        >
                          <AlertTitle>Great!</AlertTitle>
                          <strong>You're ready to fill out your season predictions!</strong>
                        </Alert>
                      </Box>
                    </Collapse>
                    <Box mb={2}>
                      <Alert
                        severity="info"
                      >
                        <strong>Rank the contestants in order!</strong>
                      </Alert>
                    </Box>
                  </Box>
                </Grid>
                {this.state.columnOrder.map(columnId => {
                  const column = this.state.columns[columnId];
                  const contestants = column.contestantIds.map(contestantId => contestantData.byId[contestantId])

                  return <PicksColumn
                    key={column.id}
                    column={column}
                    contestants={contestants}
                    contestantData={contestantData}
                    onContestantClick={this.onContestantClick}
                  />;
                })}
              </Grid>
            </Grid>
          </DragDropContext>
          <Dialog
            open={selectedContestantId !== null}
            onClose={this.onHandleClose}
            scroll="paper"
          >
            <DialogTitle className={classes.dialogTitle}>
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <IconButton onClick={this.onHandleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent
              className={classes.dialogContent}
            >
              <ContestantDetailsPanel selectedContestant={contestantData.byId[selectedContestantId]} fullScreen />
            </DialogContent>
          </Dialog>
        </Hidden>
        <Hidden smDown>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Grid className={classes.grid} container spacing={5}>
              <Grid className={classes.grid} container item xs={8}>
                <Grid item xs={12}>
                  <Collapse
                    in={userEntries.allStepsComplete(myUserEntryAll, seasons)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box mb={2}>
                      <Alert
                        severity="success"
                      >
                        <AlertTitle>Finished!</AlertTitle>
                        <strong>You're all set for the season! Stay tuned for weekly predictions and scoring updates!</strong>
                      </Alert>
                    </Box>
                  </Collapse>
                  <Collapse
                    in={this.shouldShowNextStep()}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box mb={2}>
                      <Alert
                        severity="success"
                        action={
                          <Button
                            size="small"
                            component={RouterLink}
                            to={`${baseUrl}${routes[SEASON_PREDICTIONS].path}`}
                            color="inherit"
                            variant="outlined"
                          >
                            Continue
                          </Button>
                        }
                      >
                        <AlertTitle>Great!</AlertTitle>
                        <strong>You're ready to fill out your season predictions!</strong>
                      </Alert>
                    </Box>
                  </Collapse>
                  <Box mb={2}>
                    <Alert
                      severity="info"
                    >
                      <strong>Rank the contestants in order!</strong>
                    </Alert>
                  </Box>
                </Grid>
                {this.state.columnOrder.map(columnId => {
                  const column = this.state.columns[columnId];
                  const contestants = column.contestantIds.map(contestantId => contestantData.byId[contestantId])

                  return <PicksColumn
                    key={column.id}
                    column={column}
                    contestants={contestants}
                    contestantData={contestantData}
                    onContestantClick={this.onContestantClick}
                  />;
                })}
              </Grid>
              <Grid item xs={4}>
                <ContestantDetailsPanel selectedContestant={contestantData.byId[selectedContestantId]} />
              </Grid>
            </Grid>
          </DragDropContext>
        </Hidden>
      </Box>
    );
  }
}

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return {
    myUserEntryById: populate(state.firestore, `${seasonId}#${myUserEntry}`, firestoreConstants.populateUserIds),
    myUserEntryAll: state.firestore.ordered[`${seasonId}#${myUserEntry}`],
    seasons: state.firestore.data.seasons,
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
        where: ["userId", "==", props.user.uid],
      }],
      populates: firestoreConstants.populateUserIds,
      storeAs: `${seasonId}#${myUserEntry}`,
    },
  ];
}

export default compose(
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
  withStyles(styles),
)(UserPicksPage);

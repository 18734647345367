import { contestantDataBySeason } from './contestant-data';

const initialRankingData = {
    columns: {
        'contestants': {
            id: 'contestants',
            title: 'Contestants',
            contestantIds: [],
            showRanks: false,
        },
        'rankings': {
            id: 'rankings',
            title: 'Your Picks',
            contestantIds: [],
            showRanks: true,
        }
    },
    columnOrder: ['contestants', 'rankings'],
};

export default initialRankingData;
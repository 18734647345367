import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import {
  isLoaded,
} from 'react-redux-firebase'

import { get, find, } from "lodash";

import { withRouter, } from "react-router-dom";

import {
  Box,
  Hidden,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";

import { withStyles, } from "@material-ui/core/styles";

import Loader from "../Loader";

import { useGetLeagueByNameQuery } from "../../services/espn";
import { getCurrentWeek, shouldLockPicks, } from "../../services/dates";
import userEntries from "../../services/user-entries";

import Matchup from "../Matchup";

const styles = (theme) => ({
  timelineRoot: {
    "&.MuiTimeline-root": {
      padding: theme.spacing(0),
    }
  },
});

function MatchupPanel(props) {
  const {
    league,
    picks,
    handlePickChange,
  } = props;

  const {
    id: leagueId,
    year,
  } = league;

  const currentWeek = getCurrentWeek();
  const currentPicks = get(picks, currentWeek);

  const picksLocked = shouldLockPicks();

  const { data, error, isLoading} = useGetLeagueByNameQuery({
    leagueId: leagueId,
    year: year,
    week: currentWeek,
  });

  if (isLoading) {
    return <Loader />;
  }
  const {
    matchups,
    teams,
  } = data;

  return(<>
    {matchups.map((matchup, index) => {
      const {
        home_team_id,
        home_score,
        home_projected,
        away_team_id,
        away_score,
        away_projected,
      } = matchup;

      const team1 = teams.find((team) => team.id === away_team_id)
      const team2 = teams.find((team) => team.id === home_team_id)
      const pick = get(currentPicks, index);

      return (
        <Box mb={3}>
          <Matchup
            id={index}
            week={currentWeek}
            pick={pick}
            team1={team1}
            team1Score={away_score}
            team1Projected={away_projected}
            team2={team2}
            team2Score={home_score}
            team2Projected={home_projected}
            handlePickChange={handlePickChange}
            picksLocked={picksLocked}
          />
        </Box>
      );
    })}
  </>)
}

class LeaguePage extends Component {
  constructor(props) {
    super(props);

    const {
      leagues,
      orderedMyUserEntries,
    } = props;

    const params = get(this.props, "match.params");
    const {
      leagueId,
    } = params;

    const league = get(leagues, leagueId);
    const {
      id: id,
      year,
    } = league;
    const myUserEntry = find(orderedMyUserEntries, {"leagueId": id, "year": year})
    const myPicks = get(myUserEntry, "picks", {});

    this.state = {
      picks: {
        ...myPicks,
      },
    };

    this.handlePickChange = this.handlePickChange.bind(this);
  }

  handlePickChange = (weekId, matchupId, pick) => {
    const {
      openSnackbar,
    } = this.props;

    const updatedState = {
      picks: {
        [weekId]: {
          ...this.state.picks[weekId],
          [matchupId]: pick,
        },
      }
    };

    this.setState(updatedState);

    const {
      leagues,
      orderedMyUserEntries,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      leagueId,
    } = params;

    const league = get(leagues, leagueId);
    const {
      id: id,
      year,
    } = league;
    const myUserEntry = find(orderedMyUserEntries, {"leagueId": id, "year": year})
    userEntries.updatePicks(league, myUserEntry, updatedState.picks, openSnackbar);
  };

  isLoaded = () => {
    const {
      leagues,
      orderedLeagues,
      myUserEntries,
      orderedMyUserEntries,
    } = this.props;

    return isLoaded(
      leagues,
      orderedLeagues,
      myUserEntries,
      orderedMyUserEntries,
    );
  }

  render() {
    const {
      leagues,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      leagueId,
    } = params;

    const league = get(leagues, leagueId);

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const {
      picks
    } = this.state;

    const currentWeek = getCurrentWeek();

    return (
      <Box>
        <Hidden mdUp>
          <Box my={2} mx={1}>
            <Box mb={2}>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={0}
                centered
              >
                <Tab
                  label={"Week " + currentWeek}
                  disabled
                />
              </Tabs>
            </Box>
            <Typography variant="h5" gutterBottom>
              Who will win these matchups?
            </Typography>
            <MatchupPanel
              league={league}
              picks={picks}
              handlePickChange={this.handlePickChange}
            />
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box my={2} mx={2}>
            <Box mb={2}>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={0}
                centered
              >
                <Tab
                  label={"Week " + currentWeek}
                  disabled
                />
              </Tabs>
            </Box>
            <Typography variant="h4" gutterBottom>
              Who will win these matchups?
            </Typography>
            <MatchupPanel
              league={league}
              picks={picks}
              handlePickChange={this.handlePickChange}
            />
          </Box>
        </Hidden>
      </Box>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    leagues: state.firestore.data.leagues,
    orderedLeagues: state.firestore.ordered.leagues,
    myUserEntries: state.firestore.data.myUserEntries,
    orderedMyUserEntries: state.firestore.ordered.myUserEntries,
  };
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps),
)(LeaguePage);

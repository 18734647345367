import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { reducer as firebaseReducer, } from 'react-redux-firebase'
import { firestoreReducer, } from 'redux-firestore'
import { navigationReducer, } from './features/navigation';
import { espnApi } from './services/espn';

export default function createStore(initialState = {}) {
  const enhancers = []

  const store = configureStore({
    reducer: { 
      firebase: firebaseReducer,
      firestore: firestoreReducer,
      navigation: navigationReducer,
      [espnApi.reducerPath]: espnApi.reducer,
    },
    initialState: initialState,
    enhancers: enhancers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(espnApi.middleware),
  })
  
  return store;
};

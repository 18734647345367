import React, { Component } from "react";

import { compose } from 'redux'

import { withRouter, } from "react-router-dom";

import { get } from "lodash";

import {
  Paper,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import ContestantRankingRow from "../ContestantRankingRow";

import EmptyState from "../EmptyState";

import {
  contestantDataBySeason,
} from "../../data/contestant-data";

import { ReactComponent as NoDataIllustration } from "../../assets/illustrations/no-data.svg";

import appearance from "../../services/appearance";

const styles = (theme) => ({
  gridContainer: {
    margin: 0,
    width: "100%",
  },
  divider: {
    backgroundColor: appearance.colors.gray.import[500]
  },
});

class PicksColumnReadOnly extends Component {
  getRanksArray = (start, end) => Array.from({length: (end - start + 1)}, (v, k) => {
    return {
      rank: k + start
    }
  });

  render() {
    // Styling
    const { classes } = this.props;

    // Passed callback
    const { onContestantClick, } = this.props;

    const {
      contestantData,
      rankings,
      numContestants,
      isBachelorette,
      roseCeremony = {},
    } = this.props;

    const {
      contestantIdsForPoints = [],
      newEliminatedContestantIds = [],
      allEliminatedContestantIds = [],
      numContestantsLeft = numContestants,
      pointsPerRose,
    } = roseCeremony;

    const rankedContestants = rankings.map((contestantId, contestantIndex) => {
      return {
        rank: contestantIndex + 1,
        contestant: contestantData.byId[contestantId],
        scoredPoints: contestantIdsForPoints.includes(contestantId) && (contestantIndex + 1 <= numContestantsLeft),
        receivedRose: contestantIdsForPoints.includes(contestantId),
        belowThreshold: contestantIdsForPoints.includes(contestantId) && (contestantIndex + 1 > numContestantsLeft),
        newlyEliminated: newEliminatedContestantIds.includes(contestantId),
        eliminated: allEliminatedContestantIds.includes(contestantId),
      }
    })

    const unrankedContestants = this.getRanksArray(rankings.length + 1, numContestants);

    const eliminatedContestants = contestantData.eliminatedWeek1Ids.map((contestantId, contestantIndex) => {
      return {
        rank: numContestants + contestantIndex + 1,
        contestant: contestantData.byId[contestantId],
        eliminated: true,
      }
    })

    const allRankingRows = rankedContestants.concat(unrankedContestants, eliminatedContestants);

    return (
      <Paper className={classes.paper}>
        <Box py={3} px={3}>
          {rankings.length === 0 && (
            <EmptyState
              type="card"
              image={<NoDataIllustration />}
              title="No Rankings Completed"
            />
          )}

          {rankings.length > 0 && (
            <Box>
              <Box
                display="flex"
                alignItems="center"
              >
                <Typography variant="h6" gutterBottom>
                  Your Rankings:
                </Typography>
                <Box marginLeft="auto">
                  <Typography variant="body2" gutterBottom>
                    <strong>Top {numContestantsLeft}</strong>
                  </Typography>
                </Box>
              </Box>
              {allRankingRows.map((rankingRow) => {
                const {
                  rank,
                  contestant,
                  receivedRose,
                  scoredPoints,
                  belowThreshold,
                  newlyEliminated,
                  eliminated,
                } = rankingRow;

                return (
                  <Box
                    mb={2}
                    key={rank}
                  >
                    <ContestantRankingRow
                      rank={rank}
                      contestant={contestant}
                      receivedRose={receivedRose}
                      scoredPoints={scoredPoints}
                      belowThreshold={belowThreshold}
                      pointsPerRose={pointsPerRose}
                      newlyEliminated={newlyEliminated}
                      eliminated={eliminated}
                      isBachelorette={isBachelorette}
                    />
                    {rank === numContestantsLeft && (
                      <Box pt={3} pb={1}>
                        <Divider className={classes.divider}/>
                      </Box>
                    )}
                  </Box>
                )
              })}
            </Box>
          )}
        </Box>
      </Paper>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(PicksColumnReadOnly);

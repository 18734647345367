
import React, { Component, useReducer } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { get } from "lodash";

import { firestoreConnect } from 'react-redux-firebase'

import { withRouter, } from "react-router-dom";

import { firestoreConstants, } from "../../firebase";

import { isLoaded, populate, } from "react-redux-firebase";

import { Link as RouterLink } from "react-router-dom";

import {
  Grid,
  Box,
  Fab,
  Tabs,
  Tab,
  Paper,
  Hidden,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import UserEntryPageWeb from "./UserEntryPageWeb";
import UserEntryPageMobile from "./UserEntryPageMobile";

import EmptyState from "../../components/EmptyState";

import PicksColumnReadOnly from "../../components/PicksColumnReadOnly";

import SeasonPredictionsPanel from "../../components/SeasonPredictionsPanel";

import ScoringPanel from "../../components/ScoringPanel";

import Loader from "../../components/Loader";

import {
  myUserEntry,
} from "../../data/season-constants";

import { ReactComponent as NoDataIllustration } from "../../assets/illustrations/no-data.svg";

import userEntries from "../../services/user-entries";

import routes, {
  WEEKLY_PREDICTIONS,
} from "../../data/routes";

const styles = (theme) => ({
  grid: {
    margin: 0,
    width: "100%",
  },
});

class UserEntryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRoseCeremonyId: null,
      selectedTabIndex: 0,
    };
  }

  isMyEntry = () => {
    const params = get(this.props, "match.params");
    const {
      userEntryId,
    } = params;

    const path = get(this.props, "match.path");

    return !(userEntryId) || path.includes("my-entry");
  }

  isLoaded = () => {
    const {
      seasons,
      userEntry,
      myUserEntryAll,
      myUserEntryById,
    } = this.props;

    const isMyEntry = this.isMyEntry();
    if (isMyEntry) {
      return isLoaded(seasons, myUserEntryAll, myUserEntryById);
    } else {
      return isLoaded(seasons, userEntry);
    }
  }

  onRoseCeremonySelected = (event) => {
    const roseCeremonyId = event.target.value;
    if (roseCeremonyId) {
      this.setState({
        selectedRoseCeremonyId: roseCeremonyId,
      });
    }
  };

  handleTabChange = (event, newTab) => {
    this.setState({
      selectedTabIndex: newTab,
    });
  };

  getDisplayName = (user) => {
    if (!user) {
      return "";
    }

    const {
      id,
      firstName,
      lastName,
    } = user;

    let realName = firstName;

    if (lastName) {
      realName = realName + " " + lastName;
    }

    return realName || id;
  }

  render() {
    // Styling
    const { classes } = this.props;

    const {
      baseUrl,
      seasons,
      userEntry,
      myUserEntryAll,
      myUserEntryById,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      seasonId,
      userEntryId,
    } = params;

    const isMyEntry = this.isMyEntry();

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const season = get(seasons, seasonId);

    const {
      numContestants,
      roseCeremonies,
      roseCeremoniesByWeek,
      allWeekIds,
      allWeeklyPredictionWeekIds,
      standings,
      currentWeek,
      currentRoseCeremony,
      isBachelorette,
      currentWeeklyPredictionsWeek,
      seasonPredictionsQuestions,
      weeklyPredictionsQuestions,
      hasDetailedWeeklyPredictions,
      contestantData,
    } = season;

    const {
      selectedRoseCeremonyId,
    } = this.state;

    const unloadedMyUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll);

    const hasOwnEntry = !(unloadedMyUserEntry && Object.keys(unloadedMyUserEntry).length === 0 && unloadedMyUserEntry.constructor === Object);

    const userEntryToRender = isMyEntry
      ? (hasOwnEntry ? myUserEntryById[unloadedMyUserEntry.id] : {})
      : userEntry;

    const hasEntry = !(userEntryToRender && Object.keys(userEntryToRender).length === 0 && userEntryToRender.constructor === Object)

    if (!hasEntry) {
      return (
        <EmptyState
          type="card"
          image={<NoDataIllustration />}
          title="No Entry"
          description="You haven't submitted an entry! Follow along with the weekly predictions!"
          button={
            <Fab
              variant="extended"
              color="primary"
              component={RouterLink}
              to={baseUrl + routes[WEEKLY_PREDICTIONS].path}
            >
              Weekly Predictions
            </Fab>
          }
        />
      )
    }

    const {
      rankings = [],
      seasonPredictions = undefined,
      scoring = undefined,
      userId = undefined,
      weeklyPredictions = undefined,
    } = userEntryToRender;

    const currentRoseCeremonyId = selectedRoseCeremonyId || currentRoseCeremony;

    return (
      <>
        <Hidden mdUp>
          <UserEntryPageMobile
            currentRoseCeremonyId={currentRoseCeremonyId}
            rankings={rankings}
            numContestants={numContestants}
            roseCeremonies={roseCeremonies}
            allWeekIds={allWeekIds}
            allWeeklyPredictionWeekIds={allWeeklyPredictionWeekIds}
            isBachelorette={isBachelorette}
            standings={standings}
            scoring={scoring}
            roseCeremoniesByWeek={roseCeremoniesByWeek}
            currentWeek={currentWeek}
            userEntryId={userEntryId}
            unloadedMyUserEntry={unloadedMyUserEntry}
            seasonPredictionsAnswers={seasonPredictions}
            seasonPredictionsQuestions={seasonPredictionsQuestions}
            weeklyPredictionsAnswers={weeklyPredictions}
            weeklyPredictionsQuestions={weeklyPredictionsQuestions}
            hasDetailedWeeklyPredictions={hasDetailedWeeklyPredictions}
            seasonId={seasonId}
            contestantData={contestantData}
            displayName={this.getDisplayName(userId)}
            onRoseCeremonySelected={this.onRoseCeremonySelected}
          />
        </Hidden>
        <Hidden smDown>
          <UserEntryPageWeb
            currentRoseCeremonyId={currentRoseCeremonyId}
            rankings={rankings}
            numContestants={numContestants}
            roseCeremonies={roseCeremonies}
            allWeekIds={allWeekIds}
            allWeeklyPredictionWeekIds={allWeeklyPredictionWeekIds}
            isBachelorette={isBachelorette}
            standings={standings}
            scoring={scoring}
            roseCeremoniesByWeek={roseCeremoniesByWeek}
            currentWeek={currentWeek}
            userEntryId={userEntryId}
            unloadedMyUserEntry={unloadedMyUserEntry}
            seasonPredictionsAnswers={seasonPredictions}
            seasonPredictionsQuestions={seasonPredictionsQuestions}
            weeklyPredictionsAnswers={weeklyPredictions}
            weeklyPredictionsQuestions={weeklyPredictionsQuestions}
            hasDetailedWeeklyPredictions={hasDetailedWeeklyPredictions}
            seasonId={seasonId}
            contestantData={contestantData}
            displayName={this.getDisplayName(userId)}
            onRoseCeremonySelected={this.onRoseCeremonySelected}
          />
        </Hidden>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
    userEntryId,
  } = params;

  return {
    seasons: state.firestore.data.seasons,
    userEntry: populate(state.firestore, `${seasonId}#${userEntryId}`, firestoreConstants.populateUserIds),
    myUserEntryById: populate(state.firestore, `${seasonId}#${myUserEntry}`, firestoreConstants.populateUserIds),
    myUserEntryAll: state.firestore.ordered[`${seasonId}#${myUserEntry}`],
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
    userEntryId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    ...(userEntryId ? [
      {
        collection: "seasons",
        doc: seasonId,
        subcollections: [{
          collection: "user_entries",
          doc: userEntryId,
        }],
        populates: firestoreConstants.populateUserIds,
        storeAs: `${seasonId}#${userEntryId}`,
      },
    ] : [
      {
        collection: "seasons",
        doc: seasonId,
        subcollections: [{
          collection: "user_entries",
          where: ["userId", "==", props.user.uid],
        }],
        populates: firestoreConstants.populateUserIds,
        storeAs: `${seasonId}#${myUserEntry}`,
      },
    ]),
  ];
}

export default compose(
  withStyles(styles),
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
)(UserEntryPage);

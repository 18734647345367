import React, { Component } from "react";

import { get } from "lodash";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";

import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";

import seasons from "../../services/seasons";

const styles = (theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  roseCeremeonyItem: {
    "&.MuiAccordionDetails-root": {
      flexDirection: "column",
    },
  },
});

const fields = [
  {
    fieldName: "id",
    type: "numeric",
  },
  {
    fieldName: "displayText",
    type: "string",
  },
  {
    fieldName: "regex",
    type: "string",
  },
  {
    fieldName: "points",
    type: "numeric",
  },
]

class ArbitraryFactScorer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      newFact: {},
    };

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleStringChange = this.handleStringChange.bind(this);
    this.handleNumericChange = this.handleNumericChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  initializeFromPreviousRoseCeremony(season) {
    const {
      contestantData,
      roseCeremonyIds,
      roseCeremonies,
    } = season;

    const {
      allIds,
    } = contestantData;

    const previousRoseCeremonyId = roseCeremonyIds[roseCeremonyIds.length - 1]
    const previousRoseCeremony = roseCeremonies[previousRoseCeremonyId];
    const {
      contestantIdsForPoints,
    } = previousRoseCeremony;
    return Object.fromEntries(
      allIds.map(id => [id, contestantIdsForPoints.includes(id)]));
  }

  initializeFromWeek1(season) {
    const {
      contestantData,
    } = season;

    const {
      allIds,
      stillInWeek2Ids,
    } = contestantData;
    
    return Object.fromEntries(
      allIds.map(id => [id, stillInWeek2Ids.includes(id)]));
  }

  handleNewClick() {
    this.setState({
      dialogOpen: true,
    });
  }

  handleClose() {
    this.setState({
      newFact: {},
      dialogOpen: false,
    });
  }

  handleStringChange(event) {
    this.setState(
      { 
        newFact: {
          ...this.state.newFact,
          [event.target.name]: event.target.value,
        },
      }
    );
  };

  handleNumericChange(event) {
    this.setState(
      { 
        newFact: {
          ...this.state.newFact,
          [event.target.name]: parseFloat(event.target.value),
        },
      }
    );
  };

  handleSave() {
    this.setState({
      dialogOpen: false,
    });

    const {
      openSnackbar,
      seasonId,
      weekId,
      question,
    } = this.props;
    
    const {
      id: questionId,
    } = question;

    seasons.updateWeeklyPredictionsQuestionArbitraryFact(seasonId, weekId, questionId, this.state.newFact, openSnackbar);
  }

  handleEdit(factId) {
    const {
      factsById = {},
    } = this.props.question;

    const existingFact = get(factsById, factId);

    this.setState({
      dialogOpen: true,
      newFact: existingFact,
    });
  }

  render() {
    const { classes } = this.props;

    const {
      question,
    } = this.props;

    const {
      dialogOpen,
    } = this.state;

    const {
      allFactIds = [],
      factsById = {},
    } = question;

    return (
      <Box>
          <Box
            display="flex"
            flexDirection="column"
          >
            {allFactIds.map((factId) => {
              const fact = factsById[factId];

              const {
                id,
                displayText,
                regex,
                points,
              } = fact;

              return (
                <Box key={id}>
                  <List>
                    <ListItem>
                      <ListItemText primary={displayText} secondary={regex + "; " + points  + " pts"}>
                      </ListItemText>
                      <IconButton
                        aria-label="edit"
                        id={id}
                        onClick={() => this.handleEdit(id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </ListItem>
                  </List>
                </Box>
              );
            })}
          </Box>
          <Button
            variant="contained"
            onClick={this.handleNewClick}
          >
            New Fact
          </Button>
          <Dialog
            open={dialogOpen}
            onClose={this.handleClose}
          >
            <DialogTitle>
              New Fact Details
            </DialogTitle>
            <DialogContent>
              {fields.map((field) => {
                const {
                  fieldName,
                  type,
                } = field;

                const value = get(this.state, ["newFact", fieldName]) || "";

                if (type === "numeric") {
                  return (
                    <TextField
                      key={fieldName}
                      fullWidth
                      label={fieldName}
                      type={"number"}
                      value={value}
                      variant="filled"
                      margin="normal"
                      name={fieldName}
                      onChange={(event) => this.handleNumericChange(event)}
                    />
                  );
                } else if (type === "string") {
                  return (
                    <TextField
                      key={fieldName}
                      fullWidth
                      label={fieldName}
                      type={"text"}
                      value={value}
                      variant="filled"
                      margin="normal"
                      name={fieldName}
                      onChange={(event) => this.handleStringChange(event)}
                    />
                  );
                }
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>
                Cancel
              </Button>
              <Button onClick={this.handleSave}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
      </Box>
    )
  }
}

export default withStyles(styles)(ArbitraryFactScorer);;

import React, { Component } from "react";

import { compose } from 'redux'

import {
  Box,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

import appearance from "../../services/appearance";

const styles = (theme) => ({
  positiveRankChange: {
    backgroundColor: fade(appearance.colors.green.import[500], 0.4),
  },
  negativeRankChange: {
    backgroundColor: fade(appearance.colors.red.import[500], 0.5),
  },
});

class RankChangeCell extends Component {
  constructor(props) {
    super(props);
  }

  getRankChangeDisplay = (rankChange) => {
    if (rankChange > 0) {
      return `+${rankChange}`;
    } else if (rankChange === 0) {
      return "";
    } else if (rankChange < 0) {
      return rankChange;
    }
  }

  getRankChangeClassName = (rankChange) => {
    const { classes } = this.props;

    if (rankChange > 0) {
      return classes.positiveRankChange;
    } else if (rankChange < 0) {
      return classes.negativeRankChange;
    }

    return "";
  }

  render() {
    // Styling
    const { classes } = this.props;

    const {
      rankChange
    } = this.props;

    const rankChangeDisplay = this.getRankChangeDisplay(rankChange);

    return (rankChangeDisplay && (
      <Box
        border={1}
        borderRadius={8}
        display="flex"
        justifyContent="center"
        className={this.getRankChangeClassName(rankChange)}
      >
        {rankChangeDisplay}
      </Box>
    ));
  }
}

export default compose(
  withStyles(styles),
)(RankChangeCell);

// Question Types
export const STRING = "STRING";
export const NUMERIC = "NUMERIC";
export const RADIO = "RADIO";
export const SELECT_CONTESTANT = "SELECT_CONTESTANT";

// Answer Types
export const BINARY_STRING = "BINARY_STRING";
export const BINARY_NUMBER = "BINARY_NUMBER";
export const ONE_OF = "ONE_OF";
export const ONE_OF_CONTESTANT_IDS = "ONE_OF_CONTESTANT_IDS";
export const CLOSEST = "CLOSEST";
export const WITHIN_AMOUNT = "WITHIN_AMOUNT";
export const WITHIN_PERCENT = "WITHIN_PERCENT";
export const RADIO_SINGLE = "RADIO_SINGLE";
export const NUMERIC_OFF_BY = "NUMERIC_OFF_BY";
export const INDIVIDUAL_VIBES_FROM_ALLIE = "INDIVIDUAL_VIBES_FROM_ALLIE";

export const answerTypes = [
  BINARY_STRING,
  BINARY_NUMBER,
  ONE_OF,
  ONE_OF_CONTESTANT_IDS,
  CLOSEST,
  WITHIN_AMOUNT,
  WITHIN_PERCENT,
  RADIO_SINGLE,
  NUMERIC_OFF_BY,
  INDIVIDUAL_VIBES_FROM_ALLIE,
];

import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import {
  Skeleton,
} from "@material-ui/lab";

import { get, size, } from "lodash";

import { Link as RouterLink, } from "react-router-dom";

import PicksProgress from "../PicksProgress";

import { useGetLeagueByNameQuery } from "../../services/espn";
import { getCurrentWeek, } from "../../services/dates";
import routes, {
  LEAGUES,
} from "../../data/routes";


function shuffleOrder(teams) {
 return teams 
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}

function getSampleTeamOwners(teams) {
  const ownerNames = teams.map((team) => team.owners[0].first_name.trimEnd());
  return shuffleOrder(ownerNames).slice(0, 3).join(", ")
}

function LeagueInfo(props) {
  const {
    user,
    league,
    entry,
  } = props;

  const {
    id: leagueId,
    year,
  } = league;
  
  const currentWeek = getCurrentWeek();

  const { data, error, isLoading} = useGetLeagueByNameQuery({
    leagueId: leagueId,
    year: year,
    week: currentWeek,
  });

  if (isLoading) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6">
            <Skeleton />
          </Typography>
          <Typography variant="subtitle1">
            <Skeleton />
          </Typography>
          <Typography variant="subtitle1">
            <Skeleton />
          </Typography>
        </CardContent>
      </Card>
    )
  }

  if (user === null) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6">
            {data.settings.league_name}
          </Typography>
          <Typography variant="subtitle1">
            {data.settings.team_count} teams
          </Typography>
          <Typography variant="subtitle1">
            {`Featuring: ${getSampleTeamOwners(data.teams)}, and more!`} 
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const picks = get(entry, ["picks", currentWeek]);
  const numPicked = size(picks);
  const totalPicks = data.matchups.length;

  return (
    <Card>
      <CardActionArea component={RouterLink} to={routes[LEAGUES].pathWithoutParam + leagueId}>
        <CardContent>
          <Box display="flex">
            <Box>
              <Typography variant="h6">
                {data.settings.league_name}
              </Typography>
              <Typography variant="subtitle1">
                {data.settings.team_count} teams
              </Typography>
              <Typography variant="subtitle1">
                {`Featuring: ${getSampleTeamOwners(data.teams)}, and more!`} 
              </Typography>
            </Box>
            {entry && (
              <Box
                display="flex"
                flexDirection="column"
                marginLeft="auto"
              >
                Picks:
                <PicksProgress
                  numPicked={numPicked}
                  totalPicks={totalPicks}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default LeagueInfo;
import React, { Component } from "react";

import {
  Box,
  Typography,
  Divider,
  Tabs,
  Tab,
  ListItem,
  Chip,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { get } from "lodash";

import { fade } from "@material-ui/core/styles/colorManipulator";
import appearance from "../../services/appearance";

const styles = (theme) => ({
  selectedButton: {
    border: `2px solid ${theme.palette.primary[500]}`,
  },
  unselectedButton: {
    border: `1px solid`,
  },
  roseCeremonyChip: {
    fontWeight: "500",
    fontSize: "large",
  },
  selectedChip: {
    backgroundColor: fade(appearance.colors.green.import[500], 0.4),
    fontWeight: "500",
    fontSize: "large",
  },
});

class WeekScoreCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // Styles
    const {
      classes,
    } = this.props;


    const {
      weekId,
      scoring,
      roseCeremonies,
      roseCeremonyIds,
      selectedRoseCeremonyId,
      isSelected,
    } = this.props;

    const titleText = "Week " + weekId;

    const weekOverall = get(scoring, ["points"]) ?? 0;
    const weekPredictionPoints = get(scoring, ["weeklyPredictionPoints"]) ?? 0;

    return (
      <Box
        borderRadius={16}
        className={isSelected ? classes.selectedButton : classes.unselectedButton} 
      >
        <Box
          px={2}
          py={1}
          width="100%"
        >
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={0}
            centered
          >
            <Tab label={titleText} disableRipple/>
          </Tabs>
          <Box
            pt={1}
            pb={0.5}
            display="flex"
            alignItems="flex-end"
          >
            <Box pr={1}>
              <Chip
                label={weekOverall}
                className={classes.roseCeremonyChip}
              />
            </Box>
            <Typography variant="body2" gutterBottom>
              Total pts
            </Typography>
          </Box>
          <Box pb={0.5}>
            <Divider />
          </Box>
          <Box
            display="flex"
            alignItems="flex-end"
            pb={0.5}
          >
            <Box pr={1}>
              <Chip
                label={weekPredictionPoints}
                className={classes.roseCeremonyChip}
              />
            </Box>
            <Typography variant="body2" gutterBottom>
              Weekly predictions
            </Typography>
          </Box>
          {roseCeremonyIds && Array.from(roseCeremonyIds).reverse().map((roseCeremonyId) => {
            const roseCeremony = roseCeremonies[roseCeremonyId];
            const roseCeremonyScoring = get(scoring, ["roseCeremonyById", roseCeremonyId]) ?? {
              points: 0,
              numCorrect: 0,
            };

            const {
              pointsPerRose,
              numContestantsLeft,
            } = roseCeremony;

            const {
              points,
              numCorrect,
            } = roseCeremonyScoring;

            const isSelected = selectedRoseCeremonyId === roseCeremonyId;

            return (
              <ListItem
                key={roseCeremonyId}
                disableGutters
                display="flex"
              >
                <Box pr={1}>
                  <Chip
                    label={points}
                    className={isSelected ? classes.selectedChip : classes.roseCeremonyChip}
                  />
                </Box>
                <Typography variant="body2">
                  (<strong>{numCorrect}</strong> correct × <strong>{pointsPerRose}</strong> pts each)
                </Typography>
                <Box marginLeft="auto">
                  <Typography variant="body2">
                    <strong>Top {numContestantsLeft}</strong>
                  </Typography>
                </Box>
              </ListItem>
            );
          })}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(WeekScoreCard);

import React, { Component } from "react";

import { get } from "lodash";

import {
  Box,
  Paper,
  Typography,
  Chip,
  Divider,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import QuizFormEntry from "../../components/QuizFormEntry";

const styles = (theme) => ({
  scoreChip: {
    fontWeight: "500",
    fontSize: "large",
  },
  scoreChipBox: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "6px",
  }
});

class SeasonPredictionsPanel extends Component {
  render() {
    const {
      classes,
    } = this.props;

    const {
      questions,
      answers,
      scoring,
      seasonId,
      contestantData,
    } = this.props;

    const totalSeasonPredictionPointsScored = get(scoring, ["seasonPredictions", "points"])
    const totalSeasonPredictionPoints = questions.allIds
      .map((questionId) => questions.byId[questionId])
      .map((question) => question.points)
      .reduce((partialSum, a) => partialSum + a, 0);

    return (
      <Box p={2.5}>
        <Box mb={2.5}>
          <Paper>
            <Box
              className={classes.scoreChipBox}
              pt={3}
              pb={2}
              px={4}
            >
              <Typography variant="h6">
                Season Predictions: 
              </Typography>
              {totalSeasonPredictionPointsScored >= 0 && (
                <Box pr={0.5}>
                  <Chip className={classes.scoreChip} label={totalSeasonPredictionPointsScored + " / " + totalSeasonPredictionPoints + " pts"} />
                </Box>
              )}
            </Box>
          <Box mb={3}>
            <Divider />
          </Box>
            {questions.allIds.map((questionId) => {
                const question = questions.byId[questionId];
                const scoringForQuestion = get(scoring, ["seasonPredictions", "byQuestionId", questionId])

                return (
                <Box
                  pb={7.5}
                  key={questionId}
                >
                  <QuizFormEntry 
                    question={question}
                    answer={get(answers, questionId)}
                    scoring={scoringForQuestion}
                    seasonId={seasonId}
                    disabled={true}
                    contestantData={contestantData}
                  />
                </Box>
                )
              }
            )}
          </Paper>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(SeasonPredictionsPanel);

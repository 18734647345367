import React, { Component } from "react";

import { get } from "lodash";

import {
  Box,
  Button,
} from "@material-ui/core";

import QuizFormEntry from "../QuizFormEntry";

class WeeklyPredictionsForm extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (questionId, answer) => {
    const {
      handleAnswerChange,
      weekId,
    } = this.props;

    handleAnswerChange(weekId, questionId, answer);
  }

  handleSave = (questionId, answer) => {
    const {
      handleAnswerSave,
      weekId,
    } = this.props;

    handleAnswerSave(weekId, questionId, answer);
  }

  render() {
    const {
      questions,
      answers,
      contestantData,
      seasonId,
      locked,
    } = this.props;

    return (
      <Box>
        {questions.allIds
          .map((questionId) => questions.byId[questionId])
          .map((question) => (
            <Box
              pb={2}
              key={question.id}
            >
              <QuizFormEntry 
                question={question}
                answer={get(answers, question.id)}
                onChange={this.handleChange}
                handleAnswerSave={this.handleSave}
                contestantData={contestantData}
                seasonId={seasonId}
                disabled={locked}
              />
            </Box>
          ))
        }
      </Box>
    );
  }
}

export default(WeeklyPredictionsForm);

import {
  Box,
  Grid,
  Card,
  Avatar,
  CardActionArea,
  Typography,
  Grow,
} from "@material-ui/core";

import { makeStyles, } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  matchupLeft: {
    paddingRight: theme.spacing(1.5),
  },
  matchupRight: {
    paddingLeft: theme.spacing(1.5),
  },
}));

function getOwnerName(owners) {
  return owners[0].first_name + " " + owners[0].last_name;
}

function Team(props) {
  const {
    team,
    score,
    projected,
    disabled,
    isSelected,
    onClick,
  } = props;

  const {
    name,
    owners,
    logo_url,
    standing,
    wins,
    losses,
  } = team;

  const ownerName = getOwnerName(owners);

  return (
    <Card
      raised={isSelected}
      style={{height: "100%"}}
    >
      <CardActionArea disabled={disabled || isSelected} onClick={onClick}>
        <Box
          py={2}
        >
          <Box
            display="flex"
            alignItems="center"
            marginBottom={1}
            marginX={1.5}
          >
            <Avatar
              src={logo_url}
              style={{
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
            <Grow in={isSelected}>
              <Typography
                variant="h5"
                style={{
                  marginLeft: "auto",
                }}
              >
                ✅ 
              </Typography>
            </Grow>
          </Box>
          <Typography variant="subtitle2" align="center">
            #{standing} {name}
          </Typography>
          <Typography variant="body2" align="center">
            {ownerName}
          </Typography>
          <Typography variant="body2" align="center">
            {wins}-{losses}
          </Typography>
          <Box mt={1}>
            <Typography variant="h6" align="center">
              {score}
            </Typography>
            <Typography variant="body2" align="center">
              {projected}
            </Typography>

          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}

function Matchup(props) {
  const classes = useStyles();
  const {
    id,
    week,
    pick,
    team1,
    team1Score,
    team1Projected,
    team2,
    team2Score,
    team2Projected,
    isDemo,
    handlePickChange,
    picksLocked,
  } = props;

  const team1Selected = pick === team1.id;
  const team2Selected = pick === team2.id;

  return (
    <Grid container>
      <Grid item xs={6} className={classes.matchupLeft}>
        <Team
          team={team1}
          score={team1Score}
          projected={team1Projected}
          isSelected={team1Selected}
          disabled={isDemo || picksLocked}
          onClick={() => handlePickChange(week, id, team1.id)}
        />
      </Grid>
      <Grid item xs={6} className={classes.matchupRight}>
        <Team
          team={team2}
          score={team2Score}
          projected={team2Projected}
          isSelected={team2Selected}
          disabled={isDemo || picksLocked}
          onClick={() => handlePickChange(week, id, team2.id)}
        />
      </Grid>
    </Grid>
  );
}

export default Matchup;
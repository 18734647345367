import { auth, firestore, } from "../firebase";

const userEntries = {};

userEntries.updatePicks = (league, userEntry, picks, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    if (userEntry) {
      const userEntryRef = firestore.collection("user_entries").doc(userEntry.id);

      userEntryRef.set({ 
          picks: picks,
        },
        { merge: true }
      )
      .catch((reason) => {
        reject(reason);
      });
    } else {
      const {
        id,
        year,
      } = league;

      firestore.collection("user_entries")
        .add({ 
          picks: picks,
          userId: uid,
          leagueId: id,
          year: year,
        })
        .catch((reason) => {
          reject(reason);
        });
    }
  });
};

export default userEntries;
import React, { Component } from "react";

import { get } from "lodash";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";

import QuizFormEntry from "../../components/QuizFormEntry";

import seasons from "../../services/seasons";

import {
  answerTypes,
} from "../../data/question-types";

const styles = (theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  weeklyPredictionsWeek: {
    "&.MuiAccordionDetails-root": {
      flexDirection: "column",
    },
  },
});

class WeeklyPredictionsWeek extends Component {
  constructor(props) {
    super(props);

    const {
      questions,
    } = this.props;

    this.state = Object.fromEntries(get(questions, ["allIds"], []).map((id) => ( [id, get(questions, ["byId", id, "answer"])])));

    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleAnswerSave = this.handleAnswerSave.bind(this);
    this.handleAnswerTypeSave = this.handleAnswerTypeSave.bind(this);
  }

  handleAnswerChange = (questionId, answer) => {
    this.setState({
      [questionId]: answer,
    });
  };

  handleAnswerSave = (questionId, answer) => {
    const {
      seasonId,
      weekId,
      openSnackbar,
    } = this.props;

    this.setState({
      [questionId]: answer,
    });

    seasons.updateWeeklyPredictionsQuestion(seasonId, weekId, questionId, answer, openSnackbar);
  };

  handleAnswerTypeSave = (event, questionId) => {
    const {
      seasonId,
      weekId,
      openSnackbar,
    } = this.props;

    const answerType = event.target.value;

    seasons.updateWeeklyPredictionsAnswerType(seasonId, weekId, questionId, answerType, openSnackbar);
  }

  render() {
    const { classes } = this.props;

    const {
      seasonId,
      weekId,
      questions,
      contestantData,
      openSnackbar,
    } = this.props;

    const {
      allIds,
      byId,
    } = questions;

    return (
      <Accordion key={weekId}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Week {weekId}</Typography>
        </AccordionSummary>
        <AccordionDetails
          className={classes.weeklyPredictionsWeek}
        >
          {allIds.map((questionId) => {
            const question = byId[questionId];
            const answerType = get(question, "answerType");
            const hasAnswerType = answerType !== undefined;

            return (
              <Box
                pb={4}
                key={questionId}
              >
                <QuizFormEntry 
                  question={question}
                  answer={this.state[questionId]}
                  onChange={this.handleAnswerChange}
                  handleAnswerSave={this.handleAnswerSave}
                  seasonId={seasonId}
                  weekId={weekId}
                  contestantData={contestantData}
                  disabled={!hasAnswerType}
                  isAdmin={true}
                  openSnackbar={openSnackbar}
                />
                <FormControl
                  fullWidth
                  variant="filled"
                  size="small"
                >
                  <InputLabel>Answer Type</InputLabel>
                  <Select
                    onChange={(event) => this.handleAnswerTypeSave(event, questionId)}
                    value={answerType || ""}
                  >
                    {answerTypes.map((answer) => 
                      <MenuItem
                        key={answer}
                        value={answer}
                      >
                        {answer}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
            )
          })}
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default withStyles(styles)(WeeklyPredictionsWeek);;

import React, { Component } from "react";

import { get } from "lodash";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
} from "@material-ui/core";

import {
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import seasons from "../../services/seasons";

const numericFields = [
  {
    fieldName: "currentWeek",
    type: "numeric",
  },
  {
    fieldName: "currentRoseCeremony",
    type: "numeric",
  },
  {
    fieldName: "currentWeeklyPredictionsWeek",
    type: "numeric",
  },
];

const stringFields = [
  {
    fieldName: "nextEpisodeAirDate",
    type: "string",
  },
  {
    fieldName: "nextEpisodeAirDateEnglish",
    type: "string",
  },
];

const booleanFields = [
  {
    fieldName: "isRankingsLocked",
    type: "boolean",
  },
  {
    fieldName: "isSeasonPredictionsLocked",
    type: "boolean",
  },
  {
    fieldName: "isWeeklyPredictionsLocked",
    type: "boolean",
  },
];

class SeasonSettings extends Component {
  constructor(props) {
    super(props);

    const {
      season,
    } = this.props;

    const initialValues = Object.fromEntries([...numericFields, ...stringFields]
      .map(field => field.fieldName)
      .map(fieldName => [fieldName, get(season, fieldName)]));

    this.state = {
      ...initialValues,
    };

    this.handleNumericChange = this.handleNumericChange.bind(this);
    this.handleTextSave = this.handleTextSave.bind(this);
    this.handleStringChange = this.handleStringChange.bind(this);
    this.handleBooleanChange = this.handleBooleanChange.bind(this);
    this.handleRescoreClick = this.handleRescoreClick.bind(this);
  }

  handleNumericChange(event) {
    this.setState({
      [event.target.name]: parseFloat(event.target.value),
    });
  };

  handleStringChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleTextSave(event) {
    seasons.updateField(this.props.season, event.target.name, this.state[event.target.name], this.props.openSnackbar);
  };

  handleBooleanChange(event) {
    seasons.updateField(this.props.season, event.target.name, event.target.checked, this.props.openSnackbar);
  };

  async handleRescoreClick() {
    await seasons.rescoreEverything(this.props.season.id, this.props.openSnackbar);
  };

  render() {
    const {
      season,
    } = this.props;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Season Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            flexDirection="column"
          >
            {numericFields.map((field) => {
              const {
                fieldName,
              } = field;

              const value = get(this.state, fieldName) || "";

              return (
                <TextField
                  key={fieldName}
                  fullWidth
                  label={fieldName}
                  type={"number"}
                  value={value}
                  variant="filled"
                  margin="normal"
                  name={fieldName}
                  onChange={(event) => this.handleNumericChange(event)}
                  onBlur={(event) => this.handleTextSave(event)}
                />
              );
            })}
            {stringFields.map((field) => {
              const {
                fieldName,
              } = field;

              const value = get(this.state, fieldName) || "";

              return (
                <TextField
                  key={fieldName}
                  fullWidth
                  label={fieldName}
                  type={"text"}
                  value={value}
                  variant="filled"
                  margin="normal"
                  name={fieldName}
                  onChange={(event) => this.handleStringChange(event)}
                  onBlur={(event) => this.handleTextSave(event)}
                />
              );
            })}
            <FormControl component="fieldset">
              <FormGroup>
              {booleanFields.map((field) => {
                const {
                  fieldName,
                } = field;

                const value = get(season, fieldName);

                return (
                  <FormControlLabel
                    key={fieldName}
                    control={
                      <Switch
                        checked={value}
                        onChange={this.handleBooleanChange}
                        color="primary"
                        name={fieldName}
                      />
                    }
                    label={fieldName}
                  />
                );
              })}
              </FormGroup>
            </FormControl>
            <Box
              py={4}
              border={1}
              borderColor="red"
              display="flex"
              flexDirection="column"
              alignItems="center"
              onClick={this.handleRescoreClick}
            >
              <Button
                variant="contained"
                color="primary"
              >
                RESET ALL SCORES
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default SeasonSettings;

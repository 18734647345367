import React, { Component } from "react";

import { get } from "lodash";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";

import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";

import seasons from "../../services/seasons";

const styles = (theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  roseCeremeonyItem: {
    "&.MuiAccordionDetails-root": {
      flexDirection: "column",
    },
  },
});

const fields = [
  {
    fieldName: "id",
    type: "numeric",
  },
  {
    fieldName: "week",
    type: "numeric",
  },
  {
    fieldName: "numContestantsLeft",
    type: "numeric",
  },
  {
    fieldName: "pointsPerRose",
    type: "numeric",
  },
]

class RoseCeremonies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      newRoseCeremony: {},
      checked: {},
    };

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNumericChange = this.handleNumericChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  initializeFromPreviousRoseCeremony(season) {
    const {
      contestantData,
      roseCeremonyIds,
      roseCeremonies,
    } = season;

    const {
      allIds,
    } = contestantData;

    const previousRoseCeremonyId = roseCeremonyIds[roseCeremonyIds.length - 1]
    const previousRoseCeremony = roseCeremonies[previousRoseCeremonyId];
    const {
      contestantIdsForPoints,
    } = previousRoseCeremony;
    return Object.fromEntries(
      allIds.map(id => [id, contestantIdsForPoints.includes(id)]));
  }

  initializeFromWeek1(season) {
    const {
      contestantData,
    } = season;

    const {
      allIds,
      stillInWeek2Ids,
    } = contestantData;
    
    return Object.fromEntries(
      allIds.map(id => [id, stillInWeek2Ids.includes(id)]));
  }

  handleNewClick() {
    const {
      season,
    } = this.props;

    const {
      roseCeremonyIds,
    } = season;

    const initialChecked = roseCeremonyIds && roseCeremonyIds.length ? this.initializeFromPreviousRoseCeremony(season) : this.initializeFromWeek1(season)

    this.setState({
      dialogOpen: true,
      checked: initialChecked,
    });
  }

  handleClose() {
    this.setState({
      newRoseCeremony: {},
      dialogOpen: false,
    });
  }

  handleNumericChange(event) {
    this.setState(
      { 
        newRoseCeremony: {
          ...this.state.newRoseCeremony,
          [event.target.name]: parseFloat(event.target.value),
        },
      }
    );
  };

  handleSave() {
    this.setState({
      dialogOpen: false,
    });

    const {
      openSnackbar,
    } = this.props;

    const {
      contestantData,
      roseCeremonyIds,
      roseCeremonies,
    } = this.props.season;

    const {
      id,
    } = this.state.newRoseCeremony;

    const previousRoseCeremony = roseCeremonyIds && roseCeremonyIds.includes(id - 1) ? roseCeremonies[roseCeremonyIds[roseCeremonyIds.indexOf(id - 1)]] : undefined;
    const previousContestantIdsForPoints = get(previousRoseCeremony, "contestantIdsForPoints") || contestantData.stillInWeek2Ids;
    const previousAllEliminatedIds = get(previousRoseCeremony, "allEliminatedContestantIds") || contestantData.eliminatedWeek1Ids;

    const contestantIdsChecked = this.state.checked;

    const contestantIdsForPoints = Object.keys(contestantIdsChecked).filter((id) => contestantIdsChecked[id]).map((id) => parseInt(id));
    const newlyEliminated = previousContestantIdsForPoints.filter((id) => !contestantIdsForPoints.includes(parseInt(id)));
    const allEliminatedContestantIds = [...new Set([...previousAllEliminatedIds, ...newlyEliminated])].sort(function (a, b) {  return a - b;  });

    seasons.updateRoseCeremony(this.props.season, this.state.newRoseCeremony, contestantIdsForPoints, newlyEliminated, allEliminatedContestantIds, openSnackbar);
  }

  handleEdit(roseCeremonyId) {
    const {
      roseCeremonies,
      roseCeremoniesByWeek,
      contestantData,
    } = this.props.season;

    const {
      allIds,
    } = contestantData;

    const roseCeremony = roseCeremonies[roseCeremonyId];

    const {
      numContestantsLeft,
      pointsPerRose,
      contestantIdsForPoints,
    } = roseCeremony;

    const week = parseInt(Object.keys(roseCeremoniesByWeek).find(weekId => parseInt(roseCeremoniesByWeek[weekId].find(id => id === roseCeremonyId))));
    const checked = Object.fromEntries(
      allIds.map(id => [id, contestantIdsForPoints.includes(id)]));

    this.setState({
      dialogOpen: true,
      newRoseCeremony: {
        id: roseCeremonyId,
        week: week,
        numContestantsLeft: numContestantsLeft,
        pointsPerRose: pointsPerRose,
      },
      checked: checked,
    });
  }

  handleCheck(event) {
    this.setState(
    {
      checked: {
        ...this.state.checked,
        [event.target.name]: event.target.checked,
      },
    });
  }

  render() {
    const { classes } = this.props;

    const {
      season,
    } = this.props;

    const {
      dialogOpen,
    } = this.state;

    const {
      roseCeremonies = [],
      roseCeremonyIds,
      contestantData,
    } = season;

    const allContestantIds = contestantData.allIds;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Rose Ceremonies</Typography>
        </AccordionSummary>
        <AccordionDetails
          className={classes.roseCeremeonyItem}
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            {roseCeremonyIds && roseCeremonyIds.map((roseCeremonyId) => {
              const roseCeremony = roseCeremonies[roseCeremonyId];
              const {
                pointsPerRose,
                numContestantsLeft,
                newEliminatedContestantIds,
                allEliminatedContestantIds,
                contestantIdsForPoints,
              } = roseCeremony;

              return (
                <Box key={roseCeremonyId}>
                  <List>
                    <ListItem>
                      <ListItemText primary={"Rose Ceremony " + roseCeremonyId} secondary={numContestantsLeft + " remaining; " + pointsPerRose  + " per rose"}>
                      </ListItemText>
                      <Typography>
                        Newly eliminated:
                      </Typography>
                      {newEliminatedContestantIds.map((contestantId) => {
                        const contestant = contestantData.byId[contestantId];
                        return (
                          <ListItemAvatar key={contestantId}>
                            <Avatar
                              src={require(`../../assets/images/${contestant.fullImageName}`).default}
                              className={classes.avatar}
                            />
                          </ListItemAvatar>
                        )
                      })}
                      <IconButton
                        aria-label="delete"
                        id={roseCeremonyId}
                        onClick={() => this.handleEdit(roseCeremonyId)}
                      >
                        <EditIcon />
                      </IconButton>
                    </ListItem>
                  </List>
                </Box>
              );
            })}
          </Box>
          <Button
            variant="contained"
            onClick={this.handleNewClick}
          >
            New Rose Ceremony
          </Button>
          <Dialog
            open={dialogOpen}
            onClose={this.handleClose}
          >
            <DialogTitle>
              New Rose Cermeony Details
            </DialogTitle>
            <DialogContent>
              {fields.map((field) => {
                const {
                  fieldName,
                  name,
                } = field;

                const value = get(this.state, ["newRoseCeremony", fieldName]) || "";

                return (
                  <TextField
                    key={fieldName}
                    fullWidth
                    label={fieldName}
                    type={"number"}
                    value={value}
                    variant="filled"
                    margin="normal"
                    name={fieldName}
                    onChange={(event) => this.handleNumericChange(event)}
                  />
                );
              })}
              <FormGroup column="true">
                {allContestantIds.map((id) => {
                  const contestant = contestantData.byId[id];
                  const {
                    name,
                  } = contestant;

                  const value = get(this.state, ["checked", id]);

                  return (
                    <FormControlLabel
                      key={id}
                      control={
                        <Checkbox
                          checked={value}
                          onChange={this.handleCheck}
                          name={id.toString()}
                          color="primary"
                        />
                      }
                      label={name}
                    />
                  );
                })}
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>
                Cancel
              </Button>
              <Button onClick={this.handleSave}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default withStyles(styles)(RoseCeremonies);;

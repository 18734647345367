import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  Box,
  Tabs,
  Tab,
  Paper,
  Toolbar,
  AppBar,
} from "@material-ui/core";

import UserEntryOverview from "./UserEntryOverview";
import WeeklyPredictionsPanel from "./WeeklyPredictionsPanel";
import SeasonPredictionsPanel from "./SeasonPredictionsPanel";

import TabPanel from "../../components/TabPanel";

import appearance from "../../services/appearance";

const styles = (theme) => ({
  header: {
    backgroundColor: appearance.colors.gray.import[50],
    top: "64px",
  },
  tabScrollButton: {
    "& .MuiTabScrollButton-root": {
      color: "black",
    },
  },
});

class UserEntryPageMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0,
    };
  }

  handleTabChange = (event, newTab) => {
    this.setState({
      selectedTabIndex: newTab,
    });
  };

  render() {
    const {
      selectedTabIndex
    } = this.state;

    const {
      classes,
      currentRoseCeremonyId,
      currentWeek,
      rankings,
      numContestants,
      isBachelorette,
      roseCeremonies,
      allWeekIds,
      allWeeklyPredictionWeekIds,
      standings,
      scoring,
      displayName,
      userEntryId,
      unloadedMyUserEntry,
      roseCeremoniesByWeek,
      seasonPredictionsAnswers,
      seasonPredictionsQuestions,
      weeklyPredictionsAnswers,
      weeklyPredictionsQuestions,
      hasDetailedWeeklyPredictions,
      seasonId,
      contestantData,
    } = this.props;

    // Functions
    const {
      onRoseCeremonySelected,
    } = this.props;

    return (
      <>
        <AppBar
          position="sticky"
          className={classes.header}
        >
          <Tabs
            value={selectedTabIndex}
            onChange={this.handleTabChange}
            centered
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabScrollButton}
          >
            <Tab label="Overview" />
            <Tab label="Weekly Predictions" />
            <Tab label="Season Predictions" />
          </Tabs>
        </AppBar>
        <Box>
          <TabPanel value={selectedTabIndex} index={0}>
            <UserEntryOverview
              currentRoseCeremonyId={currentRoseCeremonyId}
              rankings={rankings}
              numContestants={numContestants}
              roseCeremonies={roseCeremonies}
              allWeekIds={allWeekIds}
              isBachelorette={isBachelorette}
              standings={standings}
              scoring={scoring}
              roseCeremoniesByWeek={roseCeremoniesByWeek}
              currentWeek={currentWeek}
              userEntryId={userEntryId}
              unloadedMyUserEntry={unloadedMyUserEntry}
              displayName={displayName}
              contestantData={contestantData}
              onRoseCeremonySelected={onRoseCeremonySelected}
            />
          </TabPanel>
          <TabPanel value={selectedTabIndex} index={1}>
            <WeeklyPredictionsPanel
              questions={weeklyPredictionsQuestions}
              answers={weeklyPredictionsAnswers}
              scoring={scoring}
              seasonId={seasonId}
              hasDetailedWeeklyPredictions={hasDetailedWeeklyPredictions}
              allWeeklyPredictionWeekIds={allWeeklyPredictionWeekIds}
              contestantData={contestantData}
            />
          </TabPanel>
          <TabPanel value={selectedTabIndex} index={2}>
            <SeasonPredictionsPanel
              questions={seasonPredictionsQuestions}
              answers={seasonPredictionsAnswers}
              scoring={scoring}
              seasonId={seasonId}
              contestantData={contestantData}
            />
          </TabPanel>
        </Box>
      </>
    )
  }
}

export default withStyles(styles)(UserEntryPageMobile);

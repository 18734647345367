
const datesByYear = {
  2024: {
    startYear: 2024,
    startMonth: 8,
    startDay: 3,
    firstThursdayDayUTC: 6,
  }
}

export function getCurrentWeek() {
  const today = new Date();
  const seasonStartDate = new Date(datesByYear[2024].startYear, datesByYear[2024].startMonth, datesByYear[2024].startDay);
  return numWeeksBetween(today, seasonStartDate) + 1;
};

function isDST(d) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();    
}

export function shouldLockPicks() {
  const today = new Date();
  const yearsDates = datesByYear[2024];
  var firstThursdayDate = new Date(yearsDates.startYear, yearsDates.startMonth, yearsDates.firstThursdayDayUTC);

  if (isDST(today)) {
    firstThursdayDate.setUTCHours(0, 15, 0, 0);
  } else {
    firstThursdayDate.setUTCHours(1, 15, 0, 0);
  }

  const currentWeek = getCurrentWeek();
  var thisWeekThursday = new Date(firstThursdayDate);
  thisWeekThursday.setDate(thisWeekThursday.getDate() + (7 * (currentWeek - 1)));

  return thisWeekThursday < today;
}

function numWeeksBetween(dt2, dt1) 
{
  // Calculate the difference in milliseconds between dt2 and dt1
  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  // Convert the difference from milliseconds to weeks by dividing it by the number of milliseconds in a week
  diff /= (60 * 60 * 24 * 7);
  // Return the absolute value of the rounded difference as the result
  return Math.abs(Math.floor(diff));
}
  
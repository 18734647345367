import React, { Component } from "react";

import { get } from "lodash";

import {
  Box,
  Paper,
  Typography,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from "@material-ui/core";

import {
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";

import QuizFormEntry from "../../components/QuizFormEntry";

const styles = (theme) => ({
  scoreChip: {
    fontWeight: "500",
    fontSize: "large",
  },
  scoreChipBox: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "6px",
  },
  accordionRoot: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  accordionSummaryRoot: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  accordionSummaryContent: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  accordionDetailsRoot: {
    padding: 0,
  }
});

class WeeklyPredictionsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  render() {
    const {
      classes,
    } = this.props;

    const {
      expanded,
    } = this.state;

    const {
      questions,
      answers,
      scoring,
      seasonId,
      hasDetailedWeeklyPredictions,
      allWeeklyPredictionWeekIds,
      contestantData,
    } = this.props;

    const weeklyPredictionScoring = get(scoring, ["weekly"])
    const totalWeeklyPredictionPointsScored = Object.entries(weeklyPredictionScoring)
      .map(([_, week]) => week.weeklyPredictionPoints ?? 0)
      .reduce((partialSum, a) => partialSum + a, 0);

    if (!hasDetailedWeeklyPredictions) {
      return (
        <Box p={2.5}>
          <Box mb={2.5}>
            <Paper>
              <Box
                className={classes.scoreChipBox}
                pt={3}
                pb={2}
                px={4}
              >
                <Typography variant="h6">
                  Weekly Predictions: 
                </Typography>
                {totalWeeklyPredictionPointsScored >= 0 && (
                  <Box pr={0.5}>
                    <Chip className={classes.scoreChip} label={totalWeeklyPredictionPointsScored + " pts"} />
                  </Box>
                )}
              </Box>
              <Box mb={1}>
                <Divider />
              </Box>
              <Box px={4}>
                <List>
                  {allWeeklyPredictionWeekIds.map((weekId) => {
                    const pointsForWeek = get(weeklyPredictionScoring, [weekId, "weeklyPredictionPoints"], 0);

                    return (
                      <ListItem disableGutters key={weekId}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography variant="h6">
                            Week {weekId}
                          </Typography>
                          <Chip className={classes.scoreChip} label={(pointsForWeek) + " pts"} />
                        </Box>
                      </ListItem>
                    )
                  })}
                </List>
              </Box>
          </Paper>
        </Box>
      </Box>
    )}

    const allWeeks = get(questions, "allWeeks");
    const totalWeeklyPredictionPoints = allWeeks ? parseFloat(allWeeks
      .map((weekId) => questions.byWeek[weekId])
      .map((week) => {
        return week.allIds.map((questionId) => week.byId[questionId].points)
          .reduce((partialSum, a) => partialSum + a, 0);
      })
      .reduce((partialSum, a) => partialSum + a, 0)).toPrecision(3)
      : 0;

    return (
      <Box p={2.5}>
        <Box mb={2.5}>
          <Paper>
            <Box
              className={classes.scoreChipBox}
              pt={3}
              pb={2}
              px={4}
            >
              <Typography variant="h6">
                Weekly Predictions: 
              </Typography>
              {totalWeeklyPredictionPointsScored >= 0 && (
                <Box pr={0.5}>
                  <Chip className={classes.scoreChip} label={totalWeeklyPredictionPointsScored + " / " + totalWeeklyPredictionPoints + " pts"} />
                </Box>
              )}
            </Box>
            <Box mb={3}>
              <Divider />
            </Box>
            <Box px={0}>
              {get(questions, "allWeeks") && questions.allWeeks.map((weekId) => {
                const weeksQuestions = questions.byWeek[weekId];
                const pointsForWeek = get(weeklyPredictionScoring, [weekId, "weeklyPredictionPoints"], 0);
                const answersForWeek = get(answers, weekId);

                const anyQuestionScored = weeksQuestions.allIds
                  .map((questionId) => weeksQuestions.byId[questionId])
                  .some((question) => Boolean(get(question, "answer") || get(question, "possibleAnswers")));

                if (!anyQuestionScored) {
                  return (
                    <ListItem disableGutters key={weekId}>
                      <Box
                        px={2}
                        pt={1}
                        pb={2}
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography variant="h6">
                          Week {weekId}
                        </Typography>
                        <Chip className={classes.scoreChip} label={"Not scored yet"} />
                      </Box>
                    </ListItem>
                  );
                }

                return (
                  <Accordion
                    key={weekId}
                    square
                    classes={{ root: classes.accordionRoot }}
                    onChange={this.handleChange("panel" + weekId)}
                    expanded={expanded === ("panel" + weekId)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography variant="h6">
                          Week {weekId}
                        </Typography>
                        <Chip className={classes.scoreChip} label={(pointsForWeek) + " pts"} />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                      <Box width="100%">
                        {weeksQuestions.allIds.map((questionId) => {
                          const question = weeksQuestions.byId[questionId];
                          const scoringForQuestion = get(weeklyPredictionScoring, [weekId, "weeklyPredictionPointsByQuestionId", questionId], 0);

                          return (
                            <QuizFormEntry 
                              question={question}
                              answer={get(answersForWeek, questionId)}
                              scoring={scoringForQuestion}
                              seasonId={seasonId}
                              contestantData={contestantData}
                            />
                          )
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </Box>
          </Paper>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(WeeklyPredictionsPanel);;
